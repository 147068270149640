import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import Button from '../../Layout/Elements/Button'
import { advertisingContactRequest } from '../../../actions/mail'

const AdvertisingContactForm = ({ advertisingContactRequest }) => {
	const dispatch = useDispatch()
	// const [preferredContactMethod, setPreferredContactMethod] = useState({
	// 	contactEmail: false,
	// 	contactPhone: false,
	// 	contactBoth: true,
	// })

	// const { contactEmail, contactPhone, contactBoth } = preferredContactMethod

	// const onRadioChange = e => setPreferredContactMethod({ [e.target.name]: e.target.value })

	const [formData, setFormData] = useState({
		contactName: '',
		businessName: '',
		email: '',
		phone: '',
		contactMethod: 'email',
		preferredContactTime: '',
		message: '',
	})

	const { contactName, businessName, email, message, phone, contactMethod, preferredContactTime } = formData

	const onChange = e => {
		if (e.target.name === 'contactMethod' && e.target.value === 'phone') {
			setFormData({ ...formData, email: '', [e.target.name]: e.target.value })
		} else if (e.target.name === 'contactMethod' && e.target.value === 'email') {
			setFormData({ ...formData, phone: '', preferredContactTime: '', [e.target.name]: e.target.value })
		} else {
			setFormData({ ...formData, [e.target.name]: e.target.value })
		}
	}

	useEffect(() => {
		console.log('formData = ', formData)
	}, [formData])

	const onSubmit = e => {
		e.preventDefault()
		console.log('Submitted')

		advertisingContactRequest(formData)
	}

	return (
		<form className="form advertising-contact-form" onSubmit={e => onSubmit(e)}>
			<fieldset className="form-section">
				<div className="form-group">
					<label htmlFor="text_field">Name:</label>
					<input
						type="text"
						placeholder="Name"
						name="contactName"
						value={contactName}
						onChange={e => onChange(e)}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="text_field">Business Name:</label>
					<input
						type="text"
						placeholder="Business Name"
						name="businessName"
						value={businessName}
						onChange={e => onChange(e)}
					/>
				</div>
				<div className="form-group radio-group">
					<label htmlFor="contactMethod">Preferred Contact Method:</label>
					<div className="inline-radio-group">
						<label className="radio-item">
							<input
								type="radio"
								className="radio"
								name="contactMethod"
								checked={contactMethod === 'email' ? true : false}
								value="email"
								onChange={e => onChange(e)}
							/>{' '}
							<span className="radio-text">Email</span>
						</label>
						<label className="radio-item">
							<input
								type="radio"
								className="radio"
								name="contactMethod"
								checked={contactMethod === 'phone' ? true : false}
								value="phone"
								onChange={e => onChange(e)}
							/>{' '}
							<span className="radio-text">Phone</span>
						</label>
						{/* <label className="radio-item">
							<input
								type="radio"
								className="radio"
								name="contactMethod"
								// checked={contactBoth ? true : false}
								value="phone or email"
								onChange={e => onChange(e)}
							/>{' '}
							<span className="radio-text">Either</span>
						</label> */}
					</div>
				</div>
				{contactMethod === 'email' && (
					<div className="form-group">
						<label htmlFor="text_field">Contact Email:</label>
						<input
							type="email"
							placeholder="Contact Email"
							name="email"
							value={email}
							onChange={e => onChange(e)}
						/>
					</div>
				)}
				{contactMethod === 'phone' && (
					<div className="form-group">
						<label htmlFor="text_field">Contact Phone Number:</label>
						<input
							type="tel"
							placeholder="Contact Phone Number"
							name="phone"
							value={phone}
							onChange={e => onChange(e)}
						/>
					</div>
				)}
				{contactMethod === 'phone' && (
					<div className="form-group radio-group">
						<label htmlFor="contactTime">Preferred Contact Time of Day: (Monday through Fiday)</label>
						<div className="inline-radio-group">
							<label className="radio-item">
								<input
									type="radio"
									className="radio"
									name="preferredContactTime"
									checked={preferredContactTime === 'Early Morning' ? true : false}
									value="Early Morning"
									onChange={e => onChange(e)}
								/>{' '}
								<span className="radio-text">Early Morning (8am - 10am EST)</span>
							</label>
							<label className="radio-item">
								<input
									type="radio"
									className="radio"
									name="preferredContactTime"
									checked={preferredContactTime === 'Late Morning' ? true : false}
									value="Late Morning"
									onChange={e => onChange(e)}
								/>{' '}
								<span className="radio-text">Late Morning (10am - 12pm EST)</span>
							</label>
							<label className="radio-item">
								<input
									type="radio"
									className="radio"
									name="preferredContactTime"
									checked={preferredContactTime === 'Early Afternoon' ? true : false}
									value="Early Afternoon"
									onChange={e => onChange(e)}
								/>{' '}
								<span className="radio-text">Early Afternoon (12pm - 2:30pm EST)</span>
							</label>
							<label className="radio-item">
								<input
									type="radio"
									className="radio"
									name="preferredContactTime"
									checked={preferredContactTime === 'Late Afternoon' ? true : false}
									value="Late Afternoon"
									onChange={e => onChange(e)}
								/>{' '}
								<span className="radio-text">Late Afternoon (2:30pm - 5:00pm EST)</span>
							</label>
							<label className="radio-item">
								<input
									type="radio"
									className="radio"
									name="preferredContactTime"
									checked={preferredContactTime === 'Evening' ? true : false}
									value="Evening"
									onChange={e => onChange(e)}
								/>{' '}
								<span className="radio-text">Evening (5:00pm - 7:00pm EST)</span>
							</label>
							{/* <label className="radio-item">
							<input
								type="radio"
								className="radio"
								name="contactMethod"
								// checked={contactBoth ? true : false}
								value="phone or email"
								onChange={e => onChange(e)}
							/>{' '}
							<span className="radio-text">Either</span>
						</label> */}
						</div>
					</div>
				)}
				<div className="form-group">
					<label htmlFor="text_field">Message:</label>
					<textarea name="message" value={message} onChange={e => onChange(e)} />
				</div>

				<div className="form-section submit">
					<div className="form-group submit-btn">
						<Button variant="primary" label="Submit" size="large" />
					</div>
				</div>
			</fieldset>
		</form>
	)
}

AdvertisingContactForm.propTypes = {
	advertisingContactRequest: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, { advertisingContactRequest })(AdvertisingContactForm)
