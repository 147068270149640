import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectStripe } from 'react-stripe-elements'
import Card from './Card'
// import Address from './Address'
import Button from '../../Layout/Elements/Button'
import { submitPayment } from '../../../actions/payment'
import SelectElem from '../../forms/SelectElem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const usStateData = require('../../forms/tournament/us_states.json')

const CheckoutForm = ({ stripe, submitPayment, orderData }) => {
	const { name, options, tournLevel, tournPrice } = orderData

	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		street: '',
		city: '',
		state: '',
		// zip: '',
		price: tournPrice * 100,
		product: tournLevel,
	})

	const { firstName, lastName, street, city, state } = formData

	const tax = 0.0

	const inputChange = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	const onStateChange = e => {
		setFormData({ ...formData, state: e.target.value })
	}

	const handleSubmit = async e => {
		e.preventDefault()
		// console.log('submitted')

		const paymentName = firstName + ' ' + lastName

		let { token } = await stripe.createToken({ name: paymentName })

		submitPayment(token, formData)
	}

	return (
		<div className="checkout-form-container">
			<div className="form-layout">
				<h1>Checkout</h1>
				<form onSubmit={e => handleSubmit(e)}>
					<h3>Billing Info</h3>
					<div className="form-row">
						<div className="form-group">
							<label htmlFor="text_field">First Name</label>
							<input
								type="text"
								placeholder="First Name"
								name="firstName"
								value={firstName}
								onChange={e => inputChange(e)}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="text_field">Last Name</label>
							<input
								type="text"
								placeholder="Last Name"
								name="lastName"
								value={lastName}
								onChange={e => inputChange(e)}
							/>
						</div>
					</div>
					<h3>Billing Address</h3>

					<div className="form-group">
						<label htmlFor="text_field">Street</label>
						<input
							type="text"
							placeholder="Street"
							name="street"
							value={street}
							onChange={e => inputChange(e)}
						/>
					</div>
					<div className="form-row">
						<div className="form-group">
							<label htmlFor="text_field">City</label>
							<input
								type="text"
								placeholder="City"
								name="city"
								value={city}
								onChange={e => inputChange(e)}
							/>
						</div>

						<div className="form-group">
							<label htmlFor="text_field">State</label>
							<SelectElem
								value={state}
								name="state"
								options={usStateData.map((states, index) => {
									return (
										<option key={index} value={states.abbreviation}>
											{states.name}
										</option>
									)
								})}
								onChange={e => onStateChange(e)}
							/>
						</div>
					</div>
					{/* <div className="form-group">
							<label htmlFor="text_field">Zip</label>
							<input
								type="text"
								placeholder="Zip"
								name="zip"
								value={zip}
								onChange={e => inputChange(e)}
							/>
						</div> */}

					<Card className="card" />
					<Button isInput variant="success" label={'Submit Order'} type={'submit'} />
				</form>
			</div>
			<div className="order-summary">
				<h3>Order Summary</h3>
				<div className="summary-container">
					<div className="summary-item name">
						<dl>
							<dt>Tournament Level</dt>
							<dd>{name}</dd>
						</dl>
					</div>
					<div className="summary-item features">
						<dl>
							<dt>Features</dt>
							{options.map((option, index) => {
								return (
									<dd key={index}>
										<FontAwesomeIcon icon={{ prefix: 'far', iconName: 'check-circle' }} />
										{option}
									</dd>
								)
							})}
						</dl>
					</div>
					<div className="summary-item price">
						<dl className="dl-inline">
							<dt>Price</dt>
							<dd>${tournPrice}</dd>
						</dl>
					</div>
					<div className="summary-item tax">
						<dl className="dl-inline">
							<dt>Tax</dt>
							<dd>${tax.toFixed(2)}</dd>
						</dl>
					</div>
					<hr className="total-divider" />
					<div className="summary-item total">
						<dl className="dl-inline">
							<dt>Total</dt>
							<dd>${tournPrice + tax}</dd>
						</dl>
					</div>
				</div>
			</div>
		</div>
	)
}

CheckoutForm.propTypes = {
	submitPayment: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
	// tournament: state.tournament,
	payment: state.payment.payment,
	paymentLevel: state.payment.paymentLevel,
})

// export default (connect)injectStripe(CheckoutForm)

export default injectStripe(connect(mapStateToProps, { submitPayment })(CheckoutForm))
