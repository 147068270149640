import React from 'react'
import logo from '../../../img/logo-color.png'

const LogoTitle = ({ title, tag, className }) => {
	return (
		<div className={`logo-title-container${className ? ' ' + className : ''}`}>
			<div className="logo-container">
				<div className="brand-graphic-container">
					<img className="brand-graphic img-responsive" src={logo} alt="find fishing tournaments .com logo" />
				</div>
				{tag === 'h1' && <h1>{title}</h1>}
				{tag === 'h2' && <h2>{title}</h2>}
				{tag === 'h3' && <h3>{title}</h3>}
			</div>
		</div>
	)
}

export default LogoTitle
