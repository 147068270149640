import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { setAlert } from '../../../actions/alert'
import { register } from '../../../actions/auth'
// import SelectElem from '../../forms/SelectElem'

const RegisterForm = ({ setAlert, register, registrationType, history }) => {
	const dispatch = useDispatch()
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		password: '',
		password2: '',
		role: registrationType,
	})

	const { name, email, password, password2, role } = formData

	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

	const onSubmit = async e => {
		e.preventDefault()
		if (password !== password2) {
			dispatch(setAlert('Passwords do not match', 'danger'))
		} else {
			register({ name, email, password, role, history })
		}
	}

	return (
		<>
			<form className="form" onSubmit={e => onSubmit(e)}>
				<fieldset className="form-section">
					<div className="form-group">
						<label htmlFor="name">Name:</label>
						<input type="text" placeholder="Name" name="name" value={name} onChange={e => onChange(e)} />
					</div>
					<div className="form-group">
						<label htmlFor="email">Email:</label>
						<input
							type="email"
							placeholder="Email Address"
							name="email"
							value={email}
							onChange={e => onChange(e)}
						/>
					</div>
					{/* <div className="form-group">
				<label htmlFor="select_element">Account Type:</label>
				<SelectElem
					options={
						<>
							<option value="1">Option 1</option>
							<option value="2">Option 2</option>
						</>
					}
				/>
			</div> */}
					<div className="form-group">
						<label htmlFor="password">Password:</label>
						<input
							type="password"
							placeholder="Password"
							name="password"
							value={password}
							onChange={e => onChange(e)}
						/>
					</div>
					<div className="form-group">
						<input
							type="password"
							placeholder="Confirm Password"
							name="password2"
							value={password2}
							onChange={e => onChange(e)}
						/>
					</div>
				</fieldset>
				<input type="submit" className="btn btn-primary" value="Register" />
			</form>
			<p className="info-text">
				Already have an account? <Link to="/login">Sign In</Link>
			</p>
		</>
	)
}

RegisterForm.propTypes = {
	registrationType: PropTypes.string,
	setAlert: PropTypes.func.isRequired,
	register: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
	registrationType: state.auth.registrationType,
})

export default connect(mapStateToProps, { setAlert, register })(RegisterForm)
