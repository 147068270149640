import React from 'react'

const StyleButton = ({ onToggle, active, label, style }) => {
	let className = 'RichEditor-styleButton'
	if (active) {
		className += ' RichEditor-activeButton'
	}

	return (
		<span
			className={className}
			onMouseDown={e => {
				e.preventDefault()
				onToggle(style)
			}}
		>
			{label}
		</span>
	)
}

export default StyleButton
