import React from 'react'
import InnerStructure from '../Layout/InnerStructure'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChangePasswordForm from '../forms/registration/ChangePasswordForm'
import LogoTitle from '../Layout/Elements/LogoTitle'

// This is the "landing page" element from the forgot password email link

const ResetPassword = ({ match }) => {
	// Split the url ID on the + to get the user id and the password reset code
	const urlIdString = match.params.id.split('+')
	const userId = urlIdString[0]
	const resetCode = urlIdString[1]
	// pass the "userId" and "resetCode" into the "ChangePasswordForm" element

	// console.log('userId = ', userId)
	// console.log('resetCode = ', resetCode)

	return (
		<InnerStructure className="reset-password-container">
			<LogoTitle tag="h1" title="Reset Password" className="spacing-bottom-3" />
			<ChangePasswordForm userId={userId} resetCode={resetCode} fromEmail={true} />
		</InnerStructure>
	)
}

export default ResetPassword
