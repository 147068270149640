import React from 'react'
import InnerStructure from '../Layout/InnerStructure'
import RequestPasswordChangeForm from '../forms/registration/RequestPasswordChangeForm'

const RequestPasswordChange = () => {
	return (
		<InnerStructure>
			<div className="password-assistance">
				<h1>Password Assistance</h1>
				<RequestPasswordChangeForm />
			</div>
		</InnerStructure>
	)
}

export default RequestPasswordChange
