import React from 'react'
// import PropTypes from 'prop-types'
import { StripeProvider } from 'react-stripe-elements'
import Checkout from './Checkout'
// import InnerStructure from '../Layout/InnerStructure'

const Payments = ({ orderData }) => {
	return (
		<>
			{/* Live */}
			<StripeProvider apiKey="pk_live_BQrqyDANNivIqFQ8dA5yInKY00GBY0xFaM">
				{/* Dev */}
				{/* <StripeProvider apiKey="pk_test_fjspX2gPgpd3qENB5JJElqcA00351qltNO"> */}
				<div className="payment-form">
					<Checkout orderData={orderData} />
				</div>
			</StripeProvider>
		</>
	)
}

export default Payments
