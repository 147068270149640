import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SelectElem = ({ name, options, onChange, value, firstLineOption }) => {
	return (
		<div className="select-styling-wrapper">
			<FontAwesomeIcon icon={{ prefix: 'fad', iconName: 'triangle' }} />
			<select id={`select_element_${name}`} name={name ? name : null} onChange={onChange} value={value}>
				{firstLineOption ? firstLineOption : null}
				{options}
			</select>
		</div>
	)
}

export default SelectElem
