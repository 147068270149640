import React, { useState, useRef, useEffect } from 'react'
// import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { addTournamentTypes } from '../../../actions/admin'
import { connect } from 'react-redux'
import Button from '../../Layout/Elements/Button'
import { TweenMax } from 'gsap/all'
import InfoBox from '../../Layout/Elements/InfoBox'

const TournamentTypesForm = ({ addTournamentTypes, edit, name, value, color }) => {
	const [formData, setFormData] = useState({
		tournTypeName: '',
		tournTypeValue: '',
		colorValue: '',
		edit: false,
	})

	const colorPanel = useRef()
	const backdrop = useRef()

	const { tournTypeName } = formData

	const [colorPickerView, setColorPickerView] = useState(false)
	const [colorPickerValue, setColorPickerValue] = useState('')
	const [tournTypeValueText, setTournTypeValueText] = useState('')

	useEffect(() => {
		if (edit) {
			setFormData({
				tournTypeName: name,
				tournTypeValue: value,
				colorValue: color,
				edit: true,
			})
		}
		setColorPickerValue(color)
	}, [edit])

	const handleColorPicker = e => {
		setColorPickerValue(`hsl(${e.target.textContent}, 78%, 41%)`)
		setFormData({ ...formData, colorValue: `hsl(${e.target.textContent}, 78%, 41%)` })
		// console.log(formData)
	}

	if (colorPickerView) {
		// console.log(backdrop.current)
		TweenMax.to(colorPanel.current, 0.5, { x: '-100%' })
		TweenMax.to(backdrop.current, 0.5, { opacity: '1' })
	} else {
		TweenMax.to(colorPanel.current, 0.5, { x: '0%' })
		TweenMax.to(backdrop.current, 0.5, { opacity: '0' })
	}

	const onTournNameInputChange = e => {
		let name = e.target.value
		name = name
			.replace(/ \/ /g, '_')
			.replace(/ /g, '_')
			.replace(/\//g, '_')
			.toLowerCase()

		// console.log(name)
		setTournTypeValueText(name)
		setFormData({ ...formData, tournTypeName: e.target.value, tournTypeValue: name })
		// console.log(formData)
	}

	const onInputChange = e => {}
	//
	//
	const onSubmit = e => {
		e.preventDefault()

		addTournamentTypes(formData)

		if (!edit) {
			setFormData({ tournTypeName: '', tournTypeValue: '', colorValue: '' })
			setColorPickerValue('')
			setTournTypeValueText('')
		}

		// setColorPickerView(false)
	}

	const numberMap = []
	for (var i = 0; i < 359; i++) {
		if (i && i % 3 === 0) {
			numberMap.push(i)
		}
	}
	// console.log(numberMap)

	return (
		<div className="tournament-types-form-container">
			{!edit && <h2>Add New Tournament Type</h2>}
			{edit && <h4>Edit Tournament Type</h4>}
			<form onSubmit={e => onSubmit(e)} className="tourn-type-form">
				<div className="form-content">
					<div className="form-group">
						<label htmlFor="text_field">Tournament Type Name</label>
						<input
							type="text"
							placeholder="Tournament Type Name"
							name="tournTypeName"
							value={tournTypeName}
							onChange={e => onTournNameInputChange(e)}
						/>
						<p className="field-note">
							Tournament Type Value used for form submissions = {tournTypeValueText}
						</p>
					</div>

					<div className="form-group">
						<label htmlFor="text_field">Tournament Type Color</label>

						<input
							type="text"
							// placeholder="hsl()"
							name="colorValue"
							value={colorPickerValue}
							style={{ background: `${colorPickerValue}`, color: 'hsl(0, 0%, 96%)' }}
							onChange={e => onInputChange(e)}
							onClick={() => setColorPickerView(!colorPickerView)}
							// onClick={() => toggleColorPicker()}
						/>
					</div>

					<div className="form-group submit-btn-container">
						<div className="form-group submit-btn">
							<Button variant="primary" label={'Submit'} />
						</div>
					</div>
				</div>
				<div className="tourn-colors" ref={colorPanel}>
					<div className="form-group color-picker-btn">
						<Button
							variant="primary"
							isAnchor
							label={colorPickerView ? 'Close Color Picker' : 'Open Color Picker'}
							onClick={() => setColorPickerView(!colorPickerView)}
							// onClick={() => toggleColorPicker()}
						/>
					</div>
					{/* <div className="form-row row-fluid">
						<p className="field-instructions">
							Click on the color picker to add the Tournament Type Color as hsl().
						</p>
					</div> */}
					<InfoBox iconName="eye-dropper">
						Click on the color picker to add the Tournament Type Color as hsl().
					</InfoBox>
					{/* {colorPickerView && ( */}
					<div className="swatch-block">
						{numberMap.map((num, index) => {
							return (
								<div
									key={index}
									className="swatch"
									value={num}
									style={{ backgroundColor: `hsl(${num}, 78%, 41%)` }}
									onClick={e => handleColorPicker(e)}
								>
									{/* hsl({num}, 78%, 41%) */}
									{num}
								</div>
							)
						})}
					</div>
					{/* )} */}
				</div>
				<div className={`backdrop ${colorPickerView ? 'active' : 'inactive'}`} ref={backdrop}></div>
			</form>
		</div>
	)
}

TournamentTypesForm.propTypes = {
	addTournamentTypes: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, { addTournamentTypes })(TournamentTypesForm)
