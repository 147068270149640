import React from 'react'

const TournamentOption = ({ title, optionsList, price, onClick }) => {
	return (
		<div className="tournament-option" onClick={onClick}>
			<h6 className="option-title">{title}</h6>
			<p>{title} Listing Package Includes:</p>
			<ul className="options-list">
				{optionsList.map((option, index) => {
					return <li key={index}>{option}</li>
				})}
			</ul>

			<div className="price">{price}</div>
		</div>
	)
}

export default TournamentOption
