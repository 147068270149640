import React, { useState } from 'react'

const Headings = () => {
	const [tag, setTag] = useState(<h1>Heading 1</h1>)

	const handleClick = tag => {
		setTag(tag)
	}
	return (
		<>
			<div className="column-container">
				<div className="left-column">
					<hr />
					<h1 onClick={() => handleClick(<h1>Heading 1</h1>)}>Heading 1</h1>
					<hr />
					<h2 onClick={() => handleClick(<h2>Heading 2</h2>)}>Heading 2</h2>
					<hr />
					<h3 onClick={() => handleClick(<h3>Heading 3</h3>)}>Heading 3</h3>
					<hr />
					<h4 onClick={() => handleClick(<h4>Heading 4</h4>)}>Heading 4</h4>
					<hr />
					<h5 onClick={() => handleClick(<h5>Heading 5</h5>)}>Heading 5</h5>
					<hr />
					<h6 onClick={() => handleClick(<h6>Heading 6</h6>)}>Heading 6</h6>
					<hr />
				</div>
				<div className="right-column">
					{tag}
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ac lorem dignissim,
						sollicitudin nibh vitae, porta sapien. Nunc facilisis quam et lectus dapibus mollis. Praesent
						luctus, mauris ac ornare commodo, ex enim blandit ligula, non feugiat felis tortor id nisi. Duis
						placerat viverra quam, eget congue tellus egestas ac. Nulla facilisi. Nunc sit amet ultrices
						eros. Nam est lectus, interdum vel ornare in, posuere id orci. Vestibulum eget urna varius,
						pellentesque nunc sit amet, aliquet justo.
					</p>
				</div>
			</div>
		</>
	)
}

export default Headings
