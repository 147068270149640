import React from 'react'
import Button from '../../Elements/Button'

const AdvertiseCallout = () => {
	return (
		<div className="callout-container">
			<h3 className="callout-title">Looking To Advertise?</h3>
			<p>
				FindFishingTournaments.com's tournament search platform connects our users to fishing tournaments all
				accross the country. Let them know your business is in the area by advertising with
				FindFishingTournaments.com.
			</p>
			<Button variant="primary" label={'Get Started'} outline={true} isLink to="/advertise" />
		</div>
	)
}

export default AdvertiseCallout
