import React, { Fragment } from 'react'
import Button from '../Elements/Button'

const Buttons = () => {
	return (
		<Fragment>
			<div className="section-group">
				<h2 className="section-group-title">{'<Button>'}</h2>
				<div className="sub-section">
					<h3 className="sub-section-title">Large</h3>
					<Button variant="primary" size={'large'} label={'Primary'} />
					<Button variant="secondary" size={'large'} label={'Secondary'} />
					<Button variant="success" size={'large'} label={'Success'} />
					<Button variant="warning" size={'large'} label={'Warning'} />
					<Button variant="danger" size={'large'} label={'Danger'} />
					<Button variant="info" size={'large'} label={'Info'} />
					<Button variant="light" size={'large'} label={'Light'} />
					<Button variant="dark" size={'large'} label={'Dark'} />
					<Button variant="link" size={'large'} label={'Link'} />
					<Button variant="primary" size={'large'} label={'Disabled'} disabled />

					<pre>
						<code>
							{`<Button variant="primary" size={'large'} label={'Primary'} />
<Button variant="secondary" size={'large'} label={'Secondary'} />
<Button variant="success" size={'large'} label={'Success'} />
<Button variant="warning" size={'large'} label={'Warning'} />
<Button variant="danger" size={'large'} label={'Danger'} />
<Button variant="info" size={'large'} label={'Info'} />
<Button variant="light" size={'large'} label={'Light'} />
<Button variant="dark" size={'large'} label={'Dark'} />
<Button variant="link" size={'large'} label={'Link'} />`}
						</code>
					</pre>

					<Button variant="primary" size={'large'} label={'Primary'} outline={true} />
					<Button variant="secondary" size={'large'} label={'Secondary'} outline={true} />
					<Button variant="success" size={'large'} label={'Success'} outline={true} />
					<Button variant="warning" size={'large'} label={'Warning'} outline={true} />
					<Button variant="danger" size={'large'} label={'Danger'} outline={true} />
					<Button variant="info" size={'large'} label={'Info'} outline={true} />
					<Button variant="light" size={'large'} label={'Light'} outline={true} />
					<Button variant="dark" size={'large'} label={'Dark'} outline={true} />
					<Button variant="link" size={'large'} label={'Link'} outline={true} />

					<pre>
						<code>
							{`<Button variant="primary" size={'large'} label={'Primary'} outline={true} />
<Button variant="secondary" size={'large'} label={'Secondary'} outline={true} />
<Button variant="success" size={'large'} label={'Success'} outline={true} />
<Button variant="warning" size={'large'} label={'Warning'} outline={true} />
<Button variant="danger" size={'large'} label={'Danger'} outline={true} />
<Button variant="info" size={'large'} label={'Info'} outline={true} />
<Button variant="light" size={'large'} label={'Light'} outline={true} />
<Button variant="dark" size={'large'} label={'Dark'} outline={true} />
<Button variant="link" size={'large'} label={'Link'} outline={true} />`}
						</code>
					</pre>
				</div>

				<div className="sub-section">
					<h3 className="sub-section-title">With Icon</h3>
					<Button variant="primary" label={'View Details'} icon={'info-circle'} iconType={'fas'} />
					<pre>
						<code>
							{`With Icon
<Button variant="primary" label="View Details" icon="info-circle" iconType="fas"
/>
`}
						</code>
					</pre>
				</div>

				{/* TODO: Finish Styling for icon only button sizes */}
				<div className="sub-section">
					<h3 className="sub-section-title">Icon Only</h3>
					<Button
						variant="danger"
						iconOnly={true}
						icon="times-octagon"
						iconType="fas"
						size="large"
						onClick={() => {}}
					/>
					<Button variant="danger" iconOnly={true} icon="times-octagon" iconType="fas" onClick={() => {}} />
					<Button
						variant="danger"
						iconOnly={true}
						icon="times-octagon"
						iconType="fas"
						size="small"
						onClick={() => {}}
					/>
					<pre>
						<code>
							{`Icon Only
<Button variant="danger" iconOnly={true} icon="times-octagon" iconType="fas" size="small" onClick={() => {}} />
/>
`}
						</code>
					</pre>
				</div>

				<div className="sub-section">
					<h3 className="sub-section-title">Standard</h3>
					<Button variant="primary" label={'Primary'} />
					<Button variant="secondary" label={'Secondary'} />
					<Button variant="success" label={'Success'} />
					<Button variant="warning" label={'Warning'} />
					<Button variant="danger" label={'Danger'} />
					<Button variant="info" label={'Info'} />
					<Button variant="light" label={'Light'} />
					<Button variant="dark" label={'Dark'} />
					<Button variant="link" label={'Link'} />
					<pre>
						<code>
							{`<Button variant="primary" label={'Primary'} />
<Button variant="secondary" label={'Secondary'} />
<Button variant="success" label={'Success'} />
<Button variant="warning" label={'Warning'} />
<Button variant="danger" label={'Danger'} />
<Button variant="info" label={'Info'} />
<Button variant="light" label={'Light'} />
<Button variant="dark" label={'Dark'} />
<Button variant="link" label={'Link'} />`}
						</code>
					</pre>

					<Button variant="primary" label={'Primary'} outline={true} />
					<Button variant="secondary" label={'Secondary'} outline={true} />
					<Button variant="success" label={'Success'} outline={true} />
					<Button variant="warning" label={'Warning'} outline={true} />
					<Button variant="danger" label={'Danger'} outline={true} />
					<Button variant="info" label={'Info'} outline={true} />
					<Button variant="light" label={'Light'} outline={true} />
					<Button variant="dark" label={'Dark'} outline={true} />
					<Button variant="link" label={'Link'} outline={true} />

					<pre>
						<code>
							{`<Button variant="primary" label={'Primary'} outline={true} />
<Button variant="secondary" label={'Secondary'} outline={true} />
<Button variant="success" label={'Success'} outline={true} />
<Button variant="warning" label={'Warning'} outline={true} />
<Button variant="danger" label={'Danger'} outline={true} />
<Button variant="info" label={'Info'} outline={true} />
<Button variant="light" label={'Light'} outline={true} />
<Button variant="dark" label={'Dark'} outline={true} />
<Button variant="link" label={'Link'} outline={true} />`}
						</code>
					</pre>
				</div>

				<div className="sub-section">
					<h3 className="sub-section-title">Small</h3>
					<Button variant="primary" size={'small'} label={'Primary'} />
					<Button variant="secondary" size={'small'} label={'Secondary'} />
					<Button variant="success" size={'small'} label={'Success'} />
					<Button variant="warning" size={'small'} label={'Warning'} />
					<Button variant="danger" size={'small'} label={'Danger'} />
					<Button variant="info" size={'small'} label={'Info'} />
					<Button variant="light" size={'small'} label={'Light'} />
					<Button variant="dark" size={'small'} label={'Dark'} />
					<Button variant="link" size={'small'} label={'Link'} />

					<pre>
						<code>
							{`<Button variant="primary" size={'small'} label={'Primary'} />
<Button variant="secondary" size={'small'} label={'Secondary'} />
<Button variant="success" size={'small'} label={'Success'} />
<Button variant="warning" size={'small'} label={'Warning'} />
<Button variant="danger" size={'small'} label={'Danger'} />
<Button variant="info" size={'small'} label={'Info'} />
<Button variant="light" size={'small'} label={'Light'} />
<Button variant="dark" size={'small'} label={'Dark'} />
<Button variant="link" size={'small'} label={'Link'}`}
						</code>
					</pre>

					<Button variant="primary" size={'small'} label={'Primary'} outline={true} />
					<Button variant="secondary" size={'small'} label={'Secondary'} outline={true} />
					<Button variant="success" size={'small'} label={'Success'} outline={true} />
					<Button variant="warning" size={'small'} label={'Warning'} outline={true} />
					<Button variant="danger" size={'small'} label={'Danger'} outline={true} />
					<Button variant="info" size={'small'} label={'Info'} outline={true} />
					<Button variant="light" size={'small'} label={'Light'} outline={true} />
					<Button variant="dark" size={'small'} label={'Dark'} outline={true} />
					<Button variant="link" size={'small'} label={'Link'} outline={true} />

					<pre>
						<code>
							{`<Button variant="primary" size={'small'} label={'Primary'} outline={true} />
<Button variant="secondary" size={'small'} label={'Secondary'} outline={true} />
<Button variant="success" size={'small'} label={'Success'} outline={true} />
<Button variant="warning" size={'small'} label={'Warning'} outline={true} />
<Button variant="danger" size={'small'} label={'Danger'} outline={true} />
<Button variant="info" size={'small'} label={'Info'} outline={true} />
<Button variant="light" size={'small'} label={'Light'} outline={true} />
<Button variant="dark" size={'small'} label={'Dark'} outline={true} />
<Button variant="link" size={'small'} label={'Link'} outline={true} />`}
						</code>
					</pre>
				</div>
			</div>

			<div className="section-group">
				<h2 className="section-group-title">{'<input>'}</h2>
				<div className="sub-section">
					<h3 className="sub-section-title">Large</h3>
					<Button isInput variant="primary" size={'large'} label={'Primary'} />
					<Button isInput variant="secondary" size={'large'} label={'Secondary'} />
					<Button isInput variant="success" size={'large'} label={'Success'} />
					<Button isInput variant="warning" size={'large'} label={'Warning'} />
					<Button isInput variant="danger" size={'large'} label={'Danger'} />
					<Button isInput variant="info" size={'large'} label={'Info'} />
					<Button isInput variant="light" size={'large'} label={'Light'} />
					<Button isInput variant="dark" size={'large'} label={'Dark'} />
					<Button isInput variant="link" size={'large'} label={'Link'} />
					<pre>
						<code>
							{`<Button isInput variant="primary" size={'large'} label={'Primary'} />
<Button isInput variant="secondary" size={'large'} label={'Secondary'} />
<Button isInput variant="success" size={'large'} label={'Success'} />
<Button isInput variant="warning" size={'large'} label={'Warning'} />
<Button isInput variant="danger" size={'large'} label={'Danger'} />
<Button isInput variant="info" size={'large'} label={'Info'} />
<Button isInput variant="light" size={'large'} label={'Light'} />
<Button isInput variant="dark" size={'large'} label={'Dark'} />
<Button isInput variant="link" size={'large'} label={'Link'} />`}
						</code>
					</pre>
					<Button isInput variant="primary" size={'large'} label={'Primary'} outline={true} />
					<Button isInput variant="secondary" size={'large'} label={'Secondary'} outline={true} />
					<Button isInput variant="success" size={'large'} label={'Success'} outline={true} />
					<Button isInput variant="warning" size={'large'} label={'Warning'} outline={true} />
					<Button isInput variant="danger" size={'large'} label={'Danger'} outline={true} />
					<Button isInput variant="info" size={'large'} label={'Info'} outline={true} />
					<Button isInput variant="light" size={'large'} label={'Light'} outline={true} />
					<Button isInput variant="dark" size={'large'} label={'Dark'} outline={true} />
					<Button isInput variant="link" size={'large'} label={'Link'} outline={true} />
					<pre>
						<code>
							{`<Button isInput variant="primary" size={'large'} label={'Primary'} outline={true} />
<Button isInput variant="secondary" size={'large'} label={'Secondary'} outline={true} />
<Button isInput variant="success" size={'large'} label={'Success'} outline={true} />
<Button isInput variant="warning" size={'large'} label={'Warning'} outline={true} />
<Button isInput variant="danger" size={'large'} label={'Danger'} outline={true} />
<Button isInput variant="info" size={'large'} label={'Info'} outline={true} />
<Button isInput variant="light" size={'large'} label={'Light'} outline={true} />
<Button isInput variant="dark" size={'large'} label={'Dark'} outline={true} />
<Button isInput variant="link" size={'large'} label={'Link'} outline={true} />`}
						</code>
					</pre>
				</div>

				<div className="sub-section">
					<h3 className="sub-section-title">Standard</h3>
					<Button isInput variant="primary" label={'Primary'} />
					<Button isInput variant="secondary" label={'Secondary'} />
					<Button isInput variant="success" label={'Success'} />
					<Button isInput variant="warning" label={'Warning'} />
					<Button isInput variant="danger" label={'Danger'} />
					<Button isInput variant="info" label={'Info'} />
					<Button isInput variant="light" label={'Light'} />
					<Button isInput variant="dark" label={'Dark'} />
					<Button isInput variant="link" label={'Link'} />
					<pre>
						<code>
							{`<Button isInput variant="primary" label={'Primary'} />
<Button isInput variant="secondary" label={'Secondary'} />
<Button isInput variant="success" label={'Success'} />
<Button isInput variant="warning" label={'Warning'} />
<Button isInput variant="danger" label={'Danger'} />
<Button isInput variant="info" label={'Info'} />
<Button isInput variant="light" label={'Light'} />
<Button isInput variant="dark" label={'Dark'} />
<Button isInput variant="link" label={'Link'} />`}
						</code>
					</pre>
					<Button isInput variant="primary" label={'Primary'} outline={true} />
					<Button isInput variant="secondary" label={'Secondary'} outline={true} />
					<Button isInput variant="success" label={'Success'} outline={true} />
					<Button isInput variant="warning" label={'Warning'} outline={true} />
					<Button isInput variant="danger" label={'Danger'} outline={true} />
					<Button isInput variant="info" label={'Info'} outline={true} />
					<Button isInput variant="light" label={'Light'} outline={true} />
					<Button isInput variant="dark" label={'Dark'} outline={true} />
					<Button isInput variant="link" label={'Link'} outline={true} />
					<pre>
						<code>
							{`<Button isInput variant="primary" label={'Primary'} outline={true} />
<Button isInput variant="secondary" label={'Secondary'} outline={true} />
<Button isInput variant="success" label={'Success'} outline={true} />
<Button isInput variant="warning" label={'Warning'} outline={true} />
<Button isInput variant="danger" label={'Danger'} outline={true} />
<Button isInput variant="info" label={'Info'} outline={true} />
<Button isInput variant="light" label={'Light'} outline={true} />
<Button isInput variant="dark" label={'Dark'} outline={true} />
<Button isInput variant="link" label={'Link'} outline={true} />`}
						</code>
					</pre>
				</div>

				<div className="sub-section">
					<h3 className="sub-section-title">Small</h3>
					<Button isInput variant="primary" size={'small'} label={'Primary'} />
					<Button isInput variant="secondary" size={'small'} label={'Secondary'} />
					<Button isInput variant="success" size={'small'} label={'Success'} />
					<Button isInput variant="warning" size={'small'} label={'Warning'} />
					<Button isInput variant="danger" size={'small'} label={'Danger'} />
					<Button isInput variant="info" size={'small'} label={'Info'} />
					<Button isInput variant="light" size={'small'} label={'Light'} />
					<Button isInput variant="dark" size={'small'} label={'Dark'} />
					<Button isInput variant="link" size={'small'} label={'Link'} />
					<pre>
						<code>
							{`<Button isInput variant="primary" size={'small'} label={'Primary'} />
<Button isInput variant="secondary" size={'small'} label={'Secondary'} />
<Button isInput variant="success" size={'small'} label={'Success'} />
<Button isInput variant="warning" size={'small'} label={'Warning'} />
<Button isInput variant="danger" size={'small'} label={'Danger'} />
<Button isInput variant="info" size={'small'} label={'Info'} />
<Button isInput variant="light" size={'small'} label={'Light'} />
<Button isInput variant="dark" size={'small'} label={'Dark'} />
<Button isInput variant="link" size={'small'} label={'Link'} />`}
						</code>
					</pre>
					<Button isInput variant="primary" size={'small'} label={'Primary'} outline={true} />
					<Button isInput variant="secondary" size={'small'} label={'Secondary'} outline={true} />
					<Button isInput variant="success" size={'small'} label={'Success'} outline={true} />
					<Button isInput variant="warning" size={'small'} label={'Warning'} outline={true} />
					<Button isInput variant="danger" size={'small'} label={'Danger'} outline={true} />
					<Button isInput variant="info" size={'small'} label={'Info'} outline={true} />
					<Button isInput variant="light" size={'small'} label={'Light'} outline={true} />
					<Button isInput variant="dark" size={'small'} label={'Dark'} outline={true} />
					<Button isInput variant="link" size={'small'} label={'Link'} outline={true} />
					<pre>
						<code>
							{`<Button isInput variant="primary" size={'small'} label={'Primary'} outline={true} />
<Button isInput variant="secondary" size={'small'} label={'Secondary'} outline={true} />
<Button isInput variant="success" size={'small'} label={'Success'} outline={true} />
<Button isInput variant="warning" size={'small'} label={'Warning'} outline={true} />
<Button isInput variant="danger" size={'small'} label={'Danger'} outline={true} />
<Button isInput variant="info" size={'small'} label={'Info'} outline={true} />
<Button isInput variant="light" size={'small'} label={'Light'} outline={true} />
<Button isInput variant="dark" size={'small'} label={'Dark'} outline={true} />
<Button isInput variant="link" size={'small'} label={'Link'} outline={true} />`}
						</code>
					</pre>
				</div>
			</div>

			<div className="section-group">
				<h2 className="section-group-title">{'<a href>'}</h2>
				<div className="sub-section">
					<h3 className="sub-section-title">Large</h3>
					<Button isLink to={'#!'} variant="primary" size={'large'} label={'Primary'} />
					<Button isLink to={'#!'} variant="secondary" size={'large'} label={'Secondary'} />
					<Button isLink to={'#!'} variant="success" size={'large'} label={'Success'} />
					<Button isLink to={'#!'} variant="warning" size={'large'} label={'Warning'} />
					<Button isLink to={'#!'} variant="danger" size={'large'} label={'Danger'} />
					<Button isLink to={'#!'} variant="info" size={'large'} label={'Info'} />
					<Button isLink to={'#!'} variant="light" size={'large'} label={'Light'} />
					<Button isLink to={'#!'} variant="dark" size={'large'} label={'Dark'} />
					<Button isLink to={'#!'} variant="link" size={'large'} label={'Link'} />
					<pre>
						<code>
							{`<Button isLink to={'#!'} variant="primary" size={'large'} label={'Primary'} />
<Button isLink to={'#!'} variant="secondary" size={'large'} label={'Secondary'} />
<Button isLink to={'#!'} variant="success" size={'large'} label={'Success'} />
<Button isLink to={'#!'} variant="warning" size={'large'} label={'Warning'} />
<Button isLink to={'#!'} variant="danger" size={'large'} label={'Danger'} />
<Button isLink to={'#!'} variant="info" size={'large'} label={'Info'} />
<Button isLink to={'#!'} variant="light" size={'large'} label={'Light'} />
<Button isLink to={'#!'} variant="dark" size={'large'} label={'Dark'} />
<Button isLink to={'#!'} variant="link" size={'large'} label={'Link'} />`}
						</code>
					</pre>
					<Button isLink to={'#!'} variant="primary" size={'large'} label={'Primary'} outline={true} />
					<Button isLink to={'#!'} variant="secondary" size={'large'} label={'Secondary'} outline={true} />
					<Button isLink to={'#!'} variant="success" size={'large'} label={'Success'} outline={true} />
					<Button isLink to={'#!'} variant="warning" size={'large'} label={'Warning'} outline={true} />
					<Button isLink to={'#!'} variant="danger" size={'large'} label={'Danger'} outline={true} />
					<Button isLink to={'#!'} variant="info" size={'large'} label={'Info'} outline={true} />
					<Button isLink to={'#!'} variant="light" size={'large'} label={'Light'} outline={true} />
					<Button isLink to={'#!'} variant="dark" size={'large'} label={'Dark'} outline={true} />
					<Button isLink to={'#!'} variant="link" size={'large'} label={'Link'} outline={true} />
					<pre>
						<code>
							{`<Button isLink to={'#!'} variant="primary" size={'large'} label={'Primary'} outline={true} />
<Button isLink to={'#!'} variant="secondary" size={'large'} label={'Secondary'} outline={true} />
<Button isLink to={'#!'} variant="success" size={'large'} label={'Success'} outline={true} />
<Button isLink to={'#!'} variant="warning" size={'large'} label={'Warning'} outline={true} />
<Button isLink to={'#!'} variant="danger" size={'large'} label={'Danger'} outline={true} />
<Button isLink to={'#!'} variant="info" size={'large'} label={'Info'} outline={true} />
<Button isLink to={'#!'} variant="light" size={'large'} label={'Light'} outline={true} />
<Button isLink to={'#!'} variant="dark" size={'large'} label={'Dark'} outline={true} />
<Button isLink to={'#!'} variant="link" size={'large'} label={'Link'} outline={true} />`}
						</code>
					</pre>
				</div>

				<div className="sub-section">
					<h3 className="sub-section-title">Standard</h3>
					<Button isLink to={'#!'} variant="primary" label={'Primary'} />
					<Button isLink to={'#!'} variant="secondary" label={'Secondary'} />
					<Button isLink to={'#!'} variant="success" label={'Success'} />
					<Button isLink to={'#!'} variant="warning" label={'Warning'} />
					<Button isLink to={'#!'} variant="danger" label={'Danger'} />
					<Button isLink to={'#!'} variant="info" label={'Info'} />
					<Button isLink to={'#!'} variant="light" label={'Light'} />
					<Button isLink to={'#!'} variant="dark" label={'Dark'} />
					<Button isLink to={'#!'} variant="link" label={'Link'} />
					<pre>
						<code>
							{`<Button isLink to={'#!'} variant="primary" label={'Primary'} />
<Button isLink to={'#!'} variant="secondary" label={'Secondary'} />
<Button isLink to={'#!'} variant="success" label={'Success'} />
<Button isLink to={'#!'} variant="warning" label={'Warning'} />
<Button isLink to={'#!'} variant="danger" label={'Danger'} />
<Button isLink to={'#!'} variant="info" label={'Info'} />
<Button isLink to={'#!'} variant="light" label={'Light'} />
<Button isLink to={'#!'} variant="dark" label={'Dark'} />
<Button isLink to={'#!'} variant="link" label={'Link'} />`}
						</code>
					</pre>
					<Button isLink to={'#!'} variant="primary" label={'Primary'} outline={true} />
					<Button isLink to={'#!'} variant="secondary" label={'Secondary'} outline={true} />
					<Button isLink to={'#!'} variant="success" label={'Success'} outline={true} />
					<Button isLink to={'#!'} variant="warning" label={'Warning'} outline={true} />
					<Button isLink to={'#!'} variant="danger" label={'Danger'} outline={true} />
					<Button isLink to={'#!'} variant="info" label={'Info'} outline={true} />
					<Button isLink to={'#!'} variant="light" label={'Light'} outline={true} />
					<Button isLink to={'#!'} variant="dark" label={'Dark'} outline={true} />
					<Button isLink to={'#!'} variant="link" label={'Link'} outline={true} />
					<pre>
						<code>
							{`<Button isLink to={'#!'} variant="primary" label={'Primary'} outline={true} />
<Button isLink to={'#!'} variant="secondary" label={'Secondary'} outline={true} />
<Button isLink to={'#!'} variant="success" label={'Success'} outline={true} />
<Button isLink to={'#!'} variant="warning" label={'Warning'} outline={true} />
<Button isLink to={'#!'} variant="danger" label={'Danger'} outline={true} />
<Button isLink to={'#!'} variant="info" label={'Info'} outline={true} />
<Button isLink to={'#!'} variant="light" label={'Light'} outline={true} />
<Button isLink to={'#!'} variant="dark" label={'Dark'} outline={true} />
<Button isLink to={'#!'} variant="link" label={'Link'} outline={true} />`}
						</code>
					</pre>
				</div>

				<div className="sub-section">
					<h3 className="sub-section-title">Small</h3>
					<Button isLink to={'#!'} variant="primary" size={'small'} label={'Primary'} />
					<Button isLink to={'#!'} variant="secondary" size={'small'} label={'Secondary'} />
					<Button isLink to={'#!'} variant="success" size={'small'} label={'Success'} />
					<Button isLink to={'#!'} variant="warning" size={'small'} label={'Warning'} />
					<Button isLink to={'#!'} variant="danger" size={'small'} label={'Danger'} />
					<Button isLink to={'#!'} variant="info" size={'small'} label={'Info'} />
					<Button isLink to={'#!'} variant="light" size={'small'} label={'Light'} />
					<Button isLink to={'#!'} variant="dark" size={'small'} label={'Dark'} />
					<Button isLink to={'#!'} variant="link" size={'small'} label={'Link'} />
					<pre>
						<code>
							{`<Button isLink to={'#!'} variant="primary" size={'small'} label={'Primary'} />
<Button isLink to={'#!'} variant="secondary" size={'small'} label={'Secondary'} />
<Button isLink to={'#!'} variant="success" size={'small'} label={'Success'} />
<Button isLink to={'#!'} variant="warning" size={'small'} label={'Warning'} />
<Button isLink to={'#!'} variant="danger" size={'small'} label={'Danger'} />
<Button isLink to={'#!'} variant="info" size={'small'} label={'Info'} />
<Button isLink to={'#!'} variant="light" size={'small'} label={'Light'} />
<Button isLink to={'#!'} variant="dark" size={'small'} label={'Dark'} />
<Button isLink to={'#!'} variant="link" size={'small'} label={'Link'} />`}
						</code>
					</pre>
					<Button isLink to={'#!'} variant="primary" size={'small'} label={'Primary'} outline={true} />
					<Button isLink to={'#!'} variant="secondary" size={'small'} label={'Secondary'} outline={true} />
					<Button isLink to={'#!'} variant="success" size={'small'} label={'Success'} outline={true} />
					<Button isLink to={'#!'} variant="warning" size={'small'} label={'Warning'} outline={true} />
					<Button isLink to={'#!'} variant="danger" size={'small'} label={'Danger'} outline={true} />
					<Button isLink to={'#!'} variant="info" size={'small'} label={'Info'} outline={true} />
					<Button isLink to={'#!'} variant="light" size={'small'} label={'Light'} outline={true} />
					<Button isLink to={'#!'} variant="dark" size={'small'} label={'Dark'} outline={true} />
					<Button isLink to={'#!'} variant="link" size={'small'} label={'Link'} outline={true} />
					<pre>
						<code>
							{`<Button isLink to={'#!'} variant="primary" size={'small'} label={'Primary'} outline={true} />
<Button isLink to={'#!'} variant="secondary" size={'small'} label={'Secondary'} outline={true} />
<Button isLink to={'#!'} variant="success" size={'small'} label={'Success'} outline={true} />
<Button isLink to={'#!'} variant="warning" size={'small'} label={'Warning'} outline={true} />
<Button isLink to={'#!'} variant="danger" size={'small'} label={'Danger'} outline={true} />
<Button isLink to={'#!'} variant="info" size={'small'} label={'Info'} outline={true} />
<Button isLink to={'#!'} variant="light" size={'small'} label={'Light'} outline={true} />
<Button isLink to={'#!'} variant="dark" size={'small'} label={'Dark'} outline={true} />
<Button isLink to={'#!'} variant="link" size={'small'} label={'Link'} outline={true} />`}
						</code>
					</pre>
				</div>
			</div>
		</Fragment>
	)
}

export default Buttons
