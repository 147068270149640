import React, { Fragment } from 'react'

const BlockElem = () => {
	return (
		<Fragment>
			<h3>Paragraphs and Images</h3>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
				incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
				exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
				irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
				pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
				deserunt mollit anim id est laborum.
			</p>
			<p>
				Aliquam libero nisi, imperdiet at, tincidunt nec, gravida vehicula, nisl. Praesent
				mattis, massa quis luctus fermentum, turpis mi volutpat justo, eu volutpat enim diam
				eget metus. Maecenas ornare tortor.
			</p>

			<p>
				<img
					alt="Placeholder and Some Alt Text"
					src="https://placehold.it/350x150"
					title="A title element for this placeholder image."
				/>
			</p>

			<p>
				Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nullam dignissim convallis
				est. Quisque aliquam. Donec faucibus. Nunc iaculis suscipit dui. Nam sit amet sem.
			</p>

			<h3>Blockquote</h3>

			<p>
				This is a standard paragraph. Lorem ipsum dolor sit amet, consectetuer adipiscing
				elit.
			</p>
			<blockquote>
				"<strong>This is a blockquote.</strong> Lorem ipsum dolor sit amet, consectetuer
				adipiscing elit. Nullam dignissim convallis est. Quisque aliquam. Donec faucibus.
				Nunc iaculis suscipit dui. Nam sit amet sem. Aliquam libero nisi, imperdiet at,
				tincidunt nec, gravida vehicula, nisl."
			</blockquote>
			<p>
				This is a standard paragraph. Lorem ipsum dolor sit amet, consectetuer adipiscing
				elit.
			</p>

			<h3>Figure-Caption</h3>

			<figure>
				<img src="https://placehold.it/350x150" alt="A placeholder figure." />
				<figcaption>The figcaption element example</figcaption>
			</figure>

			<h3>Details-Summary</h3>

			<details>
				<summary>The summary element example</summary>
				<p>
					The details example text. It may be styled differently based on what browser or
					operating system you are using.
				</p>
			</details>
		</Fragment>
	)
}

export default BlockElem
