import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createProfile, getCurrentProfile } from '../../../actions/profile'
import messages from '../../messages/messages.json'
import Spinner from '../../effects/loading/Spinner'
import InnerStructure from '../../Layout/InnerStructure'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../Layout/Elements/Button'

const EditProfile = ({ profile: { profile, profileLoading }, createProfile, getCurrentProfile, history }) => {
	const [formData, setFormData] = useState({
		zip: '',
		bio: '',
		twitter: '',
		facebook: '',
		youtube: '',
		instagram: '',
	})

	useEffect(() => {
		getCurrentProfile()

		setFormData({
			zip: profileLoading || !profile.zip ? '' : profile.zip,
			bio: profileLoading || !profile.bio ? '' : profile.bio,
			twitter: profileLoading || !profile.social ? '' : profile.social.twitter,
			facebook: profileLoading || !profile.social ? '' : profile.social.facebook,
			youtube: profileLoading || !profile.social ? '' : profile.social.youtube,
			instagram: profileLoading || !profile.social ? '' : profile.social.instagram,
		})
	}, [profileLoading])

	const { zip, bio, twitter, facebook, youtube, instagram } = formData

	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

	const onSubmit = e => {
		e.preventDefault()
		createProfile(formData, history, true)
	}

	return profileLoading && profile === null ? (
		<Spinner />
	) : (
		<InnerStructure>
			<header className="dasboard-section-header">
				<div className="section-title">
					<h1>Edit Your Profile</h1>
				</div>
				<div className="section-options">
					<Button
						isLink
						to="/dashboard"
						variant="link"
						label={'Go Back'}
						icon={'hand-point-left'}
						iconType={'fad'}
						size="small"
					/>
				</div>
			</header>

			<form className="form spacing-bottom-4" onSubmit={e => onSubmit(e)}>
				<div className="form-group">
					<label htmlFor="zip">{messages.zip.long}</label>
					<p className="form-text">{messages.zip.desc}</p>
					<input type="text" placeholder="Location" name="zip" value={zip} onChange={e => onChange(e)} />
				</div>

				<div className="form-group">
					<small className="form-text">Tell us a little about yourself</small>
					<textarea
						placeholder="A short bio of yourself"
						name="bio"
						value={bio}
						onChange={e => onChange(e)}
					/>
				</div>

				<div className="spacing-top-2">
					<h5>
						{messages.social.short} <span className="title-small">Optional</span>
					</h5>
				</div>
				<div className="user-social-links-edit spacing-top-1 spacing-bottom-2">
					<div className="form-group social-input twitter">
						<FontAwesomeIcon icon={{ prefix: 'fab', iconName: 'twitter-square' }} />
						<input
							type="text"
							placeholder="Twitter URL"
							name="twitter"
							value={twitter}
							onChange={e => onChange(e)}
						/>
					</div>

					<div className="form-group social-input facebook">
						<FontAwesomeIcon icon={{ prefix: 'fab', iconName: 'facebook-square' }} />
						<input
							type="text"
							placeholder="Facebook URL"
							name="facebook"
							value={facebook}
							onChange={e => onChange(e)}
						/>
					</div>

					<div className="form-group social-input youtube">
						<FontAwesomeIcon icon={{ prefix: 'fab', iconName: 'youtube-square' }} />
						<input
							type="text"
							placeholder="YouTube URL"
							name="youtube"
							value={youtube}
							onChange={e => onChange(e)}
						/>
					</div>

					<div className="form-group social-input instagram">
						<FontAwesomeIcon icon={{ prefix: 'fab', iconName: 'instagram' }} />
						<input
							type="text"
							placeholder="Instagram URL"
							name="instagram"
							value={instagram}
							onChange={e => onChange(e)}
						/>
					</div>
				</div>

				<input type="submit" className="btn btn-primary my-1" />
			</form>
		</InnerStructure>
	)
}

EditProfile.propTypes = {
	createProfile: PropTypes.func.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
	profile: state.profile,
})

export default connect(mapStateToProps, { createProfile, getCurrentProfile })(withRouter(EditProfile))
