import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const InfoBox = ({
	variant,
	outline,
	className,
	children,
	iconName,
	size,
	noShadow,
	isNote,
	noRadius,
	noBorder,
	noIcon,
}) => {
	if (isNote) {
		outline = true
		iconName = 'asterisk'
	}

	return (
		<div
			className={`info-box info-box-${variant ? variant : 'info'}${outline ? '-outline' : ''}${
				className ? ' ' + className : ''
			}${size ? ' ' + size : ''}${noShadow ? ' ' + 'flat' : ''}${noRadius ? ' ' + 'sharp' : ''}${
				noBorder ? ' ' + 'no-border' : ''
			}${isNote ? ' ' + 'note' : ''}`}
		>
			{!noIcon && (
				<FontAwesomeIcon
					className="info-box-icon"
					icon={{ prefix: 'fas', iconName: iconName ? iconName : 'info-circle' }}
				/>
			)}
			{isNote && <span className="note-icon-text">Note:</span>}
			<div className="info-box-content">{children}</div>
		</div>
	)
}

export default InfoBox
