import React, { Fragment, useState } from 'react'
import Button from '../Elements/Button'
import SelectElem from '../../forms/SelectElem'
import CheckboxeElems from './FormElems/CheckboxeElems'
import Checkbox from '../../forms/Checkbox'

const FormElem = () => {
	const handleClick = e => {}

	const handleBtnClick = () => {}
	return (
		<Fragment>
			<h3>The Fieldset:</h3>
			<fieldset>
				<legend>Legend</legend>

				<div className="form-title">The Form:</div>

				<form>
					<div className="form-group">
						<label htmlFor="text_field">Text Field:</label>
						<input type="text" id="text_field" />
						<p className="field-instructions">
							Instructions: Annoy kitten brother with poking the dog smells bad warm up laptop with butt
							lick butt fart rainbows until owner yells pee in litter box.
						</p>
					</div>

					<div className="form-group">
						<label htmlFor="text_field_disabled">Disabled Text Field:</label>
						<input type="text" id="text_field_disabled" disabled value="I'm disabled" />
					</div>

					<div className="form-group">
						<label htmlFor="text_field_readonly">Readonly Text Field:</label>
						<input type="text" id="text_field_readonly" readOnly value="I'm read only" />
					</div>

					<div className="form-group">
						<label htmlFor="text_area">Text Area:</label>
						<textarea id="text_area"></textarea>
					</div>

					<div className="form-group">
						<label htmlFor="text_area_disabled">Disabled Text Area:</label>
						<textarea id="text_area_disabled" disabled defaultValue={"I'm disabled"}></textarea>
					</div>

					<div className="form-group">
						<label htmlFor="text_area_readonly">Readonly Text Area:</label>
						<textarea id="text_area_readonly" readOnly defaultValue={"I'm readonly"}></textarea>
					</div>

					<div className="form-group">
						<label htmlFor="select_element">Select Element:</label>
						<SelectElem
							options={
								<>
									<optgroup label="Option Group 1">
										<option value="1">Option 1</option>
										<option value="2">Option 2</option>
									</optgroup>
									<optgroup label="Option Group 2">
										<option value="1">Option 1</option>
										<option value="2">Option 2</option>
										<option value="3" disabled>
											Disabled Option
										</option>
									</optgroup>
								</>
							}
						/>
					</div>

					<pre>
						<code>
							{`// Import
import SelectElem from '../../forms/SelectElem'

// JSX
<div className="form-group">
	<label htmlFor="select_element">Select Element:</label>
	<SelectElem
		options={
			<>
				<optgroup label="Option Group 1">
					<option value="1">Option 1</option>
					<option value="2">Option 2</option>
				</optgroup>
				<optgroup label="Option Group 2">
					<option value="1">Option 1</option>
					<option value="2">Option 2</option>
					<option value="3" disabled>
						Disabled Option
					</option>
				</optgroup>
			</>
		}
	/>
</div>`}
						</code>
					</pre>

					<div className="form-group">
						<label htmlFor="select_element_disabled">Disabled Select Element:</label>
						<div className="select-styling-wrapper">
							<select id="select_element_disabled" disabled>
								<option value="1">Unselectable Option</option>
								<option value="2">This option should not even be seen</option>
							</select>
						</div>
					</div>

					<div className="form-group">
						Radio Buttons:
						<label>
							<input type="radio" className="radio" name="radio_button" value="radio_1" /> Radio 1
						</label>
						<label>
							<input type="radio" className="radio" name="radio_button" value="radio_2" /> Radio 2
						</label>
						<label>
							<input type="radio" className="radio" name="radio_button" value="radio_3" /> Radio 3
						</label>
						<label>
							<input type="radio" className="radio" name="radio_button" value="radio_4" disabled /> Radio
							Disabled
						</label>
					</div>

					<CheckboxeElems />

					<div className="form-group">
						<label htmlFor="password">Password:</label>
						<input type="password" className="password" id="password" />
					</div>

					<div className="form-group">
						<label htmlFor="file">File Input:</label>
						<input type="file" className="file" id="file" />
					</div>
				</form>
			</fieldset>
			<h3>HTML5-specific Form Elements</h3>
			<fieldset>
				<form>
					<div className="form-group">
						<label htmlFor="email">Email:</label>
						<input type="email" id="email" />
					</div>

					<div className="form-group">
						<label htmlFor="url">URL:</label>
						<input type="url" id="url" />
					</div>

					<div className="form-group">
						<label htmlFor="tel">Telephone:</label>
						<input type="tel" id="tel" />
					</div>

					<div className="form-group">
						<label htmlFor="number">Number:</label>
						<input type="number" id="number" min="0" max="10" step="1" defaultValue="5" />
					</div>

					<div className="form-group">
						<label htmlFor="search">Search:</label>
						<input type="search" id="search" />
					</div>

					<div className="form-group">
						<label htmlFor="date">Date:</label>
						<input type="date" id="date" />
					</div>

					<div className="form-group">
						<label htmlFor="time">Time:</label>
						<input type="time" id="time" />
					</div>

					<div className="form-group">
						<label htmlFor="color">Color:</label>
						<input type="color" id="color" />
					</div>

					<div className="form-group">
						<label htmlFor="datalist">Datalist:</label>
						<input list="browsers" name="browser" type="datalist" id="datalist" />
						<datalist id="browsers">
							<option value="Internet Explorer" />
							<option value="Firefox" />
							<option value="Chrome" />
							<option value="Opera" />
							<option value="Safari" />
						</datalist>
					</div>

					<hr />

					<div className="form-row">
						<div className="form-group">
							<label htmlFor="text_field">Text Field:</label>
							<input type="text" id="text_field" />
							<p className="field-instructions">Form Row: Two Inputs side by side.</p>
						</div>
						<div className="form-group">
							<label htmlFor="text_field">Text Field:</label>
							<input type="text" id="text_field" />
							<p className="field-instructions">Form Row: Two Inputs side by side.</p>
						</div>
					</div>

					<pre>
						<code>
							{`<div className="form-row">
	<div className="form-group">
		<label htmlFor="text_field">Text Field:</label>
		<input type="text" id="text_field" />
		<p className="field-instructions">Form Row: Two Inputs side by side.</p>
	</div>
	<div className="form-group">
		<label htmlFor="text_field">Text Field:</label>
		<input type="text" id="text_field" />
		<p className="field-instructions">Form Row: Two Inputs side by side.</p>
	</div>
</div>`}
						</code>
					</pre>

					<div className="form-group">
						<label htmlFor="range">Range:</label>
						<input type="range" id="range" name="points" min="1" max="10" />
					</div>

					<div className="form-group">
						<label htmlFor="output">Output:</label>
						<output name="result" id="output">
							42
						</output>
					</div>

					<div className="form-group">
						<label htmlFor="progress">Progress:</label>
						<progress id="progress" value="65" max="100"></progress>
					</div>

					<div className="form-group">
						<label htmlFor="meter">Meter:</label>
						<meter id="meter" min="200" max="500" value="350">
							350 degrees
						</meter>
					</div>

					<div className="form-group">
						<label>Type: Button</label>
						<Button variant="success" label={'Button Element'} />
						<label>Type: Input</label>
						<Button isInput variant="success" label={'Submit'} onClick={() => handleBtnClick()} />
						<label>Type: Input</label>
						<Button isInput variant="success" label={'reset'} onClick={() => handleBtnClick()} />
					</div>
				</form>
			</fieldset>
		</Fragment>
	)
}

export default FormElem
