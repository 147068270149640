import React, { useRef, useEffect } from 'react'
import { TimelineMax } from 'gsap/all'

const LoadingPulseBar = ({ isLoading, text }) => {
	// const [points, setPoints] = useState(30)
	// const [pulseSpeed, setPulseSpeed] = useState(0.5)
	// const [interval, setInterval] = useState(0.05)
	// const [delay, setDelay] = useState('-=1.2')

	const points = 30
	const pulseSpeed = 0.5
	const interval = 0.05
	const delay = '-=1.2'

	const pulseBlock = useRef()

	const tl = new TimelineMax({ repeat: -1, yoyo: true })

	useEffect(() => {
		if (isLoading) {
			// prettier-ignore
			tl.staggerTo(pulseBlock.current.children, pulseSpeed, { opacity: 1 }, interval)
			// prettier-ignore
			.staggerTo(pulseBlock.current.children, pulseSpeed, { opacity: 0 }, interval, delay)
		}
	})

	return (
		<div className="loading-bar pulse">
			<div className="text-wrapper">
				<h6>{text}</h6>
			</div>
			<div className="bar" ref={pulseBlock}>
				{Array.from({ length: points }, (_, i) => (
					<div className="pulse-block" key={i}></div>
				))}
			</div>
		</div>
	)
}

export default LoadingPulseBar
