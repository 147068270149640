import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { getTournament } from '../../../actions/tournament'
import Moment from 'react-moment'
import MyMapComponentSingle from '../../Layout/Elements/MapsSingle'
import TournamentSponsors from './TournamentSponsors'
// import InnerStructure from '../InnerStructure'
import Button from '../Elements/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditTournament from '../../forms/tournament/EditTournament'
import { EDIT_TOURNAMENT, EXIT_EDIT_TOURNAMENT } from '../../../actions/types'
import { TimelineMax } from 'gsap/all'
import { withRouter, Redirect } from 'react-router-dom'
import AddToFavorites from '../../Layout/Elements/AddToFavorites'
import SocialShare from '../SocialShare'
import {
	TitlePanel,
	Type,
	Date,
	Location,
	Organizer,
	Contact,
	Details,
	RegistrationDeadline,
	Media,
} from './TournDetailItems'
// import Banner3D from '../Elements/Banner3D'
// import NumberFormat from 'react-number-format';

const TournamentDetail = ({
	isAuthenticated,
	getTournament,
	match,
	tournament,
	tournamentLoading,
	user,
	history,
	tournamentEdit,
	role,
	tournamentLevel,
}) => {
	const dispatch = useDispatch()
	const adminBar = useRef()
	// Arrays to add refs to on .map()
	const sponsorRefs = []
	const [sponsorImages, setSponsorImages] = useState([])

	const [adminBarDisplay, setAdminBarDisplay] = useState(false)
	const [adminBarVisible, setAdminBarVisible] = useState(false)
	const [redirectToHome, setRedirectToHome] = useState(false)
	const [favExists, setFavExists] = useState(false)
	const [isMobile, setIsMobile] = useState(false)

	const mobileWindowBreakpoint = 1200

	const speed = 0.25

	useEffect(() => {
		function detectWindowWidth() {
			const windowWidth = window.innerWidth
			// console.log('windowWidth = ', windowWidth)
			if (windowWidth < mobileWindowBreakpoint) {
				setIsMobile(true)
			} else {
				setIsMobile(false)
			}
		}

		detectWindowWidth()

		function handleResize() {
			detectWindowWidth()
		}

		window.addEventListener('resize', handleResize)
	})

	const openAdminBar = () => {
		const openAdminTL = new TimelineMax({ onComplete: () => setAdminBarVisible(true) })
		const adminBarHeight = adminBar.current.clientHeight
		openAdminTL.to(adminBar.current, { y: -adminBarHeight, ease: 'power2.in', durration: speed })

		openAdminTL.play()
	}

	const closeAdminBar = () => {
		const closeAdminTL = new TimelineMax({ onComplete: () => setAdminBarVisible(false) })
		closeAdminTL.to(adminBar.current, { y: 0, ease: 'power2.out(3)', durration: speed })
		closeAdminTL.play()
	}

	if (adminBarDisplay) {
		openAdminBar()
	} else if (adminBarVisible) {
		closeAdminBar()
	}

	const handleGoBack = e => {
		if (user === null) {
			setRedirectToHome(true)
		} else {
			history.goBack()
		}
	}

	const editTournament = () => {
		if (tournamentEdit) {
			dispatch({ type: EXIT_EDIT_TOURNAMENT })
		} else {
			dispatch({ type: EDIT_TOURNAMENT })
		}
	}

	useEffect(() => {
		getTournament(match.params.id)
	}, [getTournament, match.params.id])

	// Determine if the requested tournament is in the current users 'savedTournaments' (has been added to the users "Watchlist")
	useEffect(() => {
		if (user && tournament) {
			user.savedTournaments.forEach(function(elem) {
				// if the current user has this in thier 'savedTournaments'
				// and the favExists state has not been set -> Set the State to 'true'
				if (elem._id === tournament._id && !favExists) {
					setFavExists(true)
				}
			})
		}
	}, [user, tournament])

	if (redirectToHome) {
		return <Redirect to="/" />
	} else if (tournament && !tournamentLoading) {
		return (
			<div className="tournament-details-view">
				{role === 'admin' && (
					<>
						<div className={`admin-bar ${adminBarDisplay ? 'active' : null}`} ref={adminBar}>
							<div className="admin-bar__container">
								<h4 className="admin-bar__title" onClick={() => setAdminBarDisplay(!adminBarDisplay)}>
									<FontAwesomeIcon icon={{ prefix: 'far', iconName: 'caret-circle-up' }} />
									Admin Bar
								</h4>
								<div className="admin-bar__col">
									<h5>Tournament Details</h5>
								</div>
								<div className="admin-bar__col">
									<dl>
										<dt>Tournament Level:</dt>
										<dd>{tournament.paymentLevel}</dd>
									</dl>
									<dl>
										<dt>Owner Name:</dt>
										<dd>{user.name}</dd>
									</dl>
								</div>
							</div>
						</div>
					</>
				)}
				{!isMobile && (
					<>
						<div className="edge-bar"></div>
						<header className="tournament-header">
							<TitlePanel tournament={tournament} />
							<div className="header-content">
								<div className="tourn-info-container">
									<Type tournament={tournament} />
									<Date tournament={tournament} />
									<Location tournament={tournament} />
									<Organizer tournament={tournament} />
									{(tournament.contact.website ||
										tournament.contact.email ||
										tournament.contact.phone) && <Contact tournament={tournament} />}
								</div>
							</div>
						</header>
					</>
				)}
				<div className="tournament-content-container details-view">
					<div className="view-controls">
						{user && !tournamentEdit && (
							<>
								<AddToFavorites
									tournament={tournament}
									isFav={favExists}
									user={user}
									variant="link"
									className="add-to-watchlist"
								/>
								{/* <Button className="add-to-watchlist" variant="link" label="Add To My Watchlist" /> */}
							</>
						)}
						{!tournamentEdit && (
							<>
								<Button
									className="btn-back"
									variant="link"
									label={'Go Back'}
									onClick={e => handleGoBack(e)}
								/>
							</>
						)}
						{((user && user._id === tournament.owner) || (user && user.role === 'admin')) && (
							<>
								<Button
									variant="link"
									// label={'Edit Tournament'}
									label={tournamentEdit ? 'Exit Edit Mode (discard changes)' : 'Edit Tournament'}
									// className="edit-tournamnet"
									className={tournamentEdit ? 'edit-tournamnet active' : 'edit-tournamnet'}
									onClick={() => editTournament()}
								/>
							</>
						)}
					</div>
					<section className="tournament-content">
						{tournamentEdit && (
							<>
								<EditTournament tournId={match.params.id} tournament={tournament} />
							</>
						)}
						{!tournamentEdit && (
							<>
								{isMobile && (
									<header className="mobile-display tournament-content-header">
										<TitlePanel tournament={tournament} />
										<Type tournament={tournament} />
									</header>
								)}
								<div className="date-loction-container">
									<MyMapComponentSingle tournament={tournament} />

									{!isMobile && tournamentLevel === 'tl_3' && (
										<TournamentSponsors tournament={tournament} tournamentLevel={tournamentLevel} />
									)}
								</div>
								<div className="tournament-details">
									<SocialShare
										shareUrl={'https://findfishingtournaments.com/tournaments/' + tournament._id}
										title={tournament.tourName}
									/>
									{isMobile && (
										<div className="mobile-display">
											<Date tournament={tournament} />
											<Location tournament={tournament} />
											<Organizer tournament={tournament} />
											{(tournament.contact.website ||
												tournament.contact.email ||
												tournament.contact.phone) && <Contact tournament={tournament} />}
										</div>
									)}

									<Details tournament={tournament} />
									<RegistrationDeadline tournament={tournament} />

									{tournamentLevel === 'tl_2' && (
										<TournamentSponsors tournament={tournament} tournamentLevel={tournamentLevel} />
									)}

									{tournament.tournMedia.path !== null && (
										<>
											<Media tournament={tournament} />
										</>
									)}
								</div>
								{isMobile && tournamentLevel === 'tl_3' && (
									<TournamentSponsors
										isMobile={true}
										tournament={tournament}
										tournamentLevel={tournamentLevel}
									/>
								)}
							</>
						)}
					</section>
				</div>
			</div>
		)
	} else {
		return <div className="tournament-details-view">REPLACE WITH LOADING SPINNER</div>
	}
}

TournamentDetail.propTypes = {
	isAuthenticated: PropTypes.bool,
	match: PropTypes.object.isRequired,
	tournament: PropTypes.object,
	tournamentLoading: PropTypes.bool.isRequired,
	user: PropTypes.object,
	tournamentEdit: PropTypes.bool,
	role: PropTypes.string,
	tournamentLevel: PropTypes.string,
}

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
	tournament: state.tournament.tournament,
	tournamentLoading: state.tournament.tournamentLoading,
	role: state.auth.role,
	user: state.auth.user,
	tournamentEdit: state.tournament.tournamentEdit,
	tournamentLevel: state.tournament.tournamentLevel,
})

export default connect(mapStateToProps, { getTournament })(withRouter(TournamentDetail))
