import React from 'react'
import Button from '../../Elements/Button'
import { connect, useDispatch } from 'react-redux'
import { SHOW_LIST_A_TOURNAMENT } from '../../../../actions/types'

const ListATournamentCallout = ({}) => {
	const dispatch = useDispatch()

	return (
		<>
			<div className="callout-container">
				<h3 className="callout-title">List a Tournament</h3>

				<p>
					Attention Tournament Organizers! List your tournament with FindFishingTounaments.com and see your
					event grow!
				</p>

				<Button
					variant="primary"
					isLink
					to="/dashboard"
					outline="true"
					label="List a Tournament"
					outline={true}
					onClick={() => dispatch({ type: SHOW_LIST_A_TOURNAMENT })}
				/>
			</div>
			{/* {showTournamentAlertSignUp && <TournAlertSignUp show={showTournamentAlertSignUp} />} */}
		</>
	)
}

ListATournamentCallout.propTypes = {}

const mapStateToProps = state => ({})

export default connect(mapStateToProps)(ListATournamentCallout)
