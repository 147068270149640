import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '../../Layout/Elements/Button'
import { requestPasswordChange } from '../../../actions/mail'
import InfoBox from '../../Layout/Elements/InfoBox'

const RequestPasswordChangeForm = ({
	requestPasswordChange,
	passwordResetEmailSent,
	passwordResetNoEmail,
	passwordResetEmailError,
}) => {
	const dispatch = useDispatch()
	const [formData, setFormData] = useState({
		email: '',
	})

	const { email } = formData

	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

	const onSubmit = e => {
		e.preventDefault()
		requestPasswordChange(formData)
	}

	return (
		<>
			{!passwordResetEmailSent && (
				<>
					<InfoBox variant="light" size="small" outline="true" noShadow={true}>
						Enter the email address associated with your FindFishingTournaments.com account.
					</InfoBox>

					{passwordResetNoEmail && (
						<InfoBox
							variant="danger"
							size="small"
							outline="true"
							iconName="exclamation-triangle"
							noShadow={true}
						>
							Error: That email is not registered with FindFishingTournaments.com
						</InfoBox>
					)}

					<form className="form" onSubmit={e => onSubmit(e)}>
						<label htmlFor="email">Email:</label>
						<input
							type="email"
							placeholder="Email Address"
							name="email"
							value={email}
							onChange={e => onChange(e)}
						/>
						<Button
							className="spacing-top-2"
							variant="primary"
							outline="true"
							label="Submit"
							icon="edit"
							iconType="fad"
							size="small"
						/>
					</form>
				</>
			)}
			{passwordResetEmailSent && (
				<>
					<InfoBox variant="success" size="large" outline="true" iconName="smile-beam" noShadow={true}>
						Success: An email has been sent to your inbox with instructions on how to update your password.
					</InfoBox>

					<InfoBox isNote variant="info" iconName="asterisk">
						For your security the password reset email will expire in one hour.
					</InfoBox>
				</>
			)}
		</>
	)
}

RequestPasswordChangeForm.propTypes = {
	requestPasswordChange: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
	passwordResetEmailSent: state.mail.passwordResetEmailSent,
	passwordResetNoEmail: state.mail.passwordResetNoEmail,
	passwordResetEmailError: state.mail.passwordResetEmailError,
})

export default connect(mapStateToProps, { requestPasswordChange })(RequestPasswordChangeForm)
