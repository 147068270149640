import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Stripes from '../Layout/Elements/BG/Stripes'
// import PropTypes from 'prop-types'

const OuterStructure = props => {
	return (
		<>
			<Stripes />
			<Header />
			<main className="outer-content">{props.children}</main>
			<Footer />
		</>
	)
}

// Structure.propTypes = {}

export default OuterStructure
