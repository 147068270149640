import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../Layout/Elements/Button'
import { REGISTRATION_TYPE_SELECTED } from '../../actions/types'

const ChooseAccountType = ({ dispatch, registrationType }) => {
	const tournOwnerIcon = useRef()
	const standardIcon = useRef()

	// const [iconHeight, setIconHeight] = useState(0)

	const accountSelect = selection => {
		dispatch({
			type: REGISTRATION_TYPE_SELECTED,
			payload: selection,
		})
	}

	// useEffect(() => {
	// 	setIconHeight(standardIcon.current.offsetHeight)
	// })

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Features Lists
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const standardFeaturesList = [
		'Search for fishing tournaments.',
		'Save fishing tournaments to your watchlist.',
		'Register for notifications of new tournaments in your area.',
		'Get dates, times, and descriptions of tournaments.',
	]
	const tournFeaturesList = [
		'Create a tournament for fisherman to find.',
		'Add dates, times and location of your tournament.',
		'Add descriptions and helpful info for tournament goers.',
		'Add contact information and media. (Certain Plans)',
	]

	// Arrays to add refs to on .map()
	const standardRefs = []
	const tournRefs = []

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Loop through rendered Features Lists -> Compare and set Heights
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		let standardRefsLength = standardRefs.length
		let tournRefsLength = tournRefs.length
		let longestList
		let shortestList

		if (standardRefsLength >= tournRefsLength) {
			longestList = standardRefs
			shortestList = tournRefs
		} else {
			longestList = tournRefs
			shortestList = standardRefs
		}

		shortestList.forEach((element, index) => {
			let compareElem = longestList[index]
			let elemheight = element.offsetHeight
			let compareHeight = compareElem.offsetHeight
			let adjustedHeight

			if (elemheight > compareHeight) {
				compareElem.style.height = elemheight + 'px'
			} else if (compareHeight > elemheight) {
				element.style.height = compareHeight + 'px'
			} else {
				adjustedHeight = elemheight
			}
		})
	}, [standardRefs, tournRefs])

	return (
		<div className="choose-acct-type">
			<h2 className="center-text">Choose Account Type</h2>

			<div className="account-types-container">
				<p className="description standard-desc ">Are you a fisherman looking for tournaments in your area?</p>
				<div className="acc-type-wrapper standard-account-container">
					<div className="account-type">
						{/* <div className="header-accent"></div> */}
						<div className="content-wrapper">
							<div className="account-type-header">
								<div className="header-content">
									<h5 className="acc-type-title">Standard User</h5>
									<div className="icon-container" ref={standardIcon}>
										<FontAwesomeIcon icon={{ prefix: 'fal', iconName: 'user' }} />
									</div>
									<div className="price-container">
										<div className="price">FREE</div>
									</div>
								</div>
							</div>

							<ul className="features-list">
								{standardFeaturesList.map((item, index) => {
									return (
										<li className="feature" key={index} ref={node => (standardRefs[index] = node)}>
											<div className="item-wrapper">
												<FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'fish' }} />
												{item}
											</div>
										</li>
									)
								})}
							</ul>
						</div>
						<Button
							isLink
							to="/register"
							variant="success"
							label="Sign Up"
							onClick={() => accountSelect('standard')}
						/>
					</div>
				</div>
				<p className="description tourn-desc">
					Are you a tournament owner / organizer? Sign up here to list your tournament.
				</p>
				<div className="acc-type-wrapper tournament-account-container">
					<div className="account-type">
						{/* <div className="header-accent"></div> */}
						<div className="content-wrapper">
							<div className="account-type-header">
								<div className="header-content">
									<h5 className="acc-type-title">Tournament Owner</h5>
									<div className="icon-container" ref={tournOwnerIcon}>
										<FontAwesomeIcon icon={{ prefix: 'fal', iconName: 'trophy' }} />
									</div>
									<div className="price-container">
										<div className="price">FREE ~ $9.99</div>
									</div>
								</div>
							</div>

							<ul className="features-list">
								{tournFeaturesList.map((item, index) => {
									return (
										<li className="feature" key={index} ref={node => (tournRefs[index] = node)}>
											<div className="item-wrapper">
												<FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'fish' }} />
												{item}
											</div>
										</li>
									)
								})}
							</ul>
						</div>
						<Button
							isLink
							to="/register"
							variant="success"
							label="Sign Up"
							onClick={() => accountSelect('tournament')}
						/>
					</div>
				</div>
				<p className="footnote">
					<span className="asterisk">*</span>Available with certian plans.
				</p>
			</div>
		</div>
	)
}

ChooseAccountType.propTypes = {
	registrationType: PropTypes.string,
}

const mapStateToProps = state => ({
	registrationType: state.auth.registrationType,
})

export default connect(mapStateToProps)(ChooseAccountType)
