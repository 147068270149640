import React from 'react'

const Stripes = () => {
	return (
		<div className="stripes">
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>
	)
}

export default Stripes
