import React, { useEffect } from 'react'
// import { Redirect } from 'react-router-dom'
// import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'
import Header from '../Header'
import Footer from '../Footer'
import Hero from './Hero'
import TournSearchResults from '../Tournament/TournSearchResults'
// import TournamentSearch from '../../forms/tournament/TournamentSearch'
import InnerStructure from '../InnerStructure'
// import Tournaments from '../../Layout/Tournament/Tournaments'
import Button from '../Elements/Button'
import GetTournAlertsCallout from '../Elements/Callouts/GetTournAlertsCallout'
import AdvertiseCallout from '../Elements/Callouts/AdvertiseCallout'
import ListATournamentCallout from '../Elements/Callouts/ListATournamentCallout'
import Callout from '../Elements/Callouts/Callout'

const Landing = () => {
	// if (isAuthenticated) {
	// 	return <Redirect to="/dashboard" />
	// }

	return (
		<>
			<div className="landing">
				<Header />
				<Hero />
				<div className="tournament-search-wrapper">
					{/* <TournamentSearch /> */}
					<div className="tournament-search-display">
						<TournSearchResults displayAds={true} />
					</div>
				</div>
				<InnerStructure className="landing-callouts-container">
					<div className="landing-callouts">
						<GetTournAlertsCallout />
						<ListATournamentCallout />
						<AdvertiseCallout />
					</div>
				</InnerStructure>
			</div>
			<Footer />
		</>
	)
}

// Landing.propTypes = {
// 	isAuthenticated: PropTypes.bool,
// }

const mapStateToProps = state => ({
	// isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps)(Landing)
