import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Checkbox = ({ label, name, value, className, onChange, isDisabled }) => {
	const checkbox = useRef(null)
	const [isChecked, setIsChecked] = useState(false)

	useEffect(() => {
		checkbox.current.checked ? setIsChecked(true) : setIsChecked(false)
	})
	return (
		<div
			className={`checkbox-container${className ? ' ' + className : ''}${isChecked ? ' checked' : ''}${
				isDisabled ? ' disabled' : ''
			}`}
		>
			<label className="checkbox-label">
				<input
					type="checkbox"
					ref={checkbox}
					value={value}
					className="hidden-checkbox"
					name={name}
					disabled={isDisabled}
					onChange={onChange}
				/>
				<div className="styled-checkbox" checked={isChecked ? 'checked' : ''}>
					<FontAwesomeIcon icon={{ prefix: 'fad', iconName: isChecked ? 'check-square' : 'square'}} />
				</div>
				<span className="label-text">{label}</span>
			</label>
		</div>
	)
}

export default Checkbox
