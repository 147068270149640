import React, { useState, useEffect, useRef } from 'react'
// import { imageToS3 } from '../../../actions/tournament'
// import PropTypes from 'prop-types'
import Button from '../../Layout/Elements/Button'
import { connect } from 'react-redux'
import axios from 'axios'
import { TweenMax } from 'gsap/all'
import { Document, Page } from 'react-pdf/dist/entry.webpack'
import Spinner from '../../../components/effects/loading/Spinner'
import LoadingPulseBar from '../../effects/loading/LoadingPulseBar'
// import axios from 'axios'

const TestArea = () => {
	// const [s3signed, setS3signed] = useState({
	// 	success: false,
	// 	url: '',
	// })
	// LOGO State
	const [logo, setLogo] = useState()
	const [logoName, setLogoName] = useState('Choose File')
	const [logoUrl, setLogoUrl] = useState()
	const [logoUploadSuccess, setLogoUploadSuccess] = useState(false)
	// eslint-disable-next-line
	const [logoUploadError, setLogoUploadError] = useState(false)
	// eslint-disable-next-line
	const [logoSigningError, setLogoSigningError] = useState(false)
	const [logoLocalUploadURL, setLogoLocalUploadURL] = useState()
	const [logoLoading, setLogoLoading] = useState(false)

	// Tournament Media State
	const [tournMedia, setTournMedia] = useState()
	const [tournMediaName, settournMediaName] = useState('Choose File')
	const [tournMediaUrl, setTournMediaUrl] = useState()
	const [tournMediaUploadSuccess, setTournMediaUploadSuccess] = useState(false)
	// eslint-disable-next-line
	const [tournMediaUploadError, setTournMediaUploadError] = useState(false)
	// eslint-disable-next-line
	const [tournMediaSigningError, setTournMediaSigningError] = useState(false)
	const [tournMediaLocalUploadURL, setTournMediaLocalUploadURL] = useState()
	const [tournMediaLoading, setTournMediaLoading] = useState(false)

	const logoImg = useRef()
	const tournMediaImg = useRef()

	const [formData, setFormData] = useState({
		tourName: 'Carls Tournament',
	})

	// eslint-disable-next-line
	const [pageNumber, setPageNumber] = useState(1)
	// eslint-disable-next-line
	const [numPages, setNumPages] = useState()

	const onDocumentLoad = ({ pages }) => {
		setNumPages(pages)
	}

	const { tourName } = formData

	// File upload to aws S3
	const handleUpload = (item, uploadItem) => {
		let file = item
		// Split the filename to get the name and type
		let fileParts = file.name.split('.')
		let fileName = fileParts[0]
		let fileType = fileParts[1]

		// console.log('file = ', file)
		// console.log('fileParts = ', fileParts)
		// console.log('fileName = ', fileName)
		// console.log('fileType = ', fileType)

		axios
			.post('http://localhost:5000/api/image-upload', {
				fileName: file.name,
				fileType: fileType,
			})
			.then(response => {
				const returnData = response.data.data.returnData
				const signedRequest = returnData.signedRequest
				const url = returnData.url
				// console.log('returnData = ', returnData)
				// console.log('signedRequest = ', signedRequest)
				// console.log('url = ', url)
				if (uploadItem === 'logo') {
					setLogoUrl(url)
				} else if (uploadItem === 'tournMedia') {
					setTournMediaUrl(url)
				}

				// Put the fileType in the headers for the upload
				const options = {
					headers: {
						'Content-Type': fileType,
					},
				}
				axios
					.put(signedRequest, file, options)
					.then(result => {
						// console.log(result)

						// console.log('Response from s3 - Upload Successful')
						if (uploadItem === 'logo') {
							setLogoUploadSuccess(true)
						} else if (uploadItem === 'tournMedia') {
							setTournMediaUploadSuccess(true)
						}
					})
					.catch(error => {
						if (uploadItem === 'logo') {
							setLogoUploadError(true)
						} else if (uploadItem === 'tournMedia') {
							setTournMediaUploadError(true)
						}
						// console.log(error)

						console.error('ERROR ' + JSON.stringify(error))
					})
			})
			.catch(error => {
				if (uploadItem === 'logo') {
					setLogoSigningError(true)
				} else if (uploadItem === 'tournMedia') {
					setTournMediaSigningError(true)
				}
				console.error(JSON.stringify(error))
			})
	}

	const onSubmit = e => {
		e.preventDefault()
		// console.log(formData)

		const data = new FormData()

		for (var key in formData) {
			data.append(key, formData[key])
		}

		if (logo) {
		}

		if (tournMedia) {
		}
	}

	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

	// ---------------------------------------------------------------------------------------------------------
	// Logo Upload Events
	// ---------------------------------------------------------------------------------------------------------
	// When User Chooses File - Triggers setLogoName -> Changes to file name
	const onLogoUploadChange = e => {
		setLogo(e.target.files[0])
		setLogoName(e.target.files[0].name)
		setLogoLocalUploadURL(URL.createObjectURL(e.target.files[0]))
		setLogoLoading(true)
	}

	// Once User Chooses File and Triggers setLogoName -> Initiate Upload to S3
	useEffect(() => {
		if (logoName !== 'Choose File') {
			handleUpload(logo, 'logo')
		}
	}, [logoName])

	// When file is uploaded to S3 successfully -> animate in
	useEffect(() => {
		if (logoUploadSuccess) {
			TweenMax.to(logoImg.current, 0.5, { x: 0 })
			TweenMax.to(logoImg.current.children[1], 0.5, { delay: 0.3, x: 0 })
			setLogoLoading(false)
		}
	}, [logoUploadSuccess])

	// ---------------------------------------------------------------------------------------------------------
	// Media Upload Events
	// ---------------------------------------------------------------------------------------------------------
	// When User Chooses File - Triggers settournMediaName -> Changes to file name
	const onMediaUploadChange = e => {
		setTournMedia(e.target.files[0])
		settournMediaName(e.target.files[0].name)
		setTournMediaLocalUploadURL(URL.createObjectURL(e.target.files[0]))
		setTournMediaLoading(true)
	}

	// Once User Chooses File and Triggers settournMediaName -> Initiate Upload to S3
	useEffect(() => {
		if (tournMediaName !== 'Choose File') {
			handleUpload(tournMedia, 'tournMedia')
		}
	}, [tournMediaName])

	// When file is uploaded to S3 successfully -> animate in
	useEffect(() => {
		if (tournMediaUploadSuccess) {
			TweenMax.to(tournMediaImg.current, 0.5, { x: 0 })
			TweenMax.to(tournMediaImg.current.children[1], 0.5, { delay: 0.3, x: 0 })
			setTournMediaLoading(false)
		}
	}, [tournMediaUploadSuccess])

	return (
		<div>
			<form className="form add-tourn" encType="multipart/form-data" onSubmit={e => onSubmit(e)}>
				<div className="form-group">
					<label htmlFor="tourName">Tournament Name</label>
					<input
						type="text"
						placeholder="Tournament Name"
						name="tourName"
						value={tourName}
						onChange={e => onChange(e)}
					/>
				</div>
				<div className="form-group media-group">
					<div className="media-input">
						<label htmlFor="logo">Upload your Tournament Logo</label>
						<label className="file-input-label">{logoName}</label>
						<input type="file" name="logo" className="file-input" onChange={onLogoUploadChange} />
						{logoLoading && (
							<>
								<LoadingPulseBar isLoading={true} text="Loading" />
							</>
						)}
					</div>
					<div className="image-display" ref={logoImg}>
						{logoUrl && (
							<>
								<img
									src={logoLocalUploadURL}
									alt={`tournament logo titled ${logoName}`}
									className="img-responsive"
								/>
								<div className="image-caption">{logoName}</div>
							</>
						)}
					</div>
				</div>

				<div className="form-group media-group">
					<div className="media-input">
						<label htmlFor="media">Tournament Media</label>
						<label htmlFor="media" className="file-input-label">
							{tournMediaName}
						</label>
						<input type="file" name="media" className="file-input" onChange={onMediaUploadChange} />
						{tournMediaLoading && (
							<>
								<LoadingPulseBar isLoading={true} text="Loading" />
							</>
						)}
					</div>
					<div className="image-display" ref={tournMediaImg}>
						{tournMediaUrl && (
							<>
								{/* {console.log(tournMediaUrl)} */}
								<Document file={tournMediaLocalUploadURL} onLoadSuccess={onDocumentLoad}>
									<Page pageNumber={pageNumber} tournamentLoading={<Spinner />} width={300} />
								</Document>
								<div className="image-caption">{tournMediaName}</div>
							</>
						)}
					</div>
				</div>

				<div className="form-group">
					<div className="form-group submit-btn">
						<Button variant="primary" size={'large'} label={'Submit'} />
					</div>
				</div>
			</form>
		</div>
	)
}

TestArea.propTypes = {}

const mapStateToProps = state => ({})

export default connect(mapStateToProps)(TestArea)
