import React from 'react'
import StyleButton from './StyleButton'

const InlineStyleControls = ({ editorState, onToggle }) => {
	const inlineStyles = [
		{ label: 'Bold', style: 'BOLD' },
		{ label: 'Italic', style: 'ITALIC' },
		{ label: 'Underline', style: 'UNDERLINE' },
	]
	const currentStyle = editorState.getCurrentInlineStyle()
	return (
		<div className="RichEditor-controls">
			{inlineStyles.map(type => (
				<StyleButton
					key={type.label}
					active={currentStyle.has(type.style)}
					label={type.label}
					onToggle={onToggle}
					style={type.style}
				/>
			))}
		</div>
	)
}

export default InlineStyleControls
