import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import PropTypes from 'prop-types'
// import { EditorState, convertFromRaw } from 'draft-js'
import { addTournament, getTournament } from '../../../actions/tournament'
import { getTournamentTypes } from '../../../actions/admin'
import { connect, useDispatch } from 'react-redux'
import Button from '../../Layout/Elements/Button'
import DatePicker from 'react-datepicker'
import SelectElem from '../../forms/SelectElem'
import { TweenMax } from 'gsap/all'
import { Document, Page } from 'react-pdf/dist/entry.webpack'
import LoadingPulseBar from '../../effects/loading/LoadingPulseBar'
import Spinner from '../../../components/effects/loading/Spinner'
import { TOURNAMENT_UPDATE_LEVEL, EXIT_EDIT_TOURNAMENT } from '../../../actions/types'
import NumberFormat from 'react-number-format'
import Sponsors from './Sponsors'
import { handleS3Token, handleS3Upload } from '../../../actions/fileUpload'
import InfoBox from '../../Layout/Elements/InfoBox'
// import FileUpload from '../../files/FileUpload'
import TournamentDescription from './TournamentDescription'

// import Editor from 'draft-js-plugins-editor'

import 'react-datepicker/dist/react-datepicker.css'

// const tournOptions = require('./tournOptions.json')
const usStateData = require('./us_states.json')

const CreateTournament = ({
	getTournamentTypes,
	addTournament,
	tournamentTypes,
	paymentLevel,
	tournamentEdit,
	tournId,
	editTournamentData,
	auth,
	editorState,
	setEditorState,
}) => {
	const dispatch = useDispatch()
	const logoImg = useRef()
	const tournMediaImg = useRef()

	// Settings for Rich Text Editor -> Description Field -> using draft.js
	// const [editorState, setEditorState] = useState(EditorState.createEmpty())

	useEffect(() => {
		setFormData({ ...formData, description: editorState.getCurrentContent() })
	}, [editorState])

	// Settings for PDF viewer ------------------------------
	// eslint-disable-next-line
	const [pageNumber, setPageNumber] = useState(1)
	// eslint-disable-next-line
	const [numPages, setNumPages] = useState()
	const onDocumentLoad = ({ pages }) => {
		setNumPages(pages)
	}
	// END Settings for PDF viewer

	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// Get Tourn Type Data from DB
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	useEffect(() => {
		getTournamentTypes()
	}, [getTournamentTypes])
	// END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
	// END Get Tourn Type Data from DB
	// END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// Settin's for Form Data
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	const [formData, setFormData] = useState({
		edit: false,
		tourName: '',
		paymentLevel: '',
		category: '',
		description: '',
		startDate: null,
		endDate: null,
		address: '',
		city: '',
		state: '',
		zip: '',
		lat: '',
		lng: '',
		//
		organizer: '',
		website: '',
		email: '',
		phone: '',
		// sponsors is an empty array for adding multiple sponsor objects
		sponsors: [],
		// media: '',
		approved: '',
		logoName: '',
		logoPath: '',
		tournMediaName: '',
		tournMediaPath: '',
		registrationDeadline: null,
	})

	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// Set Existing Data for Edit State
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	useEffect(() => {
		// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
		// For the edit tournament state this 'CreateTournament' module
		// is being called through the 'EditTournament' module './EditTournament.js'
		// 'EditTournament' is used to test if data exists and set the state accordingly
		// 'EditTournament' passes 'editTournamentData' prop that contains the form data
		// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
		if (tournamentEdit) {
			setFormData({
				edit: true,
				tourName: editTournamentData.tourName,
				paymentLevel: editTournamentData.paymentLevel,
				category: editTournamentData.category,
				description: editTournamentData.description,
				startDate: new Date(editTournamentData.endDate),
				endDate: new Date(editTournamentData.startDate),
				address: editTournamentData.address,
				city: editTournamentData.city,
				state: editTournamentData.state,
				zip: editTournamentData.zip,
				lat: editTournamentData.lat,
				lng: editTournamentData.lng,
				organizer: editTournamentData.organizer,
				website: editTournamentData.website,
				email: editTournamentData.email,
				phone: editTournamentData.phone,
				sponsors: editTournamentData.sponsors,
				logoName: editTournamentData.logoName,
				logoPath: editTournamentData.logoPath,
				tournMediaName: editTournamentData.tournMediaName,
				tournMediaPath: editTournamentData.tournMediaPath,
				registrationDeadline: new Date(editTournamentData.registrationDeadline),
			})
		}
	}, [editTournamentData])

	// prettier-ignore
	const { 
		tourName, category, description, startDate, endDate, address, city, state, zip, organizer, website, email, phone, sponsors, logoPath, tournMediaPath, registrationDeadline
	} = formData

	const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })
	const onSelectElemChange = (e) => {
		if (e.target.value !== 'invalid') {
			setFormData({ ...formData, [e.target.name]: e.target.value })
		}
	}

	// useEffect(() => {
	// 	console.log('formData = ', formData)
	// }, [formData])

	// END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
	// END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
	// END Settins for Form Data
	// END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
	// END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

	// S3 upload States
	const [s3Upload, setS3Upload] = useState({
		item: '',
		fieldName: '',
		fileType: '',
		itemName: 'Choose File',
		itemUrl: '',
		localUploadUrl: '',
		itemLoading: false,
	})

	// Logo upload States
	const [logo, setLogo] = useState({
		name: 'Choose File',
		url: '',
		uploadSuccess: false,
		uploadError: false,
		signingError: false,
		localUploadURL: '',
		loading: false,
	})

	// Tournament Media upload States
	const [media, setMedia] = useState({
		name: '',
		url: '',
		uploadSuccess: false,
		uploadError: false,
		signingError: false,
		localUploadURL: '',
		loading: false,
	})

	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// Upload To AWS S3 Bucket
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// File Input Change handler
	const onUploadChange = (e) => {
		// console.log(e.target.files[0])
		// Polpulate useState Handler for S3 Upload
		setS3Upload({
			...s3Upload,
			item: e.target.files[0],
			fieldName: e.target.name,
			fileType: e.target.files[0].type,
			itemName: e.target.files[0].name,
			localUploadUrl: URL.createObjectURL(e.target.files[0]),
			itemLoading: true,
		})
	}

	const uploadToS3Bucket = () => {
		if (s3Upload.itemName !== 'Choose File') {
			// console.log('uploadItem = ', s3Upload.item)

			// set loading to true for Uploading Item
			switch (s3Upload.fieldName) {
				case 'logo':
					setLogo({ ...logo, loading: true })
					break
				case 'media':
					setMedia({ ...media, loading: true })
					break
				default:
				// console.log('Uploaded Item Didi Not Match')
			}

			// Get Upload Security Token From AWS
			handleS3Token(s3Upload.item, s3Upload.fileType).then((tokenRes) => {
				// console.log('tokenRes', tokenRes)
				const signedRequest = tokenRes.data.data.returnData.signedRequest
				const url = tokenRes.data.data.returnData.url

				// Once Token is recieved send file with token to AWS
				handleS3Upload(signedRequest, s3Upload.item, s3Upload.fileType).then((fileUploadRes) => {
					// console.log('fileUploadRes', fileUploadRes)

					// After Upload is Complete Set States for Uploaded Type
					switch (s3Upload.fieldName) {
						case 'logo':
							setLogo({
								...logo,
								name: s3Upload.itemName,
								url: url,
								uploadSuccess: true,
								localUploadURL: s3Upload.localUploadUrl,
								loading: false,
							})
							break
						case 'media':
							setMedia({
								...media,
								name: s3Upload.itemName,
								url: url,
								uploadSuccess: true,
								localUploadURL: s3Upload.localUploadUrl,
								loading: false,
							})
							break
						default:
						// console.log('Uploaded Item Did Not Match')
					}

					setS3Upload({ ...s3Upload, itemLoading: false })
				})
			})
		}
	}

	// on s3Upload state change
	useEffect(() => {
		if (s3Upload.itemLoading) {
			uploadToS3Bucket()
		}
	}, [s3Upload])

	useEffect(() => {
		if (logo.uploadSuccess) {
			setFormData({ ...formData, logoName: logo.name, logoPath: logo.url })
		}
	}, [logo])

	useEffect(() => {
		if (media.uploadSuccess) {
			setFormData({ ...formData, tournMediaName: media.name, tournMediaPath: media.url })
		}
	}, [media])

	// END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
	// END Upload To AWS S3 Bucket
	// END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
	// END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
	// END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// Tournament Level State
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

	const [tournLevel, setTournLevel] = useState(paymentLevel)

	const setPaymentLevel = (e) => {
		setTournLevel(e.target.value)
		setFormData({ ...formData, paymentLevel: e.target.value })
	}

	useEffect(() => {
		dispatch({ type: TOURNAMENT_UPDATE_LEVEL, payload: tournLevel })
	}, [tournLevel])

	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// Date Picker Events
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	const handleStartDateChange = (date) => {
		setFormData({ ...formData, startDate: date })
	}

	const handleEndDateChange = (date) => {
		setFormData({ ...formData, endDate: date })
	}

	const handleRegistrationDeadlineChange = (date) => {
		setFormData({ ...formData, registrationDeadline: date })
	}
	// END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<------
	// END Date Picker Events
	// END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<------

	const sponsorsCallback = (sponsorsData) => {
		// get data from the Sponsors Child Element as an object and push to formData: sponsors array
		// the Sponsors Child Element object should be {sponsorName: '', sponsorUrl: '', sponsorLogoPath: ''}
		// console.log('sponsorsData = ', sponsorsData)

		setFormData({ ...formData, sponsors: sponsorsData })
	}

	const onSubmit = (e) => {
		e.preventDefault()
		formData.paymentLevel = paymentLevel
		if (tournamentEdit) {
			formData.isEdit = true
			formData.tournId = tournId
			addTournament(formData, true)
		} else {
			formData.isEdit = false
			addTournament(formData, false)
		}
	}

	return (
		<>
			<form
				className={`form ${tournamentEdit ? 'edit-tourn' : 'add-tourn'}`}
				encType="multipart/form-data"
				onSubmit={(e) => onSubmit(e)}
			>
				{/* TODO: change encType back to application/json */}
				{auth.isAdmin && (
					<>
						{!tournamentEdit && (
							<>
								<h2>Admin - Add Tournament</h2>
							</>
						)}
						{tournamentEdit && (
							<>
								<h2>Admin - Edit Tournament</h2>
							</>
						)}

						<div className="form-group">
							<label htmlFor="tourName">
								Set Tournament Level (Payment Level -> Visible Only to Admins)
							</label>
							<SelectElem
								name="paymentLevel"
								value={tournLevel}
								onChange={(e) => setPaymentLevel(e)}
								firstLineOption={
									<option disabled value="Select Tournament Level">
										Select Tournament Level
									</option>
								}
								options={
									<>
										<option value="free">Free</option>
										{/* <option value="tl_2">tl_2</option> */}
										<option value="tl_3">tl_3</option>
									</>
								}
							/>
						</div>
					</>
				)}
				{tournamentEdit && (
					<>
						<h2>Admin - Edit Tournament Mode</h2>
					</>
				)}
				<fieldset>
					<h4 className="form-section-title">Tournament Info</h4>
					<div className="form-group">
						<label htmlFor="tourName">Tournament Name</label>
						<input
							type="text"
							placeholder="Tournament Name"
							name="tourName"
							value={tourName ? tourName : ''}
							onChange={(e) => onChange(e)}
						/>
					</div>
					<div className="form-group">
						<label htmlFor="category">Tournament Type</label>
						<SelectElem
							name="category"
							value={category}
							onChange={(e) => onSelectElemChange(e)}
							firstLineOption={<option value="invalid">Select Tournament Type</option>}
							options={
								<>
									{/* <option value="Select Tournament Type">Select Tournament Type</option> */}
									{tournamentTypes.map((type, index) => {
										return (
											<option key={index} value={type.tournTypeName}>
												{type.tournTypeName}
											</option>
										)
									})}
								</>
							}
						/>
					</div>

					<div className="form-group">
						<label htmlFor="text_field">Tournament Description</label>

						<TournamentDescription
							name="description"
							editorState={editorState}
							setEditorState={setEditorState}
							onChange={setEditorState}
							value={editorState}
						/>

						{/* <textarea
							placeholder="Description"
							name="description"
							value={description}
							onChange={e => onChange(e)}
						/> */}
					</div>
					{/* Date------------------------------------------------------- */}
					{/* ----------------------------------------------------------- */}
					<div className="form-row">
						<div className="form-group">
							<label>Tournament Start Date and Time</label>

							<DatePicker
								placeholderText="Click to select a date and time."
								selected={startDate}
								onChange={handleStartDateChange}
								showTimeSelect
								// withPortal
							/>
						</div>
						<div className="form-group">
							<label htmlFor="text_field">Tournament End Date</label>
							<DatePicker
								placeholderText="Click to select a date."
								selected={endDate}
								onChange={handleEndDateChange}
							/>
						</div>
					</div>
					<div className="form-group">
						<label htmlFor="text_field">Tournament Organizer</label>
						<input
							type="text"
							placeholder="Organizer"
							name="organizer"
							value={organizer ? organizer : ''}
							onChange={(e) => onChange(e)}
						/>
					</div>
				</fieldset>

				<fieldset>
					<h4 className="form-section-title">Registration Deadline</h4>
					<div className="form-group">
						<label>Registration Deadline Date and Time</label>

						<DatePicker
							placeholderText="Click to select a date and time."
							selected={registrationDeadline}
							onChange={handleRegistrationDeadlineChange}
							showTimeSelect
							// withPortal
						/>
					</div>
				</fieldset>

				<fieldset>
					<h4 className="form-section-title">Tournament Location</h4>
					{/* Address------------------------------------------------------- */}
					{/* ----------------------------------------------------------- */}
					<div className="form-group">
						<label htmlFor="text_field">Street Address</label>
						<input
							type="text"
							placeholder="Street Address"
							name="address"
							value={address}
							onChange={(e) => onChange(e)}
						/>
					</div>
					<div className="form-group">
						<label htmlFor="text_field">City</label>
						<input type="text" placeholder="City" name="city" value={city} onChange={(e) => onChange(e)} />
					</div>
					<div className="form-group">
						<label htmlFor="text_field">State</label>

						<SelectElem
							name="state"
							value={state}
							onChange={(e) => onSelectElemChange(e)}
							firstLineOption={<option value="invalid">Select Tournament State</option>}
							options={usStateData.map((states, index) => {
								return (
									<option key={index} value={states.abbreviation}>
										{states.name}
									</option>
								)
							})}
						/>

						{/* <input
						type="text"
						placeholder="State"
						name="state"
						value={state}
						onChange={e => onChange(e)}
					/> */}
					</div>
					<div className="form-group">
						<label htmlFor="text_field">Zip</label>
						<input type="text" placeholder="Zip" name="zip" value={zip} onChange={(e) => onChange(e)} />
					</div>
				</fieldset>

				{tournLevel !== 'free' && (
					<>
						<fieldset>
							<h4 className="form-section-title">Tournament Contact Info</h4>

							<div className="form-group">
								<label htmlFor="text_field">Tournament Website</label>
								<input
									type="text"
									placeholder="Website"
									name="website"
									value={website}
									onChange={(e) => onChange(e)}
								/>
								<InfoBox isNote variant="secondary" iconName="asterisk">
									Make sure to include the full URL by using "http://" or "https://" before your
									website address.
									<br />
									<br />
									<strong>Example:</strong> https://www.mysite.com
								</InfoBox>
							</div>
							<div className="form-group">
								<label htmlFor="text_field">Tournament Email</label>
								<input
									type="text"
									placeholder="Email"
									name="email"
									value={email}
									onChange={(e) => onChange(e)}
								/>
							</div>
							<div className="form-group">
								<label htmlFor="text_field">Tournament Phone</label>
								<NumberFormat
									format="+1 (###) ###-####"
									mask="_"
									type="text"
									placeholder="Phone"
									name="phone"
									value={phone}
									onChange={(e) => onChange(e)}
								/>
							</div>
						</fieldset>

						<Sponsors
							parentCallback={sponsorsCallback}
							existingSponsors={sponsors}
							tournLevel={paymentLevel}
							editMode={tournamentEdit}
						/>

						<fieldset>
							<h4 className="form-section-title">
								{tournamentEdit && logoPath ? 'Replace' : 'Add'} Tournament Logo
							</h4>
							<div className="form-group media-group tournament-logo">
								<div className="media-input">
									<label htmlFor="logo">Upload your Tournament Logo</label>
									<label className="file-input-label">{logo.name}</label>
									<input
										type="file"
										name="logo"
										className="file-input"
										onChange={(e) => onUploadChange(e)}
									/>
									{logo.loading && (
										<>
											<LoadingPulseBar isLoading={true} text="Loading" />
										</>
									)}
								</div>
								<div className="image-display" ref={logoImg}>
									{logo.url && (
										<>
											<img
												src={logo.localUploadURL}
												alt={`tournament logo titled ${logo.name}`}
												className="img-responsive"
											/>
											<div className="image-caption">{logo.name}</div>
										</>
									)}
								</div>
							</div>
						</fieldset>
						{paymentLevel !== 'tl_2' && (
							<fieldset>
								<h4 className="form-section-title">
									{tournamentEdit && tournMediaPath ? 'Replace' : 'Add'} Tournament Media (PDF)
								</h4>
								<div className="form-group media-group tournament-media">
									<div className="media-input">
										<label htmlFor="media">Tournament Media</label>
										<label htmlFor="media" className="file-input-label">
											{media.name}
										</label>
										<input
											type="file"
											name="media"
											className="file-input"
											onChange={media.loading ? null : (e) => onUploadChange(e)}
										/>
										{media.loading && (
											<>
												<LoadingPulseBar isLoading={true} text="Loading" />
											</>
										)}
									</div>
									<div className="image-display" ref={tournMediaImg}>
										{media.url && (
											<>
												<Document file={media.localUploadURL} onLoadSuccess={onDocumentLoad}>
													<Page
														pageNumber={pageNumber}
														tournamentLoading={<Spinner />}
														width={300}
													/>
												</Document>
												<div className="image-caption">{media.name}</div>
											</>
										)}
									</div>
								</div>
							</fieldset>
						)}
					</>
				)}
				<div className="form-group">
					<div className="form-group submit-btn">
						{!tournamentEdit && (
							<>
								<Button variant="primary" size={'large'} label={'Submit'} />
							</>
						)}

						{tournamentEdit && (
							<>
								<Button variant="primary" size={'large'} label={'Save Changes'} />
							</>
						)}
					</div>
				</div>
			</form>
		</>
	)
}

CreateTournament.propTypes = {
	addTournament: PropTypes.func.isRequired,
	getTournamentTypes: PropTypes.func.isRequired,
	// imageToS3: PropTypes.func.isRequired,
	paymentLevel: PropTypes.string,
	// getTournament: PropTypes.func,
	auth: PropTypes.object.isRequired,
	tournamentEdit: PropTypes.bool,
	tournamentAdded: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	tournament: state.tournament,
	tournamentTypes: state.admin.tournamentTypes ? state.admin.tournamentTypes : null,
	paymentLevel: state.payment.paymentLevel,
	tournamentEdit: state.tournament.tournamentEdit,
	auth: state.auth,
	tournamentAdded: state.tournament.tournamentAdded,
})

export default connect(mapStateToProps, { addTournament, getTournamentTypes })(withRouter(CreateTournament))
