import React from 'react'
import Moment from 'react-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const TournLogo = ({ logo, tourName }) => {
	return (
		<div className="img-wrapper">
			<img className="tournament-logo" alt={`${tourName}'s Logo `} src={logo.path} />
		</div>
	)
}

export const TournType = ({ type, colorClass }) => {
	return (
		<div className="item-group-container type" style={{ backgroundColor: `${colorClass}` }}>
			{/* <h6 className="item-group-title">Event Type</h6> */}
			<div className="item-group label" style={{ borderColor: `${colorClass}` }}>
				{type}
			</div>
		</div>
	)
}

export const TournName = ({ tourName }) => {
	return <h2 className="tournament-title">{tourName}</h2>
}

export const TournDate = ({ startDate, endDate }) => {
	return (
		<>
			<div className="item-group-container date">
				<h6 className="item-group-title">Event Date(s)</h6>
				<div className="item-start-date">
					<div className="item-group">
						<div className="event-date">
							<div className="calendar-date">
								<Moment format="MMMM Do YYYY">{startDate}</Moment>
							</div>
							{/* TODO: Set to not display if endDate exists but is the same day as start date */}
							{endDate && (
								<>
									<div className="date-divider right">
										<FontAwesomeIcon icon={{ prefix: 'far', iconName: 'arrow-alt-right' }} />
									</div>
									<div className="calendar-date">
										<Moment format="MMMM Do YYYY">{endDate}</Moment>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="item-group-container start-time">
				<h6 className="item-group-title">Event Time</h6>
				<div className="item-start-time">
					<div className="item-group">
						<div className="event-date">
							<div className="event-time">
								@ <Moment format="h:mm a">{startDate}</Moment>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export const TournLocation = ({ location }) => {
	return (
		<div className="item-group-container location">
			<h6 className="item-group-title">Location: </h6>

			<ul className="item-group address-container">
				<li className="street">{location.address}, </li>
				<li className="street">
					{location.city}, {location.state}, {location.zip}
				</li>
			</ul>
			{/* <ul className="item-group address-container">
				<li className="street">{location.address}, </li>
				<li className="street">{location.city}, </li>
				<li className="street">{location.state}, </li>
				<li className="street">{location.zip}</li>
			</ul> */}
		</div>
	)
}
