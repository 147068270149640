// Alerts
export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
// Validation
export const CLEAR_VALIDATION = 'CLEAR_VALIDATION'
// Registration
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
// Login
export const AUTH_ERROR = 'AUTH_ERROR'
export const USER_LOADED = 'USER_LOADED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
// Profile
export const GET_PROFILE = 'GET_PROFILE'
export const GET_PROFILES = 'GET_PROFILES'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const CLEAR_PROFILE = 'CLEAR_PROFILE'
export const PROFILE_ERROR = 'PROFILE_ERROR'
// Account Actions
export const USER_ACCOUNT_UPGRADED = 'USER_ACCOUNT_UPGRADED'
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED'
export const USER_PASSWORD_UPDATE_REQUESTED = 'USER_PASSWORD_UPDATE_REQUESTED'
export const USER_PASSWORD_UPDATE_REQUESTED_ERROR = 'USER_PASSWORD_UPDATE_REQUESTED_ERROR'
export const USER_PASSWORD_UPDATE_EMAIL_SENT = 'USER_PASSWORD_UPDATE_EMAIL_SENT'
export const USER_PASSWORD_UPDATE_EMAIL_SENT_ERROR = 'USER_PASSWORD_UPDATE_EMAIL_SENT_ERROR'
export const USER_PASSWORD_UPDATE_NO_EMAIL = 'USER_PASSWORD_UPDATE_NO_EMAIL'
export const USER_PASSWORD_UPDATING = 'USER_PASSWORD_UPDATING'
export const USER_PASSWORD_UPDATED = 'USER_PASSWORD_UPDATED'
export const USER_PASSWORD_UPDATED_ERROR = 'USER_PASSWORD_UPDATED_ERROR'
export const USER_PASSWORD_UPDATE_RESET_CODE_VERIFIED = 'USER_PASSWORD_UPDATE_RESET_CODE_VERIFIED'
export const USER_PASSWORD_UPDATE_RESET_CODE_ERROR = 'USER_PASSWORD_UPDATE_RESET_CODE_ERROR'
export const RESET_USER_PASSWORD_UPDATE = 'RESET_USER_PASSWORD_UPDATE'
// Tournament Stuff
// Creating Tournaments
export const SHOW_TOURNAMENT_LEVEL_OPTIONS = 'SHOW_TOURNAMENT_LEVEL_OPTIONS'
export const HIDE_TOURNAMENT_LEVEL_OPTIONS = 'HIDE_TOURNAMENT_LEVEL_OPTIONS'
export const SHOW_CREATE_TOURNAMENT_FORM = 'SHOW_CREATE_TOURNAMENT_FORM'
export const HIDE_CREATE_TOURNAMENT_FORM = 'HIDE_CREATE_TOURNAMENT_FORM'
export const PAYMENT_LEVEL_SELECTED = 'PAYMENT_LEVEL_SELECTED'
export const ADD_TOURNAMENT = 'ADD_TOURNAMENT'
// Retrieving Tournament(s)
export const GET_ALL_TOURNAMENTS = 'GET_ALL_TOURNAMENTS'
export const GET_CURRENT_TOURNAMENTS = 'GET_CURRENT_TOURNAMENTS'
export const GET_EXPIRED_TOURNAMENTS = 'GET_EXPIRED_TOURNAMENTS'
export const GET_TOURNAMENT = 'GET_TOURNAMENT'
export const CLEAR_TOURNAMENTS = 'CLEAR_TOURNAMENTS'
export const CLEAR_TOURNAMENT = 'CLEAR_TOURNAMENT'
export const TOURNAMENT_ERROR = 'TOURNAMENT_ERROR'
export const TOURNAMENT_SUBMISSION_COMPLETE = 'TOURNAMENT_SUBMISSION_COMPLETE'
export const USER_GET_SAVED_TOURNAMENT_ID = 'USER_GET_SAVED_TOURNAMENT_ID'
export const USER_GET_SAVED_TOURNAMENT_ID_ERROR = 'USER_GET_SAVED_TOURNAMENT_ID_ERROR'
export const USER_GET_SAVED_TOURNAMENT_ALL_DATA = 'USER_GET_SAVED_TOURNAMENT_ALL_DATA'
export const USER_GET_SAVED_TOURNAMENT_ALL_DATA_ERROR = 'USER_GET_SAVED_TOURNAMENT_ALL_DATA_ERROR'
export const TOURNAMENT_UPDATE_LEVEL = 'TOURNAMENT_UPDATE_LEVEL'
// Saving / Removing Tournaments
export const USER_SAVED_TOURNAMENT = 'USER_SAVED_TOURNAMENT'
export const USER_SAVED_TOURNAMENT_ERROR = 'USER_SAVED_TOURNAMENT_ERROR'
export const USER_REMOVED_TOURN_FROM_SAVED = 'USER_REMOVED_TOURN_FROM_SAVED'
export const USER_REMOVED_TOURN_FROM_SAVED_ERROR = 'USER_REMOVED_TOURN_FROM_SAVED_ERROR'
// Tournament Edit
export const EDIT_TOURNAMENT = 'EDIT_TOURNAMENT'
export const EXIT_EDIT_TOURNAMENT = 'EXIT_EDIT_TOURNAMENT'
// Search
export const MAP_CENTER = 'MAP_CENTER'
export const SEARCH_TOURNAMENTS = 'SEARCH_TOURNAMENTS'
export const SEARCH_NO_RESULTS = 'SEARCH_NO_RESULTS'
export const SEARCH_MISPELLED_CITY = 'SEARCH_MISPELLED_CITY'
export const SEARCH_HAS_CITY_NO_STATE = 'SEARCH_HAS_CITY_NO_STATE'
export const SEARCH_HAS_STATE_NO_CITY = 'SEARCH_HAS_STATE_NO_CITY'
export const SEARCH_TOURNAMENTS_ERROR = 'SEARCH_TOURNAMENTS_ERROR'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'
// Likes / Comments
export const UPDATE_LIKES = 'UPDATE_LIKES'
export const DELETE_TOURNAMENT = 'DELETE_TOURNAMENT'
export const ADD_COMMENT = 'ADD_COMMENT'
export const REMOVE_COMMENT = 'REMOVE_COMMENT'

// Checkout / Payment
export const PAYMENT_SUBMITTED = 'PAYMENT_SUBMITTED'
export const PAYMENT_SUBMITTED_ERROR = 'PAYMENT_SUBMITTED_ERROR'
export const REGISTRATION_TYPE_SELECTED = 'REGISTRATION_TYPE_SELECTED'
export const SHOW_PAYMENT_MODAL = 'SHOW_PAYMENT_MODAL'
export const HIDE_PAYMENT_MODAL = 'HIDE_PAYMENT_MODAL'
// Tournament Email Alerts
export const USER_TOURN_ALERTS_IS_REGISTERED = 'USER_TOURN_ALERTS_IS_REGISTERED'
export const NEW_TOURNAMENT_ALERT_SIGNUP = 'NEW_TOURNAMENT_ALERT_SIGNUP'
export const NEW_TOURNAMENT_ALERT_SIGNUP_ERROR = 'NEW_TOURNAMENT_ALERT_SIGNUP_ERROR'
export const NEW_TOURNAMENT_MAIL_ALERT = 'NEW_TOURNAMENT_MAIL_ALERT'
export const NEW_TOURNAMENT_MAIL_ALERT_ERROR = 'NEW_TOURNAMENT_MAIL_ALERT_ERROR'
export const TOURNAMENT_ALERT_SIGNUP_OPEN = 'TOURNAMENT_ALERT_SIGNUP_OPEN'
export const TOURNAMENT_ALERT_SIGNUP_CLOSE = 'TOURNAMENT_ALERT_SIGNUP_CLOSE'
export const TOURNAMENT_ALERT_SIGNUP_COMPLETE = 'TOURNAMENT_ALERT_SIGNUP_COMPLETE'
export const FROM_TOURNAMENT_ALERT_SIGN_UP = 'FROM_TOURNAMENT_ALERT_SIGN_UP'
// Dahboard Views
export const SHOW_DASHBOARD_LANDING = 'SHOW_DASHBOARD_LANDING'
export const SHOW_TOURNAMENT_SEARCH = 'SHOW_TOURNAMENT_SEARCH'
export const SHOW_LIST_A_TOURNAMENT = 'SHOW_LIST_A_TOURNAMENT'
export const SHOW_VIEW_PROFILE = 'SHOW_VIEW_PROFILE'
export const SHOW_VIEW_MY_TOURNAMENTS = 'SHOW_VIEW_MY_TOURNAMENTS'
export const SHOW_MY_TOURNAMENT_WATCHLIST = 'SHOW_MY_TOURNAMENT_WATCHLIST'
// Admin
// Admin Tournament Stuff
export const ADMIN_APPROVED_STATE_UPDATED = 'ADMIN_APPROVED_STATE_UPDATED'
export const ADMIN_APPROVED_TOURNAMENT = 'ADMIN_APPROVED_TOURNAMENT'
export const ADMIN_DISAPPROVED_TOURNAMENT = 'ADMIN_DISAPPROVED_TOURNAMENT'
export const ADMIN_GET_SAVED_TOURNAMENT_TYPES = 'ADMIN_GET_SAVED_TOURNAMENT_TYPES'
export const ADMIN_GET_SAVED_TOURNAMENT_TYPES_ERROR = 'ADMIN_GET_SAVED_TOURNAMENT_TYPES_ERROR'
export const ADMIN_ADDED_TOURNAMENT_TYPE = 'ADMIN_ADDED_TOURNAMENT_TYPE'
export const ADMIN_ADDED_TOURNAMENT_TYPE_ERROR = 'ADMIN_ADDED_TOURNAMENT_TYPE_ERROR'
export const ADMIN_EDITED_TOURNAMENT_TYPE = 'ADMIN_EDITED_TOURNAMENT_TYPE'
export const ADMIN_EDITED_TOURNAMENT_TYPE_ERROR = 'ADMIN_EDITED_TOURNAMENT_TYPE_ERROR'
export const ADMIN_DELETED_TOURNAMENT_TYPE = 'ADMIN_DELETED_TOURNAMENT_TYPE'
export const ADMIN_DELETED_TOURNAMENT_TYPE_ERROR = 'ADMIN_DELETED_TOURNAMENT_TYPE_ERROR'
// Admin Views
export const SHOW_ADMIN_VIEW_ALL_TOURNAMENTS = 'SHOW_ADMIN_VIEW_ALL_TOURNAMENTS'
export const SHOW_ADMIN_VIEW_CURRENT_TOURNAMENTS = 'SHOW_ADMIN_VIEW_CURRENT_TOURNAMENTS'
export const SHOW_ADMIN_VIEW_EXPIRED_TOURNAMENTS = 'SHOW_ADMIN_VIEW_EXPIRED_TOURNAMENTS'
export const SHOW_ADMIN_CREATE_A_TOURNAMENT = 'SHOW_ADMIN_CREATE_A_TOURNAMENT'
export const SHOW_ADMIN_EDIT_TOURNAMENT_TYPES = 'SHOW_ADMIN_EDIT_TOURNAMENT_TYPES'
export const SHOW_ADMIN_ADD_TOURNAMENT_TYPE_FORM = 'SHOW_ADMIN_ADD_TOURNAMENT_TYPE_FORM'
export const HIDE_ADMIN_ADD_TOURNAMENT_TYPE_FORM = 'HIDE_ADMIN_ADD_TOURNAMENT_TYPE_FORM'
export const SHOW_ADMIN_EDIT_TOURNAMENT_TYPE_FORM = 'SHOW_ADMIN_EDIT_TOURNAMENT_TYPE_FORM'
export const HIDE_ADMIN_EDIT_TOURNAMENT_TYPE_FORM = 'HIDE_ADMIN_EDIT_TOURNAMENT_TYPE_FORM'
