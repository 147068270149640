import axios from 'axios'
import { setAlert } from './alert'

import {
	NEW_TOURNAMENT_MAIL_ALERT,
	NEW_TOURNAMENT_MAIL_ALERT_ERROR,
	NEW_TOURNAMENT_ALERT_SIGNUP,
	NEW_TOURNAMENT_ALERT_SIGNUP_ERROR,
	USER_TOURN_ALERTS_IS_REGISTERED,
	USER_PASSWORD_UPDATE_EMAIL_SENT,
	USER_PASSWORD_UPDATE_EMAIL_SENT_ERROR,
	USER_PASSWORD_UPDATE_NO_EMAIL,
} from './types'

export const tournAlertSignUp = data => async dispatch => {
	// console.log('data = ', data)

	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}
	try {
		const res = await axios.post('/api/mail/alert-sign-up', data, config)
		dispatch({
			type: NEW_TOURNAMENT_ALERT_SIGNUP,
			payload: res.data,
		})
		dispatch({
			type: USER_TOURN_ALERTS_IS_REGISTERED,
		})

		// console.log('res.data = ', res.data)
	} catch (err) {
		dispatch({
			type: NEW_TOURNAMENT_ALERT_SIGNUP_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		})
	}
}

export const sendMail_NewTournAlert = data => async dispatch => {
	// console.log('sendMail_NewTournAlert DATA = ', data)

	const { _id, tourName, category, contact, eventDate, description, location, logo, sponsors } = data
	const { startDate, endDate } = eventDate
	const { website, email, phone } = contact
	const { zip, address, city, state } = location

	const tournData = {}

	tournData._id = _id
	tournData.tourName = tourName
	tournData.category = category
	tournData.description = description
	tournData.zip = zip
	tournData.address = address
	tournData.city = city
	tournData.state = state
	tournData.startDate = startDate
	tournData.endDate = endDate
	tournData.website = website
	tournData.email = email
	tournData.phone = phone
	tournData.logo = logo.path
	tournData.sponsors = sponsors

	// console.log('tournData = ', tournData)

	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}
	try {
		const res = await axios.post('/api/mail/new-tournament-alert', tournData, config)

		dispatch({
			type: NEW_TOURNAMENT_MAIL_ALERT,
			payload: res.data,
		})
	} catch (err) {
		dispatch({
			type: NEW_TOURNAMENT_MAIL_ALERT_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		})
	}
}

export const sendMail_TournTwoWeekDeadline = async data => {
	// console.log('mail test')

	const testData = {
		data: 'test',
	}

	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}

	const res = await axios.post('/api/mail/daily-scan-tourn-registation-deadline', testData, config)
}

export const requestPasswordChange = data => async dispatch => {
	// console.log(data)

	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}

	try {
		const res = await axios.post('/api/mail/request-password-change', data, config)
		// console.log(res)

		if (res.data.emailSent) {
			dispatch(setAlert('Email Sent', 'success'))
			dispatch({ type: USER_PASSWORD_UPDATE_EMAIL_SENT })
		} else {
			if (!res.data.emailExists) {
				dispatch({ type: USER_PASSWORD_UPDATE_NO_EMAIL })
			}
		}

		// dispatch(loadUser())
		// dispatch(setAlert('Account Updated', 'success'))
	} catch (err) {
		dispatch({ type: USER_PASSWORD_UPDATE_EMAIL_SENT_ERROR })
		dispatch({ type: USER_PASSWORD_UPDATE_NO_EMAIL })
		console.log('Error Sending Email', err)
	}
}

export const advertisingContactRequest = data => async dispatch => {
	console.log(data)

	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}

	// const res = await axios.post('/api/mail/advertising-contact-request', data, config)

	try {
		// Using extensions like "add block" -> flags requests with 'advertising' in the request path and blocks them giving a
		// net::ERR_BLOCKED_BY_CLIENT error
		// adjusting request from '/api/mail/advertising-contact-request' to '/api/mail/fftadvertfft-contact-request' to avoid blocking by and users who
		// have extensions like adblock enabled
		const res = await axios.post('/api/mail/fftadvertfft-contact-request', data, config)
		console.log(res.data)
	} catch (err) {
		console.log('Error Sending Email', err)
	}
}
