import { handleS3Token, handleS3Upload } from '../../../actions/fileUpload'

export const uploadToS3Bucket = (
	s3UploadObject,
	setS3UploadObject,
	setSponsorLogo,
	sponsorLogo,
	setS3UploadObjectLoading
) => {
	if (s3UploadObject.itemName !== 'Choose File') {
		// console.log('uploadItem = ', s3UploadObject.item)
		// set loading to true for Uploading Item
		setSponsorLogo({ ...sponsorLogo, loading: true })
		// Get Upload Security Token From AWS
		handleS3Token(s3UploadObject.item, s3UploadObject.fileType).then((tokenRes) => {
			// console.log('tokenRes', tokenRes)
			const signedRequest = tokenRes.data.data.returnData.signedRequest
			const url = tokenRes.data.data.returnData.url

			// Once Token is recieved send file with token to AWS
			handleS3Upload(signedRequest, s3UploadObject.item, s3UploadObject.fileType).then(
				(fileUploadSponsorsRes) => {
					// console.log('fileUploadSponsorsRes', fileUploadSponsorsRes)
					setSponsorLogo({
						...sponsorLogo,
						name: s3UploadObject.itemName,
						url: url,
						uploadSuccess: true,
						localUploadURL: s3UploadObject.localUploadUrl,
						loading: false,
					})
					setS3UploadObject({ ...s3UploadObject })
					setS3UploadObjectLoading(false)
				}
			)
		})
	}
}
