import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import InfoBox from '../Layout/Elements/InfoBox'
import PropTypes from 'prop-types'
import InnerStructure from '../Layout/InnerStructure'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import ChooseAccountType from './ChooseAccountType'
import RegisterForm from '../forms/registration/RegisterForm'
import { titleCase } from '../../_js_functions'
import LogoTitle from '../Layout/Elements/LogoTitle'
import { TOURNAMENT_ALERT_SIGNUP_OPEN } from '../../actions/types'

const Register = ({ isAuthenticated, registrationType, role, fromTournAlertSignUp }) => {
	const dispatch = useDispatch()
	// After registration is complete and user is created and authenticated
	// Authenticated user is redirected to dashboard
	if (isAuthenticated) {
		console.log('IS AUTHENTICATED')
		if (fromTournAlertSignUp) {
			console.log('fired')

			dispatch({ type: TOURNAMENT_ALERT_SIGNUP_OPEN })
		}

		return <Redirect to="/dashboard" />
	}

	if (registrationType === null) {
		return <Redirect to="/register/account-type" />
	}

	return (
		<InnerStructure>
			<LogoTitle tag="h1" title="Sign Up" />

			<div className="registration-form-container">
				{registrationType === 'tournament' && (
					<InfoBox variant="info">
						<p>
							Tournament Owners have all the features available as a standard user but can also create and
							list tournaments on FindFinshingTournaments.com
						</p>
					</InfoBox>
				)}
				<h6 className="info-title">
					{/* TODO: Enhance this icon */}
					<FontAwesomeIcon icon="user-circle" /> Create Your "
					{registrationType === 'tournament'
						? titleCase(registrationType) + ' Owner'
						: titleCase(registrationType)}
					" Account
				</h6>
				<RegisterForm />
			</div>
		</InnerStructure>
	)
}

Register.propTypes = {
	isAuthenticated: PropTypes.bool,
	registrationType: PropTypes.string,
	// user: PropTypes.object,
	role: PropTypes.string,
	fromTournAlertSignUp: PropTypes.bool,
}

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
	registrationType: state.auth.registrationType,
	// user: state.auth.user,
	role: state.auth.role,
	fromTournAlertSignUp: state.mail.fromTournAlertSignUp,
})

export default connect(mapStateToProps)(Register)
