import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { EditorState } from 'draft-js'
// import { getTournament } from '../../../actions/tournament'
import CreateTournament from './CreateTournament'
import { TimelineMax } from 'gsap/all'

const EditTournament = ({ match, tournament, tournId }) => {
	const [editorState, setEditorState] = useState(EditorState.createWithContent(tournament.description))

	// useEffect(() => {
	// 	console.log('editorState = ', editorState)
	// }, [editorState])

	const [formData, setFormData] = useState({
		tourName: '',
		category: '',
		description: '',
		startDate: null,
		endDate: null,
		address: '',
		city: '',
		state: '',
		zip: '',
		lat: '',
		lng: '',
		organizer: '',
		website: '',
		email: '',
		phone: '',
		sponsors: [],
		media: '',
		approved: '',
		logoName: '',
		logoPath: '',
		tournMediaName: '',
		tournMediaPath: '',
		registrationDeadline: null,
	})

	// useEffect(() => {
	// 	// console.log(tournId)
	// 	getTournament(tournId)
	// }, [])

	useEffect(() => {
		if (tournament) {
			setFormData({
				tourName: tournament.tourName ? tournament.tourName : null,
				paymentLevel: tournament.paymentLevel ? tournament.paymentLevel : null,
				category: tournament.category ? tournament.category : null,
				description: tournament.description ? tournament.description : null,
				startDate: tournament.eventDate.endDate ? tournament.eventDate.endDate : null,
				endDate: tournament.eventDate.startDate ? tournament.eventDate.startDate : null,
				address: tournament.location.address ? tournament.location.address : null,
				city: tournament.location.city ? tournament.location.city : null,
				state: tournament.location.state ? tournament.location.state : null,
				zip: tournament.location.zip ? tournament.location.zip : null,
				lat: tournament.location.lat ? tournament.location.lat : null,
				lng: tournament.location.lng ? tournament.location.lng : null,
				organizer: tournament.organizer ? tournament.organizer : null,
				website: tournament.contact.website ? tournament.contact.website : null,
				email: tournament.contact.email ? tournament.contact.email : null,
				phone: tournament.contact.phone ? tournament.contact.phone : null,
				sponsors: tournament.sponsors ? tournament.sponsors : null,
				logoName: tournament.logo ? tournament.logo.name : null,
				logoPath: tournament.logo ? tournament.logo.path : null,
				tournMediaName: tournament.tournMedia.name ? tournament.tournMedia.name : null,
				tournMediaPath: tournament.tournMedia.path ? tournament.tournMedia.path : null,
				registrationDeadline: tournament.registrationDeadline ? tournament.registrationDeadline : null,
			})
		}
	}, [tournament])

	return (
		<div className="edit-tournament-container">
			<CreateTournament
				editTournamentData={formData}
				tournId={tournId}
				editorState={editorState}
				setEditorState={setEditorState}
			/>
		</div>
	)
}

EditTournament.propTypes = {}

const mapStateToProps = (state) => ({
	// tournament: state.tournament.tournament,
})

export default connect(mapStateToProps)(EditTournament)
