import React from 'react'

const StructureAdmin = props => {
	return (
		<>
			<div className="admin-content">{props.children}</div>
		</>
	)
}

// Structure.propTypes = {}

export default StructureAdmin
