import React from 'react'

const MapMarkerInfoPanel = ({ name, cat }) => {
	return (
		<div className="map-marker-info-container">
			<div className="map-marker-info">
				<div className="tourn-cat">Type: {cat}</div>
				<div className="tourn-name">{name}</div>
			</div>
		</div>
	)
}

export default MapMarkerInfoPanel
