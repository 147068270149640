import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import Button from '../../Layout/Elements/Button'
import Checkbox from '../../forms/Checkbox'
import { searchTournaments } from '../../../actions/tournament'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import SelectElem from '../../forms/SelectElem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Validation from '../../Layout/Elements/Validation'
import { CLEAR_TOURNAMENTS, CLEAR_VALIDATION } from '../../../actions/types'

const usStateData = require('./us_states.json')
// import PropTypes from 'prop-types'

const TournamentSearch = ({ searchTournaments, tournament }) => {
	// const { tournaments, tournamentLoading } = tournament
	const dispatch = useDispatch()
	const [miles, setMiles] = useState(10)
	const [useCityState, setUseCityState] = useState(false)

	const [formData, setFormData] = useState({
		zip: '', // remove 32696 - make ''
		street: '',
		city: '',
		state: '',
		lat: null,
		lng: null,
		distance: miles,
		zips: [],
		result_id: null,
		message: '',
	})

	const { zip, city, state } = formData

	const onRangeChange = miles => {
		setMiles(miles)
		setFormData({ ...formData, distance: miles })
	}
	const onChange = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	const onSubmit = e => {
		e.preventDefault()
		dispatch({
			type: CLEAR_VALIDATION,
		})
		dispatch({
			type: CLEAR_TOURNAMENTS,
		})

		if (zip === '' && city === '' && state === '') {
			alert('Ooops! You must first enter a valid zip code -or- city and state.')
		} else {
			if (formData.state === 'Select Tournament Type') {
				formData.state = ''
			}

			if (zip !== '') {
				formData.city = ''
				formData.state = ''
			}

			// console.log('formData component submit = ', formData)
			searchTournaments(formData)
		}
	}

	const dismissAlert = e => {
		dispatch({
			type: CLEAR_VALIDATION,
		})
	}

	const onDisplayChangeFunc = e => {
		e.preventDefault()
		setUseCityState(!useCityState)
	}

	useEffect(() => {
		if (useCityState) {
			setFormData({ ...formData, zip: '' })
		}
	}, [useCityState])

	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	// REMOVE -- DEV ONLY
	// useEffect(() => {
	// 	searchTournaments(formData)
	// }, [])
	// END REMOVE -- DEV ONLY
	// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

	return (
		<section className="tournament-search-container">
			<div className="tournament-search">
				<div className="header-block">
					<div className="tab active">
						<span>Tournament Search</span>
					</div>
					{/* <div className="tab stand-by">
						<span>Advanced Search</span>
					</div> */}
				</div>

				<form className="form" onSubmit={e => onSubmit(e)}>
					<div className="form-backdrop"></div>
					<fieldset className="outline">
						{!useCityState && (
							<>
								<div className="form-section search-by-zip">
									<h4 className="form-section-title">Search by Zip</h4>
									<div className="form-group">
										<input
											type="text"
											placeholder="Zip"
											name="zip"
											value={zip}
											onChange={e => onChange(e)}
										/>
									</div>
								</div>
								<div className="form-group">
									<Checkbox
										name="display-mode"
										label="I don't have the zip, let me search by City and State"
										value={useCityState}
										onChange={e => onDisplayChangeFunc(e)}
									/>
								</div>
							</>
						)}

						{/* <div className="form-section-divider">
							<span className="divider-text">or</span>
						</div> */}
						{useCityState && (
							<>
								<div className="form-section search-by-address">
									<h4 className="form-section-title">Search by City / State</h4>

									<div className="form-row">
										<div className="form-group">
											<input
												type="text"
												name="city"
												value={city}
												placeholder="City"
												onChange={e => onChange(e)}
											/>
											{tournament.searchError &&
												tournament.searchErrorMessage === 'spelling_city' && (
													<>
														<Validation
															errMsg="City Not Found"
															solution="Please Check Spelling"
															errClass="no-city"
														/>
													</>
												)}
											{tournament.searchError && tournament.searchErrorMessage === 'no_city' && (
												<div className="validation validation__error spelling">
													<div className="close-button-container">
														<div className="close-button" onClick={e => dismissAlert(e)}>
															<FontAwesomeIcon
																icon={{ prefix: 'fas', iconName: 'times-circle' }}
															/>
														</div>
													</div>
													<p>
														<span className="error__solution">
															Please Enter a City
															<span className="block">or</span>
															Remove the State Selection by choosing "Select State" from
															the dropdown.
														</span>
													</p>
												</div>
											)}
										</div>
										<div className="form-group">
											<SelectElem
												value={state}
												name="state"
												firstLineOption={
													<option value="Select Tournament Type">Select State</option>
												}
												options={usStateData.map((states, index) => {
													return (
														<option key={index} value={states.abbreviation}>
															{states.name}
														</option>
													)
												})}
												onChange={e => onChange(e)}
											/>
											{tournament.searchError && tournament.searchErrorMessage === 'no_state' && (
												<>
													<Validation
														errMsg="No State Selected"
														solution="Please Select a State"
														errClass="no-state"
													/>
												</>
											)}
										</div>
									</div>
								</div>
								<div className="form-group">
									<Checkbox
										name="display-mode"
										label="Actually, I do know the Zip Code, let me search by Zip Code"
										value={useCityState}
										onChange={e => onDisplayChangeFunc(e)}
									/>
								</div>
							</>
						)}
					</fieldset>
					{/* <div className="form-section-divider">
						<span className="divider-text">
							<FontAwesomeIcon icon={{ prefix: 'fad', iconName: 'arrow-alt-right' }} />
						</span>
					</div> */}
					<div className="form-section range">
						<h4 className="form-section-title">Select Search Range</h4>
						<div className="form-group">
							<label htmlFor="distance">
								<span className="label-value">{miles} Miles</span>
							</label>
							<Slider name="distance" value={miles} orientation="horizontal" onChange={onRangeChange} />
						</div>
					</div>
					{/* <div className="form-section-divider">
						<span className="divider-text">
							<FontAwesomeIcon icon={{ prefix: 'fad', iconName: 'arrow-alt-right' }} />
						</span>
					</div> */}
					<div className="form-section submit">
						<div className="form-group submit-btn">
							<Button variant="primary" size={'large'} label={'Search'} />
						</div>
					</div>
				</form>
			</div>
		</section>
	)
}

TournamentSearch.propTypes = {
	searchTournaments: PropTypes.func.isRequired,
	tournament: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
	tournament: state.tournament,
})

export default connect(mapStateToProps, { searchTournaments })(TournamentSearch)
