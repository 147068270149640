import React, { useRef, useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { logout } from '../../actions/auth'
import logo from '../../img/logo-color.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
	RESET_USER_PASSWORD_UPDATE,
	CLEAR_TOURNAMENTS,
	CLEAR_TOURNAMENT,
	EXIT_EDIT_TOURNAMENT,
} from '../../actions/types'

const Navbar = ({ auth: { isAuthenticated, authLoading, isAdmin }, logout, registrationType }) => {
	const dispatch = useDispatch()
	const navbar = useRef()
	const brand = useRef()
	const handleLogout = () => {
		logout()
	}

	const [navbarWrapped, setNavbarWrapped] = useState(false)

	const tournDataClear = () => {
		dispatch({
			type: CLEAR_TOURNAMENTS,
		})
		dispatch({
			type: EXIT_EDIT_TOURNAMENT,
		})
	}

	useEffect(() => {
		function detectHeight() {
			const brandItem = brand.current
			const brandHeight = brandItem.offsetHeight
			const navbarItem = navbar.current
			const navbarHeight = navbarItem.offsetHeight
			if (navbarHeight > brandHeight) {
				setNavbarWrapped(true)
			} else {
				setNavbarWrapped(false)
			}
		}

		detectHeight()

		function handleResize() {
			detectHeight()
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	})

	const authLinks = (
		<ul className="nav-links auth-links">
			<li>
				<Link to="/dashboard" onClick={() => tournDataClear()}>
					<FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'user' }} />
					<span className="hide-sm">Dashboard</span>
				</Link>
			</li>
			{isAdmin ? (
				<>
					<li>
						<Link to="/admin" onClick={() => tournDataClear()}>
							<FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'user' }} />
							<span className="hide-sm">Admin</span>
						</Link>
					</li>
					<li>
						<Link to="/styleguide" onClick={() => tournDataClear()}>
							<FontAwesomeIcon icon={{ prefix: 'fad', iconName: 'badger-honey' }} />
							Styleguide
						</Link>
					</li>
				</>
			) : (
				''
			)}
			<li>
				<a onClick={handleLogout} href="#!">
					<FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'sign-out-alt' }} />
					<span className="hide-sm">Logout</span>
				</a>
			</li>
		</ul>
	)

	const guestLinks = (
		<ul className="nav-links guest-links">
			<li>
				<Link to="/login" onClick={() => dispatch({ type: RESET_USER_PASSWORD_UPDATE })}>
					Login
				</Link>
			</li>
			<li>
				{registrationType === null && <Link to="/register/account-type">Register</Link>}
				{registrationType !== null && <Link to="/register">Register</Link>}
			</li>
			<li>
				<Link to="/advertise">Advertise</Link>
			</li>
			{/* <li>
				<Link to="/styleguide">Styleguide</Link>
			</li> */}
		</ul>
	)

	return (
		<>
			<nav ref={navbar} className={`navbar navbar-main${navbarWrapped ? ' flex-wrapped' : ''}`}>
				<div ref={brand} className="brand">
					<Link className="brand-graphic-container" to="/" onClick={() => tournDataClear()}>
						<img
							className="brand-graphic img-responsive"
							src={logo}
							alt="find fishing tournaments .com logo"
						/>
						<div className="brand-graphic-text-container">
							<div className="text-wrapper">
								<span className="brand-graphic-text top-text">FindFishing</span>
								<span className="brand-graphic-text bottom-text">Tournaments.com</span>
							</div>
						</div>
					</Link>
				</div>
				<div className="nav-actions">{!authLoading && <>{isAuthenticated ? authLinks : guestLinks}</>}</div>
			</nav>
		</>
	)
}

Navbar.propTypes = {
	logout: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	registrationType: PropTypes.string,
}

const mapStateToProps = state => ({
	auth: state.auth,
	registrationType: state.auth.registrationType,
})

export default connect(mapStateToProps, { logout })(Navbar)
