import React, { useState } from 'react'
import FontItem from './FontItem'
import Draggable from 'react-draggable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FontList = () => {
	const fontClassList = ['futura', 'museo', 'esk', 'esk-btn', 'atrament', 'eaves', 'gin', 'rift', 'market']
	const [showT, setShowT] = useState(true)
	const [showP, setShowP] = useState(true)
	const [tag, setTag] = useState('h2')

	const [useTSize, setUseTSize] = useState(false)
	const [usePSize, setUsePSize] = useState(false)
	const [usePLineHeight, setUsePLineHeight] = useState(false)

	const [formData, setFormData] = useState({
		fontSize: '12px',
		pText:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ac lorem dignissim, sollicitudin nibh vitae, porta sapien. Nunc facilisis quam et lectus dapibus mollis.',
		tText: 'Some Title',
		pSize: '1em',
		tSize: '1em',
		pLineHeight: '1.5em',
	})

	const updateData = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
		if (e.target.name === 'tSize') {
			setUseTSize(true)
		}
		if (e.target.name === 'pSize') {
			setUsePSize(true)
		}
		if (e.target.name === 'pLineHeight') {
			setUsePLineHeight(true)
		}
	}

	const setTitleTag = tagType => {
		setTag(tagType)
	}

	return (
		<Draggable handle={'.dragger'}>
			<div className="font-list-container">
				<div className="dragger">
					<FontAwesomeIcon icon={{ prefix: 'far', iconName: 'grip-vertical' }} />
					<FontAwesomeIcon icon={{ prefix: 'far', iconName: 'grip-vertical' }} />
				</div>
				<div className="title-settings setting-container">
					<div className="power-switch">
						<FontAwesomeIcon
							icon={{ prefix: 'fas', iconName: showT ? 'times' : 'check' }}
							onClick={() => setShowT(!showT)}
						/>
					</div>
					<input
						className="t-text-input"
						type="text"
						value={formData.tText}
						name="tText"
						onChange={e => updateData(e)}
					/>
					<ul className="title-select">
						<li onClick={() => setTitleTag('h1')}>h1</li>
						<li onClick={() => setTitleTag('h2')}>h2</li>
						<li onClick={() => setTitleTag('h3')}>h3</li>
						<li onClick={() => setTitleTag('h4')}>h4</li>
						<li onClick={() => setTitleTag('h5')}>h5</li>
						<li onClick={() => setTitleTag('h6')}>h6</li>
					</ul>

					{/* tSize */}
					<div className="num-input-container">
						<input
							className="t-size-input num-input"
							type="text"
							value={formData.tSize}
							name="tSize"
							onChange={e => updateData(e)}
						/>
						<label htmlFor="tSize">
							Size{' '}
							{useTSize && (
								<>
									<FontAwesomeIcon
										icon={{ prefix: 'fas', iconName: 'times' }}
										onClick={() => setUseTSize(false)}
									/>
								</>
							)}
						</label>
					</div>
				</div>
				<div className="paragraph-settings setting-container">
					<div className="power-switch">
						<FontAwesomeIcon
							icon={{ prefix: 'fas', iconName: showP ? 'times' : 'check' }}
							onClick={() => setShowP(!showP)}
						/>
					</div>
					{/* pSize */}
					<div className="num-input-container">
						<input
							className="p-size-input num-input"
							type="text"
							value={formData.pSize}
							name="pSize"
							onChange={e => updateData(e)}
						/>
						<label htmlFor="pSize">
							Size{' '}
							{usePSize && (
								<>
									<FontAwesomeIcon
										icon={{ prefix: 'fas', iconName: 'times' }}
										onClick={() => setUsePSize(false)}
									/>
								</>
							)}
						</label>
					</div>

					{/* pLineHeight */}
					<div className="num-input-container">
						<input
							className="p-line-height-input num-input"
							type="text"
							value={formData.pLineHeight}
							name="pLineHeight"
							onChange={e => updateData(e)}
						/>
						<label htmlFor="pLineHeight">
							L-H{' '}
							{usePLineHeight && (
								<>
									<FontAwesomeIcon
										icon={{ prefix: 'fas', iconName: 'times' }}
										onClick={() => setUsePLineHeight(false)}
									/>
								</>
							)}
						</label>
					</div>

					<textarea
						className="p-text-input"
						type="text"
						value={formData.pText}
						name="pText"
						onChange={e => updateData(e)}
					/>
				</div>
				<div className="font-list">
					{fontClassList.map((font, index) => {
						return (
							<FontItem
								font={font}
								data={formData}
								tag={tag}
								useTSize={useTSize}
								usePSize={usePSize}
								usePLineHeight={usePLineHeight}
								showT={showT}
								showP={showP}
							/>
						)
					})}
				</div>
			</div>
		</Draggable>
	)
}

export default FontList
