import React, { useEffect, useState } from 'react'

const TournamentSponsors = ({ tournament, tournamentLevel, isMobile }) => {
	const sponsorRefs = []
	const [sponsorImages, setSponsorImages] = useState([])

	// Fire when sponsorImages load by feeding off of "const imgOnLoad"
	// useEffect(() => {
	// 	// console.log('sponsorRefs = ', sponsorRefs)
	// 	if (!isMobile) {
	// 		let tallest = 0
	// 		sponsorImages.forEach(element => {
	// 			let thisElemHeight = element.offsetHeight
	// 			if (thisElemHeight > tallest) {
	// 				tallest = thisElemHeight
	// 			}
	// 			// console.log('thisElemHeight = ', thisElemHeight)
	// 		})
	// 		// console.log('tallest = ', tallest)
	// 		sponsorImages.forEach(element => {
	// 			element.closest('.sponsor-container').style.height = tallest + 10 + 'px'
	// 		})
	// 	}
	// }, [sponsorImages])

	const imgOnLoad = e => {
		setSponsorImages([...sponsorImages, e.target])
	}

	return (
		<>
			{tournamentLevel === 'tl_3' && (
				<div
					className={`tournament-sponsors property-field${
						isMobile ? ' ' + 'mobile-display spacing-top-3' : ''
					}`}
				>
					<h2 className="property-field__label" style={{ minHeight: '0.5em' }}>
						{tournament.sponsors.length > 0 && <>Tournament Sponsors:</>}
					</h2>
					<div className="sponsors-container">
						{tournament.sponsors.map((sponsor, index) => {
							return (
								<div
									className="sponsor-container"
									key={index}
									ref={node => (sponsorRefs[index] = node)}
								>
									<a
										className="img-container"
										href={sponsor.sponsorUrl}
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											className="img-responsive"
											src={sponsor.sponsorLogoPath}
											alt={sponsor.sponsorName + ' Logo'}
											onLoad={e => imgOnLoad(e)}
										/>
										{/* <span className="sponsor-name">{sponsor.sponsorName}</span> */}
									</a>
								</div>
							)
						})}
					</div>
				</div>
			)}
			{tournamentLevel === 'tl_2' && (
				<div className="info-item-group">
					<h6 className="item-group-title">Tournament Sponsors:</h6>
					<ul className="info-item-content">
						{tournament.sponsors.map((sponsor, index) => {
							return (
								<li className="sponsor-list-item" key={index} ref={node => (sponsorRefs[index] = node)}>
									{sponsor.sponsorName}
								</li>
							)
						})}
					</ul>
				</div>
			)}
		</>
	)
}

export default TournamentSponsors
