import axios from 'axios'
import { setAlert } from './alert'

import { loadUser } from './auth'

import {
	USER_ACCOUNT_UPGRADED,
	USER_PASSWORD_UPDATED,
	USER_PASSWORD_UPDATED_ERROR,
	USER_PASSWORD_UPDATE_RESET_CODE_VERIFIED,
	USER_PASSWORD_UPDATE_RESET_CODE_ERROR,
} from './types'

export const upgradeUserAccount = data => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}
	try {
		const res = await axios.post('/api/users/upgrade-account', data, config)
		dispatch(loadUser())
		dispatch({ type: USER_ACCOUNT_UPGRADED })
		dispatch(setAlert('Account Updated', 'success'))
	} catch (err) {
		console.log('Error Upgrading Account', err)
	}
}

// Tests if a reset code exists for password reset requests
export const verifyResetCode = (resetCode, userId) => async dispatch => {
	try {
		const res = await axios.get(`/api/users/verify-reset-code/${resetCode}`)
		if (res.data.user === userId) {
			dispatch({ type: USER_PASSWORD_UPDATE_RESET_CODE_VERIFIED })
		} else {
			dispatch({ type: USER_PASSWORD_UPDATE_RESET_CODE_ERROR })
		}
	} catch (err) {
		console.log('Error Updating Password', err)
		dispatch({ type: USER_PASSWORD_UPDATE_RESET_CODE_ERROR })
	}
}

export const changePassword = (data, isFromEmail) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}
	try {
		if (data.newPass === data.newPassConf) {
			const res = await axios.post('/api/users/change-password', data, config)
			dispatch({ type: USER_PASSWORD_UPDATED })
			if (!isFromEmail) {
				dispatch(loadUser())
			}
		} else {
			dispatch({ type: USER_PASSWORD_UPDATED_ERROR })
			dispatch(setAlert('Passwords Do Not Match', 'danger'))
		}
	} catch (err) {
		console.log('Error Updating Password', err)
	}
}
