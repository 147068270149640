import React from 'react'

const ViewTitleBar = ({ viewTitle, children }) => {
	return (
		<div className="view-title-bar">
			<h1 className="view-title">{viewTitle}</h1>
			{children}
		</div>
	)
}

export default ViewTitleBar
