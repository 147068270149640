import React, { useState } from 'react'
import Tournaments from '../Layout/Tournament/Tournaments'
import Button from '../Layout/Elements/Button'

const UserTournaments = ({ userId }) => {
	const [displayCurrentTourn, setDisplayCurrentTourn] = useState(true)
	const [displayExpiredTourn, setDisplayExpiredTourn] = useState(false)
	const [displayAllTourn, setDisplayAllTourn] = useState(false)

	const handleTournDisplay = selection => {
		switch (selection) {
			case 'all':
				setDisplayCurrentTourn(false)
				setDisplayExpiredTourn(false)
				setDisplayAllTourn(true)
				break
			case 'expired':
				setDisplayCurrentTourn(false)
				setDisplayExpiredTourn(true)
				setDisplayAllTourn(false)
				break
			default:
				setDisplayCurrentTourn(true)
				setDisplayExpiredTourn(false)
				setDisplayAllTourn(false)
		}
	}

	return (
		<div className="my-tournaments-container">
			<header className="dasboard-section-header">
				<div className="section-title">
					{displayCurrentTourn && (
						<>
							<h3>My Current Tournaments:</h3>
						</>
					)}
					{displayExpiredTourn && (
						<>
							<h3>My Completed Tournaments:</h3>
						</>
					)}
					{displayAllTourn && (
						<>
							<h3>My Current and Completed Tournaments:</h3>
						</>
					)}
				</div>
				<div className="section-options">
					<Button
						className={displayCurrentTourn ? ' ' + 'active' : ' ' + 'inactive'}
						variant="link"
						label={'Current Tournaments'}
						icon={displayCurrentTourn ? 'eye' : 'eye-slash'}
						iconType={'fad'}
						onClick={() => handleTournDisplay('current')}
						size="small"
					/>
					<Button
						className={displayExpiredTourn ? ' ' + 'active' : ' ' + 'inactive'}
						variant="link"
						label={'Completed Tournaments'}
						icon={displayExpiredTourn ? 'eye' : 'eye-slash'}
						iconType={'fad'}
						onClick={() => handleTournDisplay('expired')}
						size="small"
					/>
					<Button
						className={displayAllTourn ? ' ' + 'active' : ' ' + 'inactive'}
						variant="link"
						label={'All Tournaments'}
						icon={displayAllTourn ? 'eye' : 'eye-slash'}
						iconType={'fad'}
						onClick={() => handleTournDisplay('all')}
						size="small"
					/>
				</div>
			</header>

			{displayCurrentTourn && (
				<>
					<Tournaments userId={userId} filter={'current'} />
				</>
			)}
			{displayExpiredTourn && (
				<>
					<Tournaments userId={userId} filter={'expired'} />
				</>
			)}
			{displayAllTourn && (
				<>
					<Tournaments userId={userId} />
				</>
			)}
		</div>
	)
}

export default UserTournaments
