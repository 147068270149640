import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
	FacebookShareButton,
	TwitterShareButton,
	RedditShareButton,
	EmailShareButton,
	FacebookIcon,
	TwitterIcon,
	RedditIcon,
	EmailIcon,
} from 'react-share'

const SocialShare = ({ shareUrl, title }) => {
	return (
		<div className="share-panel">
			<FacebookShareButton url={shareUrl} quote={title} className="share-button">
				{/* <FontAwesomeIcon icon={{ prefix: 'fab', iconName: 'facebook-square' }} /> */}
				<FacebookIcon size={32} round />
			</FacebookShareButton>

			<TwitterShareButton url={shareUrl} title={title} className="share-button">
				<TwitterIcon size={32} round />
			</TwitterShareButton>

			<RedditShareButton
				url={shareUrl}
				title={title}
				windowWidth={660}
				windowHeight={460}
				className="share-button"
			>
				<RedditIcon size={32} round />
			</RedditShareButton>

			<EmailShareButton url={shareUrl} subject={title} body="body" className="share-button">
				<EmailIcon size={32} round />
			</EmailShareButton>
		</div>
	)
}

export default SocialShare
