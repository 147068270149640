import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Spinner from '../../../components/effects/loading/Spinner'
import { getTournaments, getUserTournaments } from '../../../actions/tournament'
import TournamentItem from '../Tournament/TournamentItem'

const Tournaments = ({
	getTournaments,
	getUserTournaments,
	tournament: { tournament, tournaments, tournamentLoading },
	userId,
	tournamentTypes,
	isAdminArea,
	filter,
}) => {
	useEffect(() => {
		if (userId) {
			if (filter) {
				getUserTournaments(userId, filter)
			} else {
				getUserTournaments(userId)
			}
		} else {
			// console.log('filter = ', filter)

			// getTournaments('current')
			if (filter) {
				getTournaments(filter)
			} else {
				getTournaments()
			}
		}
	}, [])

	return tournamentLoading ? (
		<Spinner />
	) : (
		<>
			{!tournaments && tournament && tournament.approved === true && (
				<div className="tournaments-container">
					<TournamentItem tournament={tournament} />
				</div>
			)}
			{tournaments && (
				<div className="tournaments-container">
					{tournaments.map((tourn, index) => {
						let tournTypeColor = ''
						tournamentTypes.map(type => {
							if (type.tournTypeName === tourn.category) {
								tournTypeColor = type.colorValue
							}
						})

						if (isAdminArea) {
							return (
								<TournamentItem
									key={index}
									tournament={tourn}
									tournColor={tournTypeColor}
									isAdminArea={isAdminArea}
								/>
							)
						} else {
							return tourn.approved ? (
								<>
									<TournamentItem key={index} tournament={tourn} tournColor={tournTypeColor} />
								</>
							) : (
								<>
									<TournamentItem
										key={index}
										tournament={tourn}
										tournColor={tournTypeColor}
										awaitingApproval={true}
									/>
								</>
							)
						}
					})}
				</div>
			)}
		</>
	)
}

Tournaments.propTypes = {
	getTournaments: PropTypes.func,
	tournament: PropTypes.object,
	getUserTournaments: PropTypes.func,
}

const mapStateToProps = state => ({
	tournament: state.tournament,
	auth: state.auth,
	tournamentTypes: state.admin.tournamentTypes ? state.admin.tournamentTypes : null,
})

export default connect(mapStateToProps, { getTournaments, getUserTournaments })(Tournaments)
