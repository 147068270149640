import React from 'react'
import InfoBox from '../Elements/InfoBox'

const InfoBoxes = () => {
	return (
		<>
			<h3>Info Boxes</h3>
			<h4>Solid</h4>
			<InfoBox variant="primary">
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox variant="secondary">
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox variant="success">
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox variant="warning">
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox variant="danger">
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox variant="info">
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox variant="light">
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox variant="dark">
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<pre>
				<code>
					{`import InfoBox from '../Layout/Elements/InfoBox'
//////////////////////////////////////////////////////////////////

<InfoBox variant="primary">{children}</InfoBox>

<InfoBox variant="primary">
	<p>
		Tournament Owners have all the features available as a standard user but can also create and list
		tournaments on FindFinshingTournaments.com
	</p>
</InfoBox>

//////////////////////////////////////////////////////////////////
// Variants
//////////////////////////////////////////////////////////////////
variant="primary"
variant="secondary"
variant="success"
variant="warning"
variant="danger"
variant="info"
variant="light"
variant="dark"
				`}
				</code>
			</pre>

			<h4>Outline</h4>

			<InfoBox variant="primary" outline={true}>
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox variant="secondary" outline={true}>
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox variant="success" outline={true}>
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox variant="warning" outline={true}>
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox variant="danger" outline={true}>
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox variant="info" outline={true}>
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox variant="light" outline={true}>
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox variant="dark" outline={true}>
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<pre>
				<code>
					{`import InfoBox from '../Layout/Elements/InfoBox'
//////////////////////////////////////////////////////////////////

<InfoBox variant="primary" outline={true}>{children}</InfoBox>

<InfoBox variant="primary" outline={true}>
	<p>
		Tournament Owners have all the features available as a standard user but can also create and list
		tournaments on FindFinshingTournaments.com
	</p>
</InfoBox>

//////////////////////////////////////////////////////////////////
// Variants
//////////////////////////////////////////////////////////////////
variant="primary"
variant="secondary"
variant="success"
variant="warning"
variant="danger"
variant="info"
variant="light"
variant="dark"
				`}
				</code>
			</pre>

			<h4>Note Style</h4>

			<InfoBox isNote variant="primary">
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox isNote variant="secondary">
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox isNote variant="success">
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox isNote variant="warning">
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox isNote variant="danger">
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox isNote variant="info">
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox isNote variant="light">
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<InfoBox isNote variant="dark">
				<p>
					Tournament Owners have all the features available as a standard user but can also create and list
					tournaments on FindFinshingTournaments.com
				</p>
			</InfoBox>
			<pre>
				<code>
					{`import InfoBox from '../Layout/Elements/InfoBox'
//////////////////////////////////////////////////////////////////

<InfoBox isNote variant="primary">{children}</InfoBox>

<InfoBox isNote variant="primary" outline={true}>
	<p>
		Tournament Owners have all the features available as a standard user but can also create and list
		tournaments on FindFinshingTournaments.com
	</p>
</InfoBox>

//////////////////////////////////////////////////////////////////
// Variants
//////////////////////////////////////////////////////////////////
variant="primary"
variant="secondary"
variant="success"
variant="warning"
variant="danger"
variant="info"
variant="light"
variant="dark"
				`}
				</code>
			</pre>
		</>
	)
}

export default InfoBoxes
