import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import TournamentTypesForm from '../forms/tournament/TournamentTypesForm'
import Button from '../Layout/Elements/Button'
import { removeTournamentType } from '../../actions/admin'

import { SHOW_ADMIN_EDIT_TOURNAMENT_TYPE_FORM, HIDE_ADMIN_EDIT_TOURNAMENT_TYPE_FORM } from '../../actions/types'

const TournType = ({ type, showEditTournamentTypeForm, tournamentTypeLoading, removeTournamentType }) => {
	const dispatch = useDispatch()
	const [showEditForm, setShowEditForm] = useState(false)
	const { tournTypeName, colorValue } = type

	useEffect(() => {
		if (tournamentTypeLoading) {
			setShowEditForm(false)
		}
	}, [tournamentTypeLoading])

	const handleEditShowForm = () => {
		if (showEditTournamentTypeForm && !showEditForm) {
			// console.log('1 is open already')
		} else {
			if (!showEditForm) {
				setShowEditForm(true)
				dispatch({ type: SHOW_ADMIN_EDIT_TOURNAMENT_TYPE_FORM })
			} else {
				setShowEditForm(false)
				dispatch({ type: HIDE_ADMIN_EDIT_TOURNAMENT_TYPE_FORM })
			}
		}
	}

	const handleRemoveItem = () => {
		removeTournamentType({ tournTypeName: tournTypeName })
	}

	const firstComma = colorValue.indexOf(',')
	const bgcolorValue = colorValue.slice(0, firstComma + 1) + '100%, 97%)'

	return (
		<div
			className="tourn-type-item-container"
			style={{ backgroundColor: bgcolorValue, border: '1px solid ' + colorValue }}
		>
			<div className="tourn-type-item">
				<div className="tourn-type-color-tile">
					<span style={{ backgroundColor: colorValue }}></span>
				</div>
				{/* <div className="tourn-type-name" style={{ color: colorValue }}> */}
				<div className="tourn-type-name">{tournTypeName}</div>
				<div className="tourn-type-color-value">{colorValue}</div>

				<div className="edit-actions">
					<Button variant="info" label="Edit" size="small" onClick={() => handleEditShowForm()} />
					<Button
						variant="danger"
						iconOnly={true}
						icon="times-octagon"
						iconType="fas"
						size="small"
						onClick={() => handleRemoveItem()}
					/>
				</div>
			</div>
			{showEditForm && showEditTournamentTypeForm && (
				<>
					<TournamentTypesForm edit={true} name={tournTypeName} value={colorValue} color={colorValue} />
				</>
			)}
		</div>
	)
}

TournType.propTypes = {
	showEditTournamentTypeForm: PropTypes.bool,
	tournamentTypeLoading: PropTypes.bool,
	removeTournamentType: PropTypes.func,
}

const mapStateToProps = state => ({
	showEditTournamentTypeForm: state.admin.showEditTournamentTypeForm,
	tournamentTypeLoading: state.admin.tournamentTypeLoading,
})

export default connect(mapStateToProps, { removeTournamentType })(TournType)
