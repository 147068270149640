import React, { useState } from 'react'
import Headings from './Headings'
import BlockElem from './BlockElem'
import TextElem from './TextElem'
import ListElem from './ListElem'
import Tables from './Tables'
// import MediaElem from './MediaElem'
import FormElem from './FormElem'
import Colors from './Colors'
import TestArea from './TestArea'
import Buttons from './Buttons'
import InfoBoxes from './InfoBoxes'
import InnerStructure from '../../Layout/InnerStructure'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Styleguide = () => {
	const [heading, setHeading] = useState(false)
	const [buttons, setButtons] = useState(false)
	const [blockelem, setBlockelem] = useState(false)
	const [textelem, setTextelem] = useState(false)
	const [listelem, setListelem] = useState(false)
	const [tables, setTables] = useState(false)
	const [colors, setColors] = useState(true)
	const [formelem, setFormelem] = useState(false)
	const [infoBoxes, setInfoBoxes] = useState(false)
	const [testArea, setTestArea] = useState(false)

	return (
		<InnerStructure className="styleguide">
			<h1 className="view-title">Style Guide</h1>

			<div className="sg-sections-container">
				<div className="left-column elem-display-select sidebar">
					<ul>
						<li className="title-elems" onClick={() => setHeading(!heading)}>
							<FontAwesomeIcon icon={{ prefix: 'fad', iconName: !heading ? 'eye-slash' : 'eye' }} />{' '}
							Headings
						</li>
						<li className="button-elems" onClick={() => setButtons(!buttons)}>
							<FontAwesomeIcon icon={{ prefix: 'fad', iconName: !buttons ? 'eye-slash' : 'eye' }} />{' '}
							Buttons
						</li>
						<li className="block-elems" onClick={() => setBlockelem(!blockelem)}>
							<FontAwesomeIcon icon={{ prefix: 'fad', iconName: !blockelem ? 'eye-slash' : 'eye' }} />{' '}
							Block Elements
						</li>
						<li className="text-elems" onClick={() => setTextelem(!textelem)}>
							<FontAwesomeIcon icon={{ prefix: 'fad', iconName: !textelem ? 'eye-slash' : 'eye' }} /> Text
							Elements
						</li>
						<li className="lists-elems" onClick={() => setListelem(!listelem)}>
							<FontAwesomeIcon icon={{ prefix: 'fad', iconName: !listelem ? 'eye-slash' : 'eye' }} /> List
							Elements
						</li>
						<li className="tables-elems" onClick={() => setTables(!tables)}>
							<FontAwesomeIcon icon={{ prefix: 'fad', iconName: !tables ? 'eye-slash' : 'eye' }} /> Table
							Elements
						</li>
						<li className="color-elems" onClick={() => setColors(!colors)}>
							<FontAwesomeIcon icon={{ prefix: 'fad', iconName: !colors ? 'eye-slash' : 'eye' }} /> Colors
						</li>
						<li className="form-elems" onClick={() => setFormelem(!formelem)}>
							<FontAwesomeIcon icon={{ prefix: 'fad', iconName: !formelem ? 'eye-slash' : 'eye' }} /> Form
							Elements
						</li>
						<li className="form-elems" onClick={() => setInfoBoxes(!infoBoxes)}>
							<FontAwesomeIcon icon={{ prefix: 'fad', iconName: !infoBoxes ? 'eye-slash' : 'eye' }} />{' '}
							Info Boxes
						</li>
						<li className="form-elems" onClick={() => setTestArea(!testArea)}>
							<FontAwesomeIcon icon={{ prefix: 'fad', iconName: !testArea ? 'eye-slash' : 'eye' }} /> Test
							Area
						</li>
					</ul>
				</div>

				<div className="right-column elem-display">
					{heading ? <Headings /> : null}
					{buttons ? <Buttons /> : null}
					{blockelem ? <BlockElem /> : null}
					{textelem ? <TextElem /> : null}
					{listelem ? <ListElem /> : null}
					{tables ? <Tables /> : null}
					{colors ? <Colors /> : null}
					{formelem ? <FormElem /> : null}
					{infoBoxes ? <InfoBoxes /> : null}
					{testArea ? <TestArea /> : null}
				</div>
			</div>
		</InnerStructure>
	)
}

export default Styleguide
