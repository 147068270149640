import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TweenMax } from 'gsap/all'

// This is the Alerts display visual element
// Alerts work by setting the alert message via Redux then this element takes the alert and populates it into the html

const Alert = ({ alerts }) => {
	const myElement = useRef()

	useEffect(() => {
		if (alerts !== null && myElement.current) {
			TweenMax.to(myElement.current, 0.5, { x: '-120%' })

			setTimeout(() => {
				TweenMax.to(myElement.current, 0.5, { x: '0' })
			}, 3000)
		}
	}, [alerts])

	return (
		alerts !== null &&
		alerts.length > 0 &&
		alerts.map(alert => (
			<div key={alert.id} ref={myElement} className={`alert alert-${alert.alertType}`}>
				<div className="icon-wrapper">
					{(function() {
						switch (alert.alertType) {
							case 'danger':
								return <FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'exclamation-triangle' }} />
							case 'success':
								return <FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'thumbs-up' }} />
							case 'info':
								return <FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'info-circle' }} />
							default:
								return null
						}
					})()}
				</div>
				<div className="alert-message">{alert.msg}</div>
			</div>
		))
	)
}

Alert.propTypes = {
	alerts: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
	alerts: state.alert,
})

export default connect(mapStateToProps)(Alert)
