import {
	USER_ACCOUNT_UPGRADED,
	USER_SAVED_TOURNAMENT,
	USER_SAVED_TOURNAMENT_ERROR,
	USER_GET_SAVED_TOURNAMENT_ID,
	USER_GET_SAVED_TOURNAMENT_ID_ERROR,
	USER_REMOVED_TOURN_FROM_SAVED,
	USER_REMOVED_TOURN_FROM_SAVED_ERROR,
	USER_GET_SAVED_TOURNAMENT_ALL_DATA,
	USER_GET_SAVED_TOURNAMENT_ALL_DATA_ERROR,
	LOGOUT,
} from '../actions/types'

const initialState = {
	savedTournamnetID: null,
	savedTournAllData: null,
	userItemsLoading: true,
}

export default function(state = initialState, action) {
	const { type, payload } = action

	switch (type) {
		case USER_SAVED_TOURNAMENT:
		case USER_REMOVED_TOURN_FROM_SAVED:
		case USER_GET_SAVED_TOURNAMENT_ID:
			// console.log(' -> reducers/user.js -------------------------------')
			// console.log('payload = ', payload)
			return {
				savedTournamnetID: payload,
			}
		case USER_GET_SAVED_TOURNAMENT_ALL_DATA:
			return {
				savedTournAllData: payload,
				userItemsLoading: false,
			}

		case USER_SAVED_TOURNAMENT_ERROR:
		case USER_REMOVED_TOURN_FROM_SAVED_ERROR:
		case USER_GET_SAVED_TOURNAMENT_ID_ERROR:
		case USER_GET_SAVED_TOURNAMENT_ALL_DATA_ERROR:
			return {
				...state,
			}
		// case LOGOUT:
		// 	return {
		// 		savedTournamnetID: null,
		// 		savedTournAllData: null,
		// 		userItemsLoading: true,
		// 	}

		default:
			return state
	}
}
