import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import RegisterForm from '../forms/registration/RegisterForm'
import LoginForm from '../forms/registration/LoginForm'
import { getCurrentProfile } from '../../actions/profile'
import { prototype } from 'aws-sdk/clients/sesv2'
import Slider from 'react-rangeslider'
import Button from '../Layout/Elements/Button'
import Checkbox from '../forms/Checkbox'
import Spinner from '../effects/loading/Spinner'
import InfoBox from '../Layout/Elements/InfoBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Stripes from '../Layout/Elements/BG/Stripes'
import { TimelineMax } from 'gsap/all'
import LogoTitle from '../Layout/Elements/LogoTitle'
import { tournAlertSignUp } from '../../actions/mail'
import Validation from '../Layout/Elements/Validation'
import { TOURNAMENT_ALERT_SIGNUP_CLOSE } from '../../actions/types'

const TournAlertSignUp = ({
	authLoading,
	isAuthenticated,
	auth,
	user,
	getCurrentProfile,
	profile,
	show,
	tournAlertSignUp,
	mail,
	tournAlertRegistered,
}) => {
	const dispatch = useDispatch()

	const backdrop = useRef()
	const panel = useRef()
	const closeBtn = useRef()

	let tlIn
	let tournAlerts

	const [displayLogin, setDisplayLogin] = useState(true)
	const [displayRegister, setDisplayRegister] = useState(false)
	const [miles, setMiles] = useState(10)
	// Validation
	const [nameError, setNameError] = useState(false)
	const [zipError, setZipError] = useState(false)
	const [emailApprovalCheckedError, setEmailApprovalCheckedError] = useState(false)

	// const [scrollPosition, setScrollPosition] = useState(0)

	const toggleLoginRegistration = () => {
		setDisplayLogin(!displayLogin)
		setDisplayRegister(!displayRegister)
	}

	const [formData, setFormData] = useState({
		_id: '',
		name: '',
		// email: '',
		zip: '',
		distance: miles,
		emailApprovalChecked: false,
	})

	const { zip } = formData

	if (user) {
		tournAlerts = user.tournAlerts
	}

	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

	const onChangeCheckbox = e => {
		if (emailApprovalCheckedError) {
			setEmailApprovalCheckedError(false)
		}
		setFormData({ ...formData, [e.target.name]: e.target.checked ? true : false })
	}

	const onRangeChange = miles => {
		setMiles(miles)
		setFormData({ ...formData, distance: miles })
	}

	useEffect(() => {
		if (user) {
			// setFormData({ ...formData, name: user.name, email: user.email, _id: user._id })
			setFormData({ ...formData, name: user.name, _id: user._id })
		}
	}, [user])

	useEffect(() => {
		if (profile) {
			setFormData({ ...formData, zip: profile.zip })
		}
	}, [profile])

	useEffect(() => {
		if (isAuthenticated) {
			getCurrentProfile()
		}
	}, [isAuthenticated])

	useEffect(() => {
		tlIn = new TimelineMax()
		tlIn.to(backdrop.current, { duration: 0.45, opacity: 1 })
		// tlIn.to(panel.current, { duration: 0.25, opacity: 1 })
		tlIn.to(panel.current, { duration: 0.35, y: 0 }, '+=0.25')
	}, [backdrop.current, panel.current])

	useEffect(() => {
		// setScrollPosition(window.scrollY)
		document.body.classList.add('modal-open')
		// document.body.style.position = 'fixed'
		// document.body.style.top = `-${window.scrollY}px`
		tlIn.play()
	}, [show])

	const setCloseSignUp = () => {
		setEmailApprovalCheckedError(false)

		const tlOut = new TimelineMax({
			onComplete: function() {
				document.body.classList.remove('modal-open')
				// const scrollY = document.body.style.top
				// document.body.style.position = ''
				// document.body.style.top = ''
				// window.scrollTo(0, parseInt(scrollY || '0') * -1)
				dispatch({
					type: TOURNAMENT_ALERT_SIGNUP_CLOSE,
				})
			},
		})
		tlOut.to(panel.current, { duration: 0.35, y: '-100vh' }, '+=0.15')
		tlOut.to(backdrop.current, { duration: 0.45, opacity: 0 }, '+=0.25')
		tlOut.play()
	}

	const onSubmit = e => {
		e.preventDefault()
		// nameError
		// zipError
		// emailApprovalCheckedError
		if (!tournAlertRegistered && !formData.emailApprovalChecked) {
			setEmailApprovalCheckedError(true)
		} else {
			// If user is already registered and is updating location or range ->
			// -> Set emailApprovalChecked to true ... this prevents the default value of false from being passed in
			if (tournAlertRegistered) {
				formData.emailApprovalChecked = true
			}

			tournAlertSignUp(formData)
		}
	}

	return (
		<>
			<div className={`backdrop${show ? ' ' + 'active' : ' ' + 'inactive'}`} ref={backdrop}>
				<div className="tournament-alert-pos-wrapper" ref={panel}>
					<div className="content-wrapper">
						<div className="stripes-container bg-blur">
							<Stripes />
						</div>
						<div className="tournament-alert-signup-container">
							<div className="close-button-container">
								<div className="close-button" ref={closeBtn} onClick={() => setCloseSignUp()}>
									<FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'times-circle' }} />
								</div>
							</div>
							{/* If Not Logged In */}
							{authLoading && (
								<>
									<Spinner />
								</>
							)}
							{!authLoading && isAuthenticated && !mail.tournAlertSignUpSuccess && (
								<>
									<InfoBox>
										{!tournAlertRegistered && (
											<p>
												Be the first to find out about <span className="emphasis">NEW</span>{' '}
												tournaments in your area with FindFishingTournaments.com's Tournament
												Alert Platform!
											</p>
										)}
										{tournAlertRegistered && (
											<p>
												Looks Like you have already signed up to recieve
												FindFishingTournaments.com's Tournament Alerts. You can update your
												settings in the section below.
											</p>
										)}
									</InfoBox>
								</>
							)}
							{!isAuthenticated && !authLoading && (
								<>
									<InfoBox variant="primary">
										<p>To Sign up for Tournament Alerts, please log in to your account.</p>
									</InfoBox>
									{displayLogin && (
										<>
											<LoginForm />
										</>
									)}
								</>
							)}
							{/* If Logged In */}
							{isAuthenticated && user && !mail.tournAlertSignUpSuccess && (
								<form className="form sign-up-form" onSubmit={e => onSubmit(e)}>
									{/* <h3 className="form-title">Tournament Alerts</h3> */}
									<LogoTitle tag="h2" title={`Tournament Alerts`} />
									<fieldset className="form-section">
										{/* Input User Zip */}
										<h4 className="form-section-title">Enter Zip Code</h4>

										<div className="form-group profile-zip">
											{/* If Profile has Zip */}
											{/* Input - Prepopulate with User Zip */}
											<input
												type="zip"
												placeholder="Zip"
												name="zip"
												value={zip}
												onChange={e => onChange(e)}
											/>
										</div>
										{/* Input Alert Radius */}
										<div className="form-section range">
											<h4 className="form-section-title">Select Search Range</h4>
											<div className="form-group">
												<label htmlFor="distance">
													<span className="label-value">{miles} Miles</span>
												</label>
												<Slider
													name="distance"
													max={500}
													step={5}
													value={miles}
													orientation="horizontal"
													onChange={onRangeChange}
												/>
											</div>
										</div>
										{/* <h4 className="form-section-title">Enter Email Address</h4>
										<div className="form-group email">
											<input
												type="email"
												placeholder="email"
												name="email"
												value={email}
												onChange={e => onChange(e)}
											/>
										</div> */}
									</fieldset>
									{/* Checkbox - Apporove add to email list */}
									{!tournAlertRegistered && emailApprovalCheckedError && (
										<>
											<Validation
												errMsg="Checkbox not checked."
												solution="In order to sign up, you must check the box acknowledging you have read our privacy policy and email disclaimer."
											/>
										</>
									)}
									{!tournAlertRegistered && (
										<div
											className={`disclaimer ${
												emailApprovalCheckedError ? ' v-error-elem' : null
											}`}
										>
											<p>
												By signing up for Email, you consent to receive electronic
												communications, including event notices and other materials from
												Findfishingtournaments.com. Please read our privacy policy and full
												email disclaimer{' '}
												<Link to="/privacy-policies" target="_blank">
													Click HERE
												</Link>
											</p>
											<div className="form-group">
												<Checkbox
													name="emailApprovalChecked"
													label="I have read the full privacy policy, email disclaimer. I agree to recieve emails, SIGN ME UP!"
													value="email-agreed"
													isDisabled={false}
													onChange={e => onChangeCheckbox(e)}
												/>
											</div>
										</div>
									)}
									<div className="form-section submit">
										<div className="form-group submit-btn">
											<Button
												variant="primary"
												label={tournAlertRegistered ? 'Update' : 'Sign Up'}
												size="large"
											/>
										</div>
									</div>
								</form>
							)}
							{isAuthenticated && mail.tournAlertSignUpSuccess && (
								<div className="sign-up-success">
									<InfoBox className="sign-up-success__alert" iconName="thumbs-up">
										<h2>Success!</h2>
										<p>
											You are all set up. You will recieve an email when new tournaments are added
											within your area.
										</p>
									</InfoBox>
									<Button variant="primary" label={'Go Back'} onClick={() => setCloseSignUp()} />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

TournAlertSignUp.propTypes = {
	auth: PropTypes.object,
	isAuthenticated: PropTypes.bool,
	user: PropTypes.object,
	getCurrentProfile: PropTypes.func.isRequired,
	profile: PropTypes.object,
	authLoading: PropTypes.bool,
	tournAlertSignUp: PropTypes.func.isRequired,
	tournAlertRegistered: PropTypes.bool,
}

const mapStateToProps = state => ({
	auth: state.auth,
	isAuthenticated: state.auth.isAuthenticated,
	authLoading: state.auth.authLoading,
	user: state.auth.user,
	profile: state.profile.profile,
	zip: state.profile.profile ? state.profile.profile.zip : null,
	email: state.auth.user ? state.auth.user.email : null,
	mail: state.mail,
	tournAlertRegistered: state.mail.tournAlertRegistered,
})

export default connect(mapStateToProps, { getCurrentProfile, tournAlertSignUp })(TournAlertSignUp)
