import React from 'react'
import GoogleMapReact from 'google-map-react'
import MapMarker from './MapMarker'
import { mapStyles } from './mapStyling'

const MyMapComponentSingle = ({ tournament, type }) => {
	const mapCenter = { lat: tournament.location.lat, lng: tournament.location.lng }

	const mapZoom = 6

	const createMapOptions = maps => {
		return mapStyles
	}

	return (
		<div className="map-container">
			<GoogleMapReact
				bootstrapURLKeys={{
					key: `AIzaSyCpKIZfNotUD9aZdeASGPwhvoaebqtlxs4`,
				}}
				defaultCenter={mapCenter}
				defaultZoom={mapZoom}
				options={createMapOptions}
			>
				<MapMarker tourn={tournament} lat={tournament.location.lat} lng={tournament.location.lng} />
			</GoogleMapReact>
		</div>
	)
}

export default MyMapComponentSingle
