import React from 'react'
import { CardElement } from 'react-stripe-elements'

const Card = () => {
	const style = {
		base: {
			color: '#32325d',
			fontFamily: '"Futura PT", Helvetica, sans-serif',
			fontWeight: '600',
			fontSmoothing: 'antialiased',
			fontSize: '20px',
			'::placeholder': {
				color: '#757575',
			},
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a',
		},
	}
	return (
		<div>
			<h3>Card Details</h3>
			<CardElement style={style} />
			{/* <div id="card-errors" role="alert"></div> */}
		</div>
	)
}

export default Card
