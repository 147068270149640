import React from 'react'
import Button from '../Layout/Elements/Button'

const Profile = ({ user, profile }) => {
	return (
		<div className="user-profile profile-display">
			<div className="profile-data">
				<dl>
					<dt>Name:</dt>
					<dd>{user.name}</dd>
				</dl>
				<dl>
					<dt>Location:</dt>
					<dd>{profile.zip}</dd>
				</dl>
				<dl>
					<dt>Bio:</dt>
					<dd>{profile.bio}</dd>
				</dl>
			</div>
			{/* <DashboardActions /> */}
		</div>
	)
}

export default Profile
