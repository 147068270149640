import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { EditorState } from 'draft-js'
import TournamentOptions from '../../Layout/Tournament/TournamentOption'
import Payments from '../../payments/Payments'
import CreateTournament from '../../forms/tournament/CreateTournament'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TimelineMax, CSSPlugin } from 'gsap/all'
import {
	TOURNAMENT_SUBMISSION_COMPLETE,
	TOURNAMENT_UPDATE_LEVEL,
	HIDE_TOURNAMENT_LEVEL_OPTIONS,
	SHOW_CREATE_TOURNAMENT_FORM,
	PAYMENT_LEVEL_SELECTED,
	SHOW_PAYMENT_MODAL,
	HIDE_PAYMENT_MODAL,
} from '../../../actions/types'

const ListTournament = ({
	paymentLevel,
	paymentComplete,
	showTournamentOptions,
	showCreateTournamentForm,
	showPaymentModal,
}) => {
	const modal = useRef()
	const paymentContainer = useRef()
	const plugins = [CSSPlugin]
	const dispatch = useDispatch()
	const [editorState, setEditorState] = useState(EditorState.createEmpty())
	const tournOpt1 = ['Tournament Name', 'Tournament Date', 'Tournament Location', 'Map View', 'Description']

	const tournOpt2 = ['All of Option 1', 'PLUS+', 'Add a Tournament Logo', 'List a Sponsor']

	const tournOpt3 = [
		'All Basic Features',
		'Add a Tournament Logo',
		'Add a Tournament Media File (PDF)',
		'List Sponsors (Unlimited Amount)',
		'- Add Logos for Sponsors',
		'- Link to Sponsors Websites',
	]

	const tournObj1 = {
		name: 'Option 1',
		level: 'free',
		price: 0,
		options: ['Tournament Name', 'Tournament Date', 'Tournament Location', 'Map View', 'Description'],
	}

	// --------------------------------------------------------
	// DO NOT REMOVE --- will reinstate at a later date
	// --------------------------------------------------------
	// const tournObj2 = {
	// 	name: 'Option 2',
	// 	level: 'tl_2',
	// 	price: 6.99,
	// 	options: [
	// 		'Tournament Name',
	// 		'Tournament Date',
	// 		'Tournament Location',
	// 		'Map View',
	// 		'Description',
	// 		'Add a Tournament Logo',
	// 		'List a Sponsor',
	// 	],
	// }

	const tournObj3 = {
		name: 'Premium',
		level: 'tl_3',
		price: 9.99,
		options: [
			'Tournament Name',
			'Tournament Date',
			'Tournament Location',
			'Map View',
			'Description',
			'Add a Tournament Logo',
			'Add a Tournament Media File (PDF)',
			'List Sponsors (Unlimited Amount)',
			'Add Logos for Sponsors',
			'Link to Sponsors Websites',
		],
	}
	const [paymentLevelSelected, setPaymentLevelSelected] = useState(false)

	const [tournSettings, setTournamentSettings] = useState({
		tournLevel: 'free',
		tournPrice: 0,
		name: 'Basic',
		options: tournObj1.options,
	})

	const handleCreateTournament = tourn => {
		setTournamentSettings({
			tournLevel: tourn.level,
			tournPrice: tourn.price,
			name: tourn.name,
			options: tourn.options,
		})

		if (paymentComplete) {
			dispatch({
				type: HIDE_TOURNAMENT_LEVEL_OPTIONS,
			})
			dispatch({
				type: SHOW_CREATE_TOURNAMENT_FORM,
			})
		} else {
			// setShowModal(true)
			dispatch({ type: SHOW_PAYMENT_MODAL })
			// setPaymentLevelSelected(true)
			dispatch({ type: PAYMENT_LEVEL_SELECTED })
		}
	}

	const handleCreateTournamentFree = tourn => {
		setTournamentSettings({
			tournLevel: tourn.level,
			tournPrice: tourn.price,
			name: tourn.name,
			options: tourn.options,
		})
		dispatch({ type: HIDE_TOURNAMENT_LEVEL_OPTIONS })
		dispatch({ type: SHOW_CREATE_TOURNAMENT_FORM })
		dispatch({ type: PAYMENT_LEVEL_SELECTED })
		// dispatch({ type: TOURNAMENT_SUBMISSION_COMPLETE })
		dispatch({ type: TOURNAMENT_UPDATE_LEVEL, payload: tourn.level })
	}

	// ----------------------------------------------------------------
	// ----------------------------------------------------------------
	// ----------------------------------------------------------------
	// ----------------------------------------------------------------
	// Animations
	// ----------------------------------------------------------------
	// ----------------------------------------------------------------
	// ----------------------------------------------------------------
	// ----------------------------------------------------------------

	// modalActive is set after the opening animation is complete -> indicates the modal is visable and ready to use
	const [modalActive, setModalActive] = useState(false)
	// hideModal is the trigger to start the closing animation
	const [hideModalOnClick, setHideModalOnClick] = useState(false)
	const [paymentCompleteModalClosed, setPaymentCompleteModalClosed] = useState(false)

	// Create the opening animation timeline
	const modalDisplayTL = new TimelineMax({
		paused: true,
		onComplete: function() {
			setModalActive(true)
		},
	})

	// Create the closing animation timeline
	const modalDisplayTL2 = new TimelineMax({
		paused: true,
		onComplete: function() {
			if (paymentComplete) {
				setPaymentCompleteModalClosed(true)
				dispatch({ type: HIDE_TOURNAMENT_LEVEL_OPTIONS })
				dispatch({ type: SHOW_CREATE_TOURNAMENT_FORM })
			}
			setHideModalOnClick(false)
			dispatch({ type: HIDE_PAYMENT_MODAL })
		},
	})

	//
	useEffect(() => {
		if (showPaymentModal === true && !paymentComplete) {
			modalDisplayTL.to(modal.current, 0.4, { opacity: 1 })
			modalDisplayTL.to(paymentContainer.current, 0.5, { y: 0, opacity: 1, ease: 'back.out(2)' })
			modalDisplayTL.play()
		}
	})

	useEffect(() => {
		if (hideModalOnClick) {
			// console.log('hideModal is true')
			modalDisplayTL2.to(modal.current, 0.6, { opacity: 0 })
			modalDisplayTL2.to(paymentContainer.current, 0.5, { y: '-100vh', opacity: 0, ease: 'back.in(2)' })
			modalDisplayTL2.play()
		}
	}, [hideModalOnClick])

	useEffect(() => {
		if (paymentComplete) {
			// TL2
			modalDisplayTL2.to(modal.current, 0.6, { opacity: 0 })
			modalDisplayTL2.to(paymentContainer.current, 0.5, { y: '-100vh', opacity: 0, ease: 'back.in(2)' })
			modalDisplayTL2.play()
		}
	}, [paymentComplete])

	// END Animations
	// ----------------------------------------------------------------

	return (
		<>
			<div className="create-tournament-container">
				<h3>List a Tournament {paymentLevel === 'free' && paymentLevelSelected ? 'for Free' : null}:</h3>

				{showTournamentOptions && !paymentComplete && (
					<div className="create-tournament-options">
						<TournamentOptions
							title="Basic"
							optionsList={tournOpt1}
							price="Free"
							tournType={tournObj1.level}
							onClick={() => handleCreateTournamentFree(tournObj1)}
						/>
						{/* <TournamentOptions
							title="Option 2"
							optionsList={tournOpt2}
							price={`$${tournObj2.price}`}
							tournType={tournObj2.level}
							onClick={() => handleCreateTournament(tournObj2)}
						/> */}
						<TournamentOptions
							title="Premium"
							optionsList={tournOpt3}
							price={`$${tournObj3.price}`}
							tournType={tournObj3.level}
							onClick={() => handleCreateTournament(tournObj3)}
						/>
					</div>
				)}
				{/* {showTournamentOptions && paymentComplete && paymentLevel === 'tl_2' && (
					<div className="create-tournament-options">
						<TournamentOptions
							title="Option 2"
							optionsList={tournOpt2}
							price={`Already Paid`}
							tournType={tournObj2.level}
							onClick={() => handleCreateTournament(tournObj2)}
						/>
					</div>
				)}
				{showTournamentOptions && paymentComplete && paymentLevel === 'tl_3' && (
					<div className="create-tournament-options">
						<TournamentOptions
							title="Option 3"
							optionsList={tournOpt3}
							price={`Already Paid`}
							tournType={tournObj3.level}
							onClick={() => handleCreateTournament(tournObj3)}
						/>
					</div>
				)} */}
				{showPaymentModal && !paymentCompleteModalClosed && (
					<div className="modal">
						<div className="modal-backdrop" ref={modal} onClick={() => setHideModalOnClick(true)}></div>

						<div className="modal-panel payment-container" ref={paymentContainer}>
							<div className="close-modal" onClick={() => setHideModalOnClick(true)}>
								<FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'times-circle' }} />
							</div>
							{/* {console.log(tournSettings)} */}
							<Payments orderData={tournSettings} />
						</div>
					</div>
				)}

				{showCreateTournamentForm && (
					<CreateTournament editorState={editorState} setEditorState={setEditorState} />
				)}
			</div>
		</>
	)
}

ListTournament.propTypes = {}

const mapStateToProps = state => ({
	paymentLevel: state.payment.paymentLevel,
	paymentComplete: state.payment.paymentComplete,
	showTournamentOptions: state.tournament.showTournamentOptions,
	showCreateTournamentForm: state.tournament.showCreateTournamentForm,
	showPaymentModal: state.payment.showPaymentModal,
})

export default connect(mapStateToProps)(ListTournament)
