import {
	SHOW_DASHBOARD_LANDING,
	SHOW_TOURNAMENT_SEARCH,
	SHOW_LIST_A_TOURNAMENT,
	SHOW_VIEW_PROFILE,
	SHOW_VIEW_MY_TOURNAMENTS,
	SHOW_MY_TOURNAMENT_WATCHLIST,
} from '../actions/types'

const initialState = {
	showDashboardLanding: true,
	showTournamentSearch: false,
	showListATournament: false,
	showViewProfile: false,
	showViewMyTournaments: false,
	showMyTournamentWatchlist: false,
	error: {},
}

export default function(state = initialState, action) {
	const { type, payload } = action

	// console.log('payload = ', payload)

	switch (type) {
		case SHOW_DASHBOARD_LANDING:
			return {
				...state,
				showDashboardLanding: true,
				showTournamentSearch: false,
				showListATournament: false,
				showViewProfile: false,
				showViewMyTournaments: false,
				showMyTournamentWatchlist: false,
			}
		case SHOW_TOURNAMENT_SEARCH:
			return {
				...state,
				showDashboardLanding: false,
				showTournamentSearch: true,
				showListATournament: false,
				showViewProfile: false,
				showViewMyTournaments: false,
				showMyTournamentWatchlist: false,
			}
		case SHOW_LIST_A_TOURNAMENT:
			return {
				...state,
				showDashboardLanding: false,
				showTournamentSearch: false,
				showListATournament: true,
				showViewProfile: false,
				showViewMyTournaments: false,
				showMyTournamentWatchlist: false,
			}
		case SHOW_VIEW_PROFILE:
			return {
				...state,
				showDashboardLanding: false,
				showTournamentSearch: false,
				showListATournament: false,
				showViewProfile: true,
				showViewMyTournaments: false,
				showMyTournamentWatchlist: false,
			}
		case SHOW_VIEW_MY_TOURNAMENTS:
			return {
				...state,
				showDashboardLanding: false,
				showTournamentSearch: false,
				showListATournament: false,
				showViewProfile: false,
				showViewMyTournaments: true,
				showMyTournamentWatchlist: false,
			}
		case SHOW_MY_TOURNAMENT_WATCHLIST:
			return {
				...state,
				showDashboardLanding: false,
				showTournamentSearch: false,
				showListATournament: false,
				showViewProfile: false,
				showViewMyTournaments: false,
				showMyTournamentWatchlist: true,
			}
		default:
			return state
	}
}
