import React from 'react'
import InnerStructure from '../Layout/InnerStructure'

const PrivacyPolicies = () => {
	return (
		<InnerStructure className="policies">
			<h1>Privacy Policy</h1>
			<p className="indent">
				Protecting your private information is our priority. This Statement of Privacy applies to
				findfishingtournaments.com and Seek Outdoors LLC and governs data collection and usage. For the purposes
				of this Privacy Policy, unless otherwise noted, all references to Seek Outdoors LLC include
				findfishingtournaments.com and FindFishingTournaments.com. The FindFishingTournaments.com website is a
				Search Engine site. By using the FindFishingTournaments.com website, you consent to the data practices
				described in this statement.
			</p>
			<section>
				<h3 className="section-title">Collection of your Personal Information</h3>
				<p className="indent">
					In order to better provide you with products and services offered on our Site,
					FindFishingTournaments.com may collect personally identifiable information, such as your:
				</p>
				<ul>
					<li>First and Last Name</li>
					<li>Mailing Address</li>
					<li>E-mail Address</li>
					<li>Phone Number</li>
				</ul>
				<p className="indent">
					If you purchase FindFishingTournaments.com's products and services, we collect billing and credit
					card information. This information is used to complete the purchase transaction.{' '}
				</p>
				<p className="indent">
					Please keep in mind that if you directly disclose personally identifiable information or personally
					sensitive data through FindFishingTournaments.com's public message boards, this information may be
					collected and used by others.{' '}
				</p>
				<p className="indent">
					We do not collect any personal information about you unless you voluntarily provide it to us.
					However, you may be required to provide certain personal information to us when you elect to use
					certain products or services available on the Site. These may include: (a) registering for an
					account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners;
					(c) signing up for special offers from selected third parties; (d) sending us an email message; (e)
					submitting your credit card or other payment information when ordering and purchasing products and
					services on our Site. To wit, we will use your information for, but not limited to, communicating
					with you in relation to services and/or products you have requested from us. We also may gather
					additional personal or non-personal information in the future.{' '}
				</p>
			</section>
			<section></section>
			<h3 className="section-title">Use of your Personal Information</h3>
			<p className="indent">
				FindFishingTournaments.com collects and uses your personal information to operate its website(s) and
				deliver the services you have requested.{' '}
			</p>
			<p className="indent">
				FindFishingTournaments.com may also use your personally identifiable information to inform you of other
				products or services available from FindFishingTournaments.com and its affiliates.{' '}
			</p>
			<h3 className="section-title">Sharing Information with Third Parties</h3>
			<p className="indent">
				FindFishingTournaments.com may sell, rent, or lease customer information to third parties for the
				following reasons:{' '}
			</p>
			<p className="indent">Advertising purposes... </p>
			<p className="indent">
				FindFishingTournaments.com may, from time to time, contact you on behalf of external business partners
				about a particular offering that may be of interest to you. In those cases, your unique personally
				identifiable information (e-mail, name, address, telephone number) is transferred to the third party.
				FindFishingTournaments.com may share data with trusted partners to help perform statistical analysis,
				send you email or postal mail, provide customer support, or arrange for deliveries. All such third
				parties are prohibited from using your personal information except to provide these services to
				FindFishingTournaments.com, and they are required to maintain the confidentiality of your information.{' '}
			</p>
			<p className="indent">
				FindFishingTournaments.com may disclose your personal information, without notice, if required to do so
				by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the
				law or comply with legal process served on FindFishingTournaments.com or the site; (b) protect and
				defend the rights or property of FindFishingTournaments.com; and/or (c) act under exigent circumstances
				to protect the personal safety of users of FindFishingTournaments.com, or the public.{' '}
			</p>
			<section>
				<h3 className="section-title">Tracking User Behavior</h3>
				<p className="indent">
					FindFishingTournaments.com may keep track of the websites and pages our users visit within
					FindFishingTournaments.com, in order to determine what FindFishingTournaments.com services are the
					most popular. This data is used to deliver customized content and advertising within
					FindFishingTournaments.com to customers whose behavior indicates that they are interested in a
					particular subject area.{' '}
				</p>
			</section>
			<section>
				<h3 className="section-title">Automatically Collected Information</h3>
				<p className="indent">
					Information about your computer hardware and software may be automatically collected by
					FindFishingTournaments.com. This information can include: your IP address, browser type, domain
					names, access times and referring website addresses. This information is used for the operation of
					the service, to maintain quality of the service, and to provide general statistics regarding use of
					the FindFishingTournaments.com website.{' '}
				</p>
			</section>
			<section>
				<h3 className="section-title">Use of Cookies</h3>
				<p className="indent">
					The FindFishingTournaments.com website may use "cookies" to help you personalize your online
					experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies
					cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to
					you, and can only be read by a web server in the domain that issued the cookie to you.{' '}
				</p>
				<p className="indent">
					One of the primary purposes of cookies is to provide a convenience feature to save you time. The
					purpose of a cookie is to tell the Web server that you have returned to a specific page. For
					example, if you personalize FindFishingTournaments.com pages, or register with
					FindFishingTournaments.com site or services, a cookie helps FindFishingTournaments.com to recall
					your specific information on subsequent visits. This simplifies the process of recording your
					personal information, such as billing addresses, shipping addresses, and so on. When you return to
					the same FindFishingTournaments.com website, the information you previously provided can be
					retrieved, so you can easily use the FindFishingTournaments.com features that you customized.{' '}
				</p>
				<p className="indent">
					You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies,
					but you can usually modify your browser setting to decline cookies if you prefer. If you choose to
					decline cookies, you may not be able to fully experience the interactive features of the
					FindFishingTournaments.com services or websites you visit.{' '}
				</p>
			</section>
			<section>
				<h3 className="section-title">Links</h3>
				<p className="indent">
					This website contains links to other sites. Please be aware that we are not responsible for the
					content or privacy practices of such other sites. We encourage our users to be aware when they leave
					our site and to read the privacy statements of any other site that collects personally identifiable
					information.{' '}
				</p>
				<h3 className="section-title">Security of your Personal Information</h3>
				<p className="indent">
					FindFishingTournaments.com secures your personal information from unauthorized access, use, or
					disclosure. FindFishingTournaments.com uses the following methods for this purpose:{' '}
				</p>
			</section>
			<section>
				<h3 className="section-title">SSL Protocol</h3>
				<p className="indent">
					When personal information (such as a credit card number) is transmitted to other websites, it is
					protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.{' '}
				</p>
				<p className="indent">
					We strive to take appropriate security measures to protect against unauthorized access to or
					alteration of your personal information. Unfortunately, no data transmission over the Internet or
					any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect
					your personal information, you acknowledge that: (a) there are security and privacy limitations
					inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of
					any and all information and data exchanged between you and us through this Site cannot be
					guaranteed.{' '}
				</p>
			</section>
			<section>
				<h3 className="section-title">Children Under Thirteen</h3>
				<p className="indent">
					FindFishingTournaments.com does not knowingly collect personally identifiable information from
					children under the age of thirteen. If you are under the age of thirteen, you must ask your parent
					or guardian for permission to use this website.{' '}
				</p>
			</section>
			<section>
				<h3 className="section-title">Opt-Out & Unsubscribe from Third Party Communications</h3>
				<p className="indent">
					We respect your privacy and give you an opportunity to opt-out of receiving announcements of certain
					information. Users may opt-out of receiving any or all communications from third-party partners of
					FindFishingTournaments.com by contacting us here:{' '}
				</p>
				<ul>
					<li>Web page: findfishingtournaments.com </li>
					<li>Email: smcknight@seek-outdoors.com </li>
					<li>Phone: 904-223-5099 </li>
				</ul>
			</section>
			<section>
				<h3 className="section-title">E-mail Communications</h3>
				<p className="indent">
					From time to time, FindFishingTournaments.com may contact you via email for the purpose of providing
					announcements, promotional offers, alerts, confirmations, surveys, and/or other general
					communication. In order to improve our Services, we may receive a notification when you open an
					email from FindFishingTournaments.com or click on a link therein.{' '}
				</p>
				<p className="indent">
					If you would like to stop receiving marketing or promotional communications via email from
					FindFishingTournaments.com, you may opt out of such communications by Customers may unsubscribe from
					emails by "replying STOP" or "clicking on the UNSUBSCRIBE button.".{' '}
				</p>
			</section>
			<h3 className="section-title">External Data Storage Sites</h3>
			<p className="indent">
				We may store your data on servers provided by third party hosting vendors with whom we have contracted.{' '}
			</p>
			<section>
				<h3 className="section-title">Changes to this Statement</h3>
				<p className="indent">
					FindFishingTournaments.com reserves the right to change this Privacy Policy from time to time. We
					will notify you about significant changes in the way we treat personal information by sending a
					notice to the primary email address specified in your account, by placing a prominent notice on our
					site, and/or by updating any privacy information on this page. Your continued use of the Site and/or
					Services available through this Site after such modifications will constitute your: (a)
					acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that
					Policy.{' '}
				</p>
			</section>
			<section>
				<h3 className="section-title">Contact Information</h3>
				<p className="indent">
					FindFishingTournaments.com welcomes your questions or comments regarding the Terms: If you believe
					that FindFishingTournaments.com has not adhered to this Statement, please contact
					FindFishingTournaments.com at:{' '}
				</p>
				<ul className="indent">
					<li>Seek Outdoors LLC</li>
					<li>PO BOX 3421</li>
					<li>Ponte Vedra Beach, Florida 32082</li>
				</ul>
				<dl className="indent">
					<dt>Email Address:</dt>
					<dd>smcknight@seek-outdoors.com</dd>
				</dl>
				<dl className="indent">
					<dt>Telephone number:</dt>
					<dd>904-223-5099</dd>
				</dl>
			</section>
		</InnerStructure>
	)
}

export default PrivacyPolicies
