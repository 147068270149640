import React from 'react'
import PropTypes from 'prop-types'
import TournamentItem from '../Layout/Tournament/TournamentItem'
import Spinner from '../effects/loading/Spinner'
import { connect, useDispatch } from 'react-redux'
import { getTournamentTypes } from '../../actions/admin'

const WatchList = ({ auth: { user, authLoading, role }, getTournamentTypes, tournamentTypes, savedTournaments }) => {
	return (
		<div className="tournament-watchlist-container">
			{!authLoading ? (
				savedTournaments.map((tourn, index) => {
					// console.log('tourn', tourn.category)
					let tournTypeColor = ''
					tournamentTypes.map(type => {
						if (type.tournTypeName === tourn.category) {
							// console.log('type', type.tournTypeName)
							// console.log(type.colorValue)
							tournTypeColor = type.colorValue
						}
					})
					return <TournamentItem key={index} tournament={tourn} tournColor={tournTypeColor} />
				})
			) : (
				<Spinner />
			)}
		</div>
	)
}

WatchList.propTypes = {}

const mapStateToProps = state => ({
	auth: state.auth,
	tournamentTypes: state.admin.tournamentTypes ? state.admin.tournamentTypes : null,
	savedTournaments: state.auth.user ? state.auth.user.savedTournaments : null,
})

export default connect(mapStateToProps, { getTournamentTypes })(WatchList)
