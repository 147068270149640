import React, { Fragment } from 'react'
import Time from 'react-time'

const TextElem = () => {
	let now = new Date()

	return (
		<Fragment>
			<p>
				The <a href="#!">a element</a> and
				<a href="https://example.com" target="_blank" rel="noopener noreferrer">
					external a element
				</a>
				examples
			</p>
			<p>
				The <abbr>abbr element</abbr> and an <abbr title="Abbreviation">abbr</abbr> element
				with title examples
			</p>
			<p>
				The <acronym title="A Cowboy Ran One New York Marathon">ACRONYM</acronym> element
				example
			</p>
			<p>
				The <b>b element</b> example
			</p>
			<p>
				The <cite>cite element</cite> example
			</p>
			<p>
				The <code>code element</code> example
			</p>
			<p>
				The <data value="3967381398">data element</data> example
			</p>
			<p>
				The <del>del element</del> example
			</p>
			<p>
				The <dfn>dfn element</dfn> and <dfn title="Title text">dfn element with title</dfn>
				examples
			</p>
			<p>
				The <em>em element</em> example
			</p>
			<p>
				The <i>i element</i> example
			</p>
			<p>
				The <ins>ins element</ins> example
			</p>
			<p>
				The <kbd>kbd element</kbd> example
			</p>
			<p>
				The <mark>mark element</mark> example
			</p>
			<p>
				The <q>q element</q> example
			</p>
			<p>
				The
				<q>
					q element <q>inside</q> a q element
				</q>
				example
			</p>
			<p>
				The <s>s element</s> example
			</p>
			<p>
				The <samp>samp element</samp> example
			</p>
			<p>
				The <small>small element</small> example
			</p>
			<p>
				The <span>span element</span> example
			</p>
			<p>
				The <strong>strong element</strong> example
			</p>
			<p>
				The <sub>sub element</sub> example
			</p>
			<p>
				The <sup>sup element</sup> example
			</p>
			<p>
				The time element example. <Time value={now} format="YYYY/MM/DD:HH:mm" />
			</p>
			<p>
				The <u>u element</u> example
			</p>
			<p>
				The <var>var element</var> example
			</p>
		</Fragment>
	)
}

export default TextElem
