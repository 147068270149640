import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, useDispatch } from 'react-redux'
import { CLEAR_VALIDATION } from '../../../actions/types'

const Validation = ({ errMsg, solution, errClass, tournament, closeBtn }) => {
	const dispatch = useDispatch()
	
	const dismissAlert = e => {
		dispatch({
			type: CLEAR_VALIDATION,
		})
	}
	return (
		<div className={`validation validation__error${errClass ? ' ' + errClass : ''}`}>
			<div className="close-button-container">
				<div className="close-button" onClick={e => dismissAlert(e)}>
					<FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'times-circle' }} />
				</div>
			</div>
			<p>
				<span className="error__problem">Error - {errMsg}</span>
				{solution && <span className="error__solution">{solution}</span>}
			</p>
		</div>
	)
}

Validation.propTypes = {}

const mapStateToProps = state => ({})

export default connect(mapStateToProps)(Validation)
