import React, { useEffect, useState } from 'react'
import Button from '../Elements/Button'
import Moment from 'react-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Document, Page } from 'react-pdf/dist/entry.webpack'
import Spinner from '../../../components/effects/loading/Spinner'
import { Editor, EditorState, convertFromRaw } from 'draft-js'

export const TitlePanel = ({ tournament }) => {
	const [imageLayout, setImageLayout] = useState('')
	useEffect(() => {
		console.log('imageLayout = ', imageLayout)
	}, [imageLayout])
	const calculateImageLayout = e => {
		console.log('fire')

		const img = e.target
		let imgWidth = img.width
		console.log('imgWidth = ', imgWidth)
		let imgHeight = img.height
		console.log('imgHeight = ', imgHeight)
		const maxHeight = 100
		const maxWidth = 100
		let landscape,
			portrait,
			square = false
		if (imgWidth > imgHeight) {
			landscape = true
			setImageLayout('landscape')
		} else if (imgHeight > imgWidth) {
			portrait = true
			setImageLayout('portrait')
		} else if (imgWidth === imgHeight) {
			square = true
			setImageLayout('square')
		}
		if (landscape) {
			while (imgHeight > maxHeight) {
				img.style.width = imgWidth - 20 + 'px'
				imgHeight = img.height
				imgWidth = img.width
			}
		}
		if (portrait) {
			while (imgWidth > maxWidth) {
				img.style.height = imgHeight - 20 + 'px'
				imgHeight = img.height
				imgWidth = img.width
			}
		}
		if (square) {
			while (imgWidth > maxWidth) {
				img.style.height = imgHeight - 20 + 'px'
				imgHeight = img.height
				imgWidth = img.width
			}
		}
	}

	useEffect(() => {
		// console.log('imageLayout = ', imageLayout)
	}, [imageLayout])
	return (
		<div className={`title-panel-backdrop${tournament.logo.path ? ' has-logo' : ' no-logo'}`}>
			<div className="title-panel">
				{tournament.logo.path && (
					<div className="tourn-logo-container">
						<div className="img-wrapper">
							<img src={tournament.logo.path} onLoad={e => calculateImageLayout(e)} />
						</div>
					</div>
				)}
				<h1>{tournament.tourName}</h1>
			</div>
		</div>
	)
}

export const Type = ({ tournament }) => {
	return (
		<div className="tournament-type info-item-group">
			<h6 className="item-group-title">Tournament Type:</h6>
			{/* <span className="property-field__value">{tournament.category}</span> */}
			<Button
				className="property-field__inline__value"
				variant="secondary"
				label={tournament.category}
				outline={true}
				cursor={'default'}
			></Button>
		</div>
	)
}

export const Date = ({ tournament }) => {
	return (
		<div className="event-date-time info-item-group">
			<div className="calendar-date date-time-field">
				<h6 className="item-group-title">When:</h6>
				<div className="info-item-content">
					<Moment className="" format="MMMM Do YYYY">
						{tournament.eventDate.startDate}
					</Moment>
					{tournament.eventDate.endDate !== null && (
						<div className="calendar-date">
							<div className="date-divider">
								<FontAwesomeIcon icon={{ prefix: 'far', iconName: 'arrow-alt-down' }} />
							</div>
							<Moment className="" format="MMMM Do YYYY">
								{tournament.eventDate.endDate}
							</Moment>
						</div>
					)}
				</div>
			</div>

			<div className="event-time date-time-field">
				<h6 className="item-group-title">Tournament Start: </h6>
				<div className="info-item-content event-time">
					<Moment format="h:mm a">{tournament.eventDate.startDate}</Moment>
				</div>
			</div>
		</div>
	)
}

export const Location = ({ tournament }) => {
	return (
		<div className="tournament-address info-item-group">
			<h6 className="item-group-title">Tournament Location:</h6>
			<ul className="info-item-content">
				<li>{tournament.location.address}, </li>
				<li>
					{tournament.location.city}, {tournament.location.state}, {tournament.location.zip}
				</li>
			</ul>
		</div>
	)
}

export const Organizer = ({ tournament }) => {
	return (
		<div className="tournament-organizer info-item-group">
			<h6 className="item-group-title">Tournament Organizer:</h6>
			<div className="info-item-content">{tournament.organizer}</div>
		</div>
	)
}

export const Contact = ({ tournament }) => {
	return (
		<div className="tournament-contact-info info-item-group">
			<h6 className="item-group-title">Tournament Contact Info:</h6>
			<ul className="info-item-content">
				{tournament.contact.website && (
					<li>
						<span className="method-title">Website:</span>
						<span className="method-text">
							<a href={tournament.contact.website} target="_blank" rel="noopener noreferrer">
								<FontAwesomeIcon icon={{ prefix: 'fad', iconName: 'globe' }} />
								{tournament.contact.website !== null
									? tournament.contact.website.replace(/^(https?|ftp):\/\//, '')
									: null}
							</a>
						</span>
					</li>
				)}
				{tournament.contact.email && (
					<li>
						<span className="method-title">By Email:</span>
						<span className="method-text">
							<FontAwesomeIcon icon={{ prefix: 'fad', iconName: 'envelope-open-text' }} />
							{tournament.contact.email}
						</span>
					</li>
				)}
				{tournament.contact.phone && (
					<li>
						<span className="method-title">By Phone:</span>
						<span className="method-text">
							<a href={`tel:${tournament.contact.phone}`}>
								<FontAwesomeIcon icon={{ prefix: 'fad', iconName: 'phone' }} />
								{tournament.contact.phone}
							</a>
						</span>
					</li>
				)}
			</ul>
		</div>
	)
}

export const Details = ({ tournament }) => {
	// const contentState = convertFromRaw(tournament.description);
	const editorState = EditorState.createWithContent(tournament.description)
	return (
		<div className="info-item-group">
			<div className="tournament-description">
				<h6 className="item-group-title">Tournament Description:</h6>
				<div className="info-item-content">
					<Editor editorState={editorState} readOnly={true} />
				</div>
			</div>
		</div>
	)
}

export const RegistrationDeadline = ({ tournament }) => {
	return (
		<div className="info-item-group">
			<h6 className="item-group-title">Registration Deadline:</h6>
			<ul className="info-item-content">
				<li>
					<span className="method-title">Deadline Date:</span>
					<span className="method-text">
						<Moment format="MMMM Do YYYY">{tournament.registrationDeadline}</Moment>
					</span>
				</li>
				<li>
					<span className="method-title">Deadline Time:</span>
					<span className="method-text">
						<Moment format="h:mm a">{tournament.registrationDeadline}</Moment>
					</span>
				</li>
			</ul>
		</div>
	)
}

export const Media = ({ tournament }) => {
	// eslint-disable-next-line
	const [pageNumber, setPageNumber] = useState(1)
	// eslint-disable-next-line
	const [numPages, setNumPages] = useState()
	const onDocumentLoad = ({ pages }) => {
		setNumPages(pages)
	}
	return (
		<div className="media-container">
			<h2 className="property-field__label">Tournament Media:</h2>
			<div>
				<Document file={tournament.tournMedia.path} onLoadSuccess={onDocumentLoad}>
					<Page pageNumber={pageNumber} tournamentLoading={<Spinner />} width={300} />
				</Document>
				<a className="btn btn-primary btn-small" href={tournament.tournMedia.path} download>
					Download PDF
				</a>
				<a
					className="btn btn-primary btn-small"
					href={tournament.tournMedia.path}
					target="_blank"
					rel="noopener noreferrer"
				>
					View in Browser
				</a>
			</div>
		</div>
	)
}
