import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { changePassword, verifyResetCode } from '../../../actions/account'
import { getCurrentProfile } from '../../../actions/profile'
import { setAlert } from '../../../actions/alert'
import Button from '../../Layout/Elements/Button'
import InfoBox from '../../Layout/Elements/InfoBox'

import { CLEAR_VALIDATION, USER_PASSWORD_UPDATING } from '../../../actions/types'

const ChangePasswordForm = ({
	setAlert,
	user,
	userId,
	resetCode,
	fromEmail,
	isAuthenticated,
	passwordResetVarrifying,
	passwordResetCodeVerrified,
	changePassword,
	userPasswordUpdating,
	userPasswordUpdated,
	passwordUpdateError,
	display,
}) => {
	// "user" is passed in from the dashboard when a user wants to change thier password while logged in
	// "userId" is passed in via "match" method from parent when a user is not logged in and requests a password change via email request -> the user id will be appended to the url
	const dispatch = useDispatch()
	const [resetCodeVerified, setResetCodeVerified] = useState(false)

	// Create function in actions for get resetCode
	// get resetCode will hit the db and check the password reset collection for an existing document with the user's id and a matching reset code
	// if no reset code can be found then the code has expired and a new code needs to be sent - > display the requset reset email form again

	// const dataToVerify = {
	// 	userId: userId,
	// 	resetCode: resetCode,
	// }
	let isFromEmail = false

	if (fromEmail) {
		isFromEmail = true
	}

	useEffect(() => {
		if (fromEmail) {
			dispatch(verifyResetCode(resetCode, userId))
		}
	}, [fromEmail])

	const [passFormData, setPassFormData] = useState({
		userId: '',
		newPass: '',
		newPassConf: '',
	})

	useEffect(() => {
		if (fromEmail) {
			// console.log('From Email')
			setPassFormData({ ...passFormData, userId: userId })
		} else {
			// console.log('From Logged In User')
			setPassFormData({ ...passFormData, userId: user._id })
		}
	}, [])

	const handleSendNewEmail = () => {}

	// useEffect(() => {
	// 	if (isAuthenticated) {
	// 		console.log('Authenticated')
	// 		if (user !== null) {
	// 			console.log('user !== null')
	// 			setPassFormData({ ...passFormData, userId: user._id })
	// 		}
	// 	} else {
	// 		console.log('Not Authenticated')
	// 		setPassFormData({ ...passFormData, userId: userId })
	// 	}
	// }, [user])

	// useEffect(() => {
	// 	console.log('passFormData = ', passFormData)
	// }, [passFormData])

	// const { oldPass, newPass, newPassConf } = passFormData
	const { newPass, newPassConf } = passFormData

	const onPasswordChange = (e) => {
		setPassFormData({ ...passFormData, [e.target.name]: e.target.value })
	}

	const onPasswordSubmit = (e) => {
		e.preventDefault()
		dispatch({
			type: CLEAR_VALIDATION,
			type: USER_PASSWORD_UPDATING,
		})

		if (newPass === '' && newPassConf === '') {
			dispatch(setAlert('Ooops! You must first enter and then confirm your new password.', 'danger'))
		} else {
			// console.log('formData component submit = ', formData)
			changePassword(passFormData, isFromEmail)
		}
	}

	return (
		<>
			{/* PassWord Reset Expired */}
			{/* If the password reset code has expired */}
			{!passwordResetVarrifying && !passwordResetCodeVerrified && (
				<>
					<InfoBox
						variant="primary"
						size="large"
						outline="true"
						iconName="exclamation-triangle"
						noShadow={true}
					>
						EXPIRED: The "Change Password" requset has expired. Please log in or click "Send me a new
						password reset email" to continue.
					</InfoBox>

					<div className="section-options">
						<Button
							isLink
							to="/login"
							variant="link"
							label="Log In"
							icon="sign-in-alt"
							iconType="fad"
							size="small"
						/>
						<Button
							isLink
							to="/forgot-password"
							variant="link"
							label="Send me a new password reset email"
							icon="envelope"
							iconType="fas"
							onClick={() => handleSendNewEmail()}
							size="small"
						/>
					</div>
				</>
			)}
			{/* END PassWord Reset Expired */}

			{!passwordResetVarrifying && passwordResetCodeVerrified && !userPasswordUpdated && (
				<div className="user-password-reset-container">
					<form className="form" onSubmit={(e) => onPasswordSubmit(e)}>
						<div className="form-group">
							<label htmlFor="password">New Password:</label>
							<input
								type="password"
								className="password"
								name="newPass"
								onChange={(e) => onPasswordChange(e)}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="password">Confirm New Password:</label>
							<input
								type="password"
								className="password"
								name="newPassConf"
								onChange={(e) => onPasswordChange(e)}
							/>
						</div>
						<Button
							variant="secondary"
							outline={true}
							label="Change Password"
							icon="edit"
							iconType="fad"
							size="small"
						/>
					</form>
				</div>
			)}

			{!userPasswordUpdating && userPasswordUpdated && (
				<div className="success-container">
					{fromEmail && (
						<>
							<InfoBox
								variant="success"
								size="large"
								outline="true"
								iconName="glass-cheers"
								noShadow={true}
							>
								Success! Your password has been updated! Please log in to continue.
							</InfoBox>
							<Button
								isLink
								to="/login"
								variant="link"
								label="Log In"
								icon="sign-in-alt"
								iconType="fad"
								size="large"
							/>
						</>
					)}
					{!fromEmail && (
						<>
							<InfoBox
								variant="success"
								size="large"
								outline="true"
								iconName="glass-cheers"
								noShadow={true}
							>
								Success! Your password has been updated!
							</InfoBox>
							<Button
								variant="link"
								label="Continue"
								icon="sign-in-alt"
								iconType="fad"
								size="large"
								onClick={display}
							/>
						</>
					)}
				</div>
			)}
		</>
	)
}

ChangePasswordForm.propTypes = {
	setAlert: PropTypes.func.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool,
	passwordResetVarrifying: PropTypes.bool,
	passwordResetCodeVerrified: PropTypes.bool,
	changePassword: PropTypes.func.isRequired,
	userPasswordUpdating: PropTypes.bool,
	userPasswordUpdated: PropTypes.bool,
	passwordUpdateError: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	passwordResetVarrifying: state.auth.passwordResetVarrifying,
	passwordResetCodeVerrified: state.auth.passwordResetCodeVerrified,
	userPasswordUpdating: state.auth.userPasswordUpdating,
	userPasswordUpdated: state.auth.userPasswordUpdated,
	passwordUpdateError: state.auth.passwordUpdateError,
})

export default connect(mapStateToProps, { setAlert, getCurrentProfile, changePassword })(ChangePasswordForm)
