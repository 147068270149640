import React, { useState, useEffect, useRef } from 'react'
import Button from '../../Layout/Elements/Button'

import InfoBox from '../../Layout/Elements/InfoBox'
import { uploadToS3Bucket } from './SponsorsUtils'

const Sponsors = ({ parentCallback, existingSponsors, tournLevel, editMode }) => {
	const logoImg = useRef()
	const sendData = (data) => {
		parentCallback(data)
	}

	const [existingSponsorsLoaded, setExistingSponsorsLoaded] = useState(false)

	const [showAddSponsorForm, setShowAddSponsorForm] = useState(false)
	const [sponsor, setSponsor] = useState({
		sponsorName: '',
		sponsorUrl: '',
		sponsorLogoPath: '',
		sponsorOrder: '',
	})

	const [sponsorOrder, setSponsorOrder] = useState(1)
	const [sponsors, setSponsors] = useState(editMode ? [...existingSponsors] : [])
	const [s3UploadObjectLoading, setS3UploadObjectLoading] = useState(false)

	// Add existing sponsors to form
	useEffect(() => {
		if (editMode) {
			if (existingSponsorsLoaded === false && existingSponsors.length > 0) {
				existingSponsors.forEach((element) => {
					setSponsors((sponsors) => [
						...sponsors,
						{
							sponsorName: element.sponsorName,
							sponsorUrl: element.sponsorUrl,
							sponsorLogoPath: element.sponsorLogoPath,
							sponsorOrder: element.sponsorOrder,
						},
					])
				})
				setExistingSponsorsLoaded(true)
			}
		}
	}, [existingSponsors])

	// Sponsor Logo upload States
	const [sponsorLogo, setSponsorLogo] = useState({
		name: 'Choose File',
		url: '',
		uploadSuccess: false,
		uploadError: false,
		signingError: false,
		localUploadURL: '',
		loading: false,
	})

	const [s3UploadObject, setS3UploadObject] = useState({
		item: '',
		fieldName: '',
		fileType: '',
		itemName: '',
		itemUrl: '',
		localUploadUrl: '',
		itemLoading: false,
	})

	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// Upload To AWS S3 Bucket
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// File Input Change handler
	const handleSponsorLogoUpload = (e) => {
		// console.log(e.target.files[0])

		setS3UploadObject({
			...s3UploadObject,
			item: e.target.files[0],
			fieldName: e.target.name,
			fileType: e.target.files[0].type,
			itemName: e.target.files[0].name,
			localUploadUrl: URL.createObjectURL(e.target.files[0]),
		})

		setS3UploadObjectLoading(true)
	}

	// on s3UploadObject state change
	useEffect(() => {
		if (s3UploadObjectLoading) {
			uploadToS3Bucket(s3UploadObject, setS3UploadObject, setSponsorLogo, sponsorLogo, setS3UploadObjectLoading)
		}
	}, [s3UploadObjectLoading])

	// END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
	// END Upload To AWS S3 Bucket
	// END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
	// END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
	// END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

	// Show Add Sponsor Form Field
	const handleAddSponsorFormDisplay = (e) => {
		e.preventDefault()
		setShowAddSponsorForm(true)
	}

	// Populate Sponsor State on form text input change
	const handleSponsorField = (e) => {
		e.preventDefault()
		setSponsor({ ...sponsor, [e.target.name]: e.target.value })
	}

	// Add sponsor object to Sponsors State (Array of Sponsor(s))
	const handleAddSponsor = (e) => {
		e.preventDefault()
		setExistingSponsorsLoaded(true)
		setSponsors((sponsors) => [
			...sponsors,
			{
				sponsorName: sponsor.sponsorName,
				sponsorUrl: sponsor.sponsorUrl,
				sponsorLogoPath: sponsorLogo.url,
				sponsorOrder: sponsorOrder,
			},
		])
		setShowAddSponsorForm(false)

		// Resets the edit sponsors field
		setSponsorLogo({
			name: 'Choose File',
			url: '',
			uploadSuccess: false,
			uploadError: false,
			signingError: false,
			localUploadURL: '',
			loading: false,
		})

		setSponsorOrder(sponsorOrder + 1)
	}

	const handleRemoveSponsor = (e, sponsorName) => {
		e.preventDefault()
		setSponsors(sponsors.filter((el) => el.sponsorName != sponsorName))
	}

	// when Sponsors State is modified call sendData to pass Sponsors to parent element
	useEffect(() => {
		sendData(sponsors)
	}, [sponsors])

	return (
		<fieldset>
			<div className="column-container">
				<div className="add-sponsors-container col-2">
					<h4 className="form-section-title">{editMode ? 'Edit' : 'Add'} Sponsors</h4>
					{!showAddSponsorForm && (
						<>
							<Button
								variant="secondary"
								label={`Add ${sponsors.length > 0 ? 'An Additional ' : ''}Sponsor`}
								onClick={(e) => handleAddSponsorFormDisplay(e)}
							/>
						</>
					)}

					{showAddSponsorForm && (
						<>
							<div className="add-sponsor">
								<div className="form-group">
									<label htmlFor="text_field">Add a Tournament Sponsor</label>
									{/* <input type="text" placeholder="Sponsors" name="sponsorName" value={sponsors} onChange={e => sendData(e)} /> */}
									<input
										type="text"
										placeholder="Sponsor Name"
										name="sponsorName"
										onChange={(e) => handleSponsorField(e)}
									/>
									{tournLevel === 'tl_3' && (
										<>
											<input
												type="text"
												placeholder="Sponsor Website"
												name="sponsorUrl"
												onChange={(e) => handleSponsorField(e)}
											/>
											<InfoBox isNote variant="secondary" iconName="asterisk">
												Make sure to include the full URL by using "http://" or "https://"
												before the sponsors website address.
												<br />
												<br />
												<strong>Example:</strong> https://www.mysponsorsite.com
											</InfoBox>
										</>
									)}
								</div>
								{tournLevel === 'tl_2' && (
									<Button
										variant="success"
										label="Add Sponsor"
										onClick={(e) => handleAddSponsor(e)}
										disabled={sponsor.sponsorName ? false : true}
									/>
								)}
								{tournLevel === 'tl_3' && (
									<>
										<div className="form-group media-group">
											{!sponsorLogo.url && (
												<div className="media-input">
													{/* TODO: Add legal disclaimer to all pages that any logos used are the responibility of the tournament owner */}
													<label htmlFor="sponsor_logo">Upload Sponsor Logo</label>
													<label className="file-input-label">{sponsorLogo.name}</label>
													<input
														type="file"
														name="sponsor_logo"
														className="file-input"
														onChange={
															s3UploadObject.itemLoading
																? null
																: (e) => handleSponsorLogoUpload(e)
														}
													/>
												</div>
											)}
											{sponsorLogo.url && (
												<>
													<div className="image-display" ref={logoImg}>
														<img
															src={sponsorLogo.localUploadURL}
															alt={`tournament logo titled ${sponsorLogo.name}`}
															className="img-responsive"
														/>
														<div className="image-caption">{sponsorLogo.name}</div>
													</div>
												</>
											)}
										</div>

										<Button
											variant="success"
											label="Add Sponsor"
											onClick={(e) => handleAddSponsor(e)}
											disabled={
												sponsorLogo.url && sponsor.sponsorName && sponsor.sponsorUrl
													? false
													: true
											}
										/>
									</>
								)}
							</div>
						</>
					)}
				</div>
				{sponsors.length > 0 && (
					<div className="sponsors-list col-2">
						{sponsors.map((sponsor, index) => {
							return (
								<div className="sponsor-item" key={index}>
									<h6>Sponsor {index + 1}</h6>
									<dl className="sponsor-item__name">
										<dt>Sponsor Name:</dt>
										<dd>{sponsor.sponsorName}</dd>
									</dl>
									{tournLevel === 'tl_3' && (
										<>
											<dl className="sponsor-item__website">
												<dt>Website:</dt>
												<dd>
													<a
														href={sponsor.sponsorUrl}
														target="_blank"
														rel="noopener noreferrer"
													>
														{sponsor.sponsorUrl}
													</a>
												</dd>
											</dl>
											<dl className="sponsor-item__logo">
												<dt>Logo:</dt>
												<dd>
													<img
														className="img-responsive"
														src={sponsor.sponsorLogoPath}
														alt={sponsor.sponsorName + ' Logo'}
													/>
												</dd>
											</dl>
										</>
									)}
									<Button
										className="spacing-top-1"
										size="small"
										label="Remove Sponsor"
										onClick={(e) => handleRemoveSponsor(e, sponsor.sponsorName)}
									/>
								</div>
							)
						})}
					</div>
				)}
			</div>
		</fieldset>
	)
}

export default Sponsors
