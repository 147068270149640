import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
// import ReactDOM from 'react-dom'
// import { compose, withProps } from 'recompose'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import MapMarker from '../../Layout/Elements/MapMarker'
import { mapStyles } from './mapStyling'

const MyMapComponent = ({ tournaments, types, searchMapCenter, searchMade }) => {
	let searchCenter = { lat: searchMapCenter.lat, lng: searchMapCenter.lng }
	let initCenter = { lat: 29.396865, lng: -82.5511525 }
	const mapZoom = 6

	// useEffect(() => {
	// 	console.log('search made')

	// 	if (searchMade === true) {
	// 		console.log('search made true')
	// 		searchCenter = { lat: searchMapCenter.lat, lng: searchMapCenter.lng }
	// 	}
	// }, [searchMade])

	const createMapOptions = maps => {
		return mapStyles
	}

	return (
		<div className="map-container">
			<GoogleMapReact
				bootstrapURLKeys={{
					key: `AIzaSyCpKIZfNotUD9aZdeASGPwhvoaebqtlxs4`,
				}}
				defaultCenter={initCenter}
				center={searchMade ? searchCenter : initCenter}
				defaultZoom={mapZoom}
				options={createMapOptions}
			>
				{tournaments &&
					tournaments.map((tournament, index) => {
						// console.log('tournament.category = ', tournament.category)
						let tournTypeColor = ''
						types.map(type => {
							// console.log('type = ', type.tournTypeName)
							if (type.tournTypeName === tournament.category) {
								tournTypeColor = type.colorValue
							}
						})
						return (
							<MapMarker
								key={index}
								tourn={tournament}
								lat={tournament.location.lat}
								lng={tournament.location.lng}
								color={tournTypeColor}
							/>
						)
					})}
			</GoogleMapReact>
		</div>
	)
}

MyMapComponent.propTypes = {
	searchMade: PropTypes.bool,
	searchMapCenter: PropTypes.object,
}

const mapStateToProps = state => ({
	searchMade: state.tournament.searchMade,
	searchMapCenter: state.tournament.searchMapCenter,
})

export default connect(mapStateToProps)(MyMapComponent)
