import React from 'react'
import { connect } from 'react-redux'

const SiteWrapper = ({ children, modalOpen }) => {
	return <div className={`fft-site${modalOpen ? ' modal-open' : ''}`}>{children}</div>
}

const mapStateToProps = state => ({
	modalOpen: state.userInterface.modalOpen,
})

export default connect(mapStateToProps)(SiteWrapper)
