import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import Spinner from '../effects/loading/Spinner'
// import DashboardActions from './DashboardActions'
import { getCurrentProfile, deleteAccount } from '../../actions/profile'
import { getTournamentTypes } from '../../actions/admin'
import { upgradeUserAccount, changePassword } from '../../actions/account'
// import { getTournList } from '../../actions/tournament'
import InnerStructure from '../Layout/InnerStructure'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DashboardLanding from './DashboardLanding'
import Profile from '../dashboard/Profile'
// import Modal from '../Layout/Modal'
import { TimelineMax, CSSPlugin } from 'gsap/all'
// import { Buffer } from 'buffer'
import Button from '../Layout/Elements/Button'

import ListTournament from '../Layout/Tournament/ListTournament'
import Watchlist from '../dashboard/WatchList'
import TournamentSearch from '../forms/tournament/TournamentSearch'
import TournSearchResults from '../Layout/Tournament/TournSearchResults'
import ViewTitleBar from '../Layout/ViewTitleBar'
import ChangePasswordForm from '../forms/registration/ChangePasswordForm'
import UserTournaments from './UserTournaments'

import {
	TOURNAMENT_SUBMISSION_COMPLETE,
	CLEAR_TOURNAMENTS,
	// TOURNAMENT_UPDATE_LEVEL,
	// EXIT_EDIT_TOURNAMENT,
	SHOW_TOURNAMENT_LEVEL_OPTIONS,
	SHOW_TOURNAMENT_SEARCH,
	SHOW_LIST_A_TOURNAMENT,
	SHOW_VIEW_PROFILE,
	SHOW_VIEW_MY_TOURNAMENTS,
	SHOW_MY_TOURNAMENT_WATCHLIST,
	SHOW_DASHBOARD_LANDING,
	USER_ACCOUNT_UPGRADED,
	CLEAR_VALIDATION,
} from '../../actions/types'

const Dashboard = ({
	getCurrentProfile,
	deleteAccount,
	tournamentTypes,
	getTournamentTypes,
	auth: { user, authLoading, role },
	profile: { profile, profileLoading },
	payment,
	paymentComplete,
	savedTournaments,
	tournamentAdded,
	tournamentEdit,
	showTournamentOptions,
	showDashboardLanding,
	showTournamentSearch,
	showListATournament,
	showViewProfile,
	showViewMyTournaments,
	showMyTournamentWatchlist,
	upgradeUserAccount,
	changePassword,
}) => {
	// eslint-disable-next-line
	const plugins = [CSSPlugin]
	const dispatch = useDispatch()

	const viewControls = useRef()
	const watchListButton = useRef()

	const [isWrapped, setIsWrapped] = useState(false)
	const [displayForm, setDisplayForm] = useState(false)

	const handleFormDisplay = () => {
		setDisplayForm(!displayForm)
	}

	useEffect(() => {
		getTournamentTypes()
	}, [getTournamentTypes])

	useEffect(() => {
		if (tournamentAdded === true) {
			dispatch({ type: SHOW_VIEW_MY_TOURNAMENTS })
		}

		return () => {
			dispatch({ type: TOURNAMENT_SUBMISSION_COMPLETE })
		}
	}, [tournamentAdded])

	// Set initial Display for loading in Dashboard
	useEffect(() => {
		if (role === 'standard') {
			// setDisplayTournSearch(true)
			dispatch({ type: SHOW_TOURNAMENT_SEARCH })
		} else if (role === 'tournament' || role === 'admin') {
			// setShowCreateTournament(true)
			dispatch({ type: SHOW_LIST_A_TOURNAMENT })
			dispatch({ type: SHOW_TOURNAMENT_LEVEL_OPTIONS })
		}
	}, [authLoading, role])

	const handleUserUpgrade = () => {
		upgradeUserAccount({
			userId: user._id,
			role: 'tournament',
		})
	}

	useEffect(() => {
		getCurrentProfile()
	}, [user])

	return !user || (profileLoading && profile === null) ? (
		<Spinner />
	) : (
		<>
			<ViewTitleBar viewTitle="My Dashboard">
				<div ref={viewControls} className="view-controls dashboard-view">
					<Button
						variant="link"
						label={'Tournament Search'}
						outline={true}
						className={showTournamentSearch ? 'active' : ''}
						onClick={() => {
							if (showTournamentSearch) {
								dispatch({ type: SHOW_DASHBOARD_LANDING })
							} else {
								dispatch({ type: CLEAR_TOURNAMENTS })
								dispatch({ type: SHOW_TOURNAMENT_SEARCH })
							}
						}}
					/>

					{(role === 'tournament' || role === 'admin') && (
						<Button
							variant="link"
							label={'List A Tournament'}
							outline={true}
							className={showListATournament ? 'active' : ''}
							onClick={() => {
								if (showListATournament) {
									dispatch({ type: SHOW_DASHBOARD_LANDING })
								} else {
									dispatch({ type: SHOW_LIST_A_TOURNAMENT })
								}
							}}
						/>
					)}

					<Button
						variant="link"
						label={'View Profile'}
						outline={true}
						className={showViewProfile ? 'active' : ''}
						onClick={() => {
							if (showViewProfile) {
								dispatch({ type: SHOW_DASHBOARD_LANDING })
							} else {
								dispatch({ type: SHOW_VIEW_PROFILE })
							}
						}}
					/>

					{(role === 'tournament' || role === 'admin') && (
						<>
							<Button
								variant="link"
								label={'View My Tournaments'}
								outline={true}
								className={showViewMyTournaments ? 'active' : ''}
								onClick={() => {
									if (showViewMyTournaments) {
										dispatch({ type: SHOW_DASHBOARD_LANDING })
									} else {
										dispatch({ type: SHOW_VIEW_MY_TOURNAMENTS })
									}
								}}
							/>
						</>
					)}
					<Button
						ref={watchListButton}
						variant="link"
						label={'My Tournaments Watchlist'}
						outline={true}
						className={showMyTournamentWatchlist ? 'active' : ''}
						onClick={() => {
							if (showMyTournamentWatchlist) {
								dispatch({ type: SHOW_DASHBOARD_LANDING })
							} else {
								dispatch({ type: SHOW_MY_TOURNAMENT_WATCHLIST })
							}
						}}
					/>
					{role === 'standard' && (
						<Button
							variant="link"
							label={'List A Tournament'}
							outline={true}
							className={showListATournament ? 'active' : ''}
							onClick={() => {
								if (showListATournament) {
									dispatch({ type: SHOW_DASHBOARD_LANDING })
								} else {
									dispatch({ type: SHOW_LIST_A_TOURNAMENT })
								}
							}}
						/>
					)}
				</div>
			</ViewTitleBar>

			<InnerStructure className="dashboard">
				<div className="dashboard-layout">
					<div className="dashboard-content">
						{showDashboardLanding && <DashboardLanding />}
						{showTournamentSearch && (
							<>
								<header className="section-header">
									<h2 className="section-title">Tournament Search</h2>
									<nav className="tournament-search-nav">
										<ul>
											<li>
												<Button
													variant="primary"
													size={'small'}
													label={'Close'}
													outline={true}
													onClick={() => {
														dispatch({ type: SHOW_DASHBOARD_LANDING })
													}}
												/>
											</li>
										</ul>
									</nav>
								</header>
								<section className="search-dashboard">
									<TournamentSearch />
									<TournSearchResults displayAds={false} />
								</section>
							</>
						)}

						{/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
						{/* // List / Create a Tournament */}
						{(role === 'tournament' || role === 'admin') && showListATournament && <ListTournament />}
						{/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
						{role === 'standard' && showListATournament && (
							<>
								<div className="view-upgrade-user">
									<h2>Looking to list a tournament?</h2>
									<p>
										Tournament listings are created, edited and managed by a tournament organizer.
									</p>
									<p>
										If you are a tournament organizer and would to list your fishing tournament with
										us just click the upgrade account button below.
									</p>
									<p>
										Upgrading your account gives you access to the "List a Tournament" feature where
										you can create and manage your tournament listing.
									</p>

									<Button variant="success" label="Upgrade Account" onClick={handleUserUpgrade} />
								</div>
							</>
						)}

						{showViewProfile && (
							<>
								<div className="user-profile-container">
									<header className="dasboard-section-header">
										<div className="section-title">
											{!displayForm && <h3>My Profile</h3>}
											{displayForm && <h3>Change Password</h3>}
										</div>
										<div className="section-options">
											<Button
												variant="link"
												label={displayForm ? 'Hide Change Password Form' : 'Change Password'}
												icon={displayForm ? null : 'edit'}
												iconType={displayForm ? null : 'fad'}
												onClick={() => handleFormDisplay()}
												size="small"
											/>
											<Button
												isLink
												to="/edit-profile"
												variant="link"
												label={'Edit Profile'}
												icon={'edit'}
												iconType={'fad'}
												size="small"
											/>
										</div>
									</header>
									<section className="dasboard-section-content">
										{!displayForm && (
											<>
												{profile !== null ? (
													<Profile user={user} profile={profile} />
												) : (
													<>
														<p className="info-text">
															You have not yet setup a profile, please add some info
														</p>
														<Link to="/create-profile" className="btn btn-primary my-1">
															Create Profile
														</Link>
													</>
												)}
											</>
										)}
										{displayForm && (
											<ChangePasswordForm user={user} display={() => handleFormDisplay()} />
										)}
									</section>
								</div>
							</>
						)}

						{savedTournaments.length > 0 && showMyTournamentWatchlist && (
							<>
								<h3>My Tournament Watchlist:</h3>
								<Watchlist />
							</>
						)}
						{savedTournaments.length === 0 && showMyTournamentWatchlist && (
							<>
								<h3>My Tournament Watchlist:</h3>
								<p>It looks like you haven't added any tournaments to your watch list.</p>
								<p>
									To add a tournament click the "Add to My Watchlist" while viewing a tournament's
									details.
								</p>
							</>
						)}

						{showViewMyTournaments && (
							<>
								<UserTournaments userId={user._id} />
							</>
						)}
					</div>
				</div>
			</InnerStructure>
		</>
	)
}

Dashboard.propTypes = {
	getCurrentProfile: PropTypes.func.isRequired,
	deleteAccount: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
	savedTournaments: PropTypes.array,
	getTournamentTypes: PropTypes.func.isRequired,
	payment: PropTypes.object.isRequired,
	tournamentAdded: PropTypes.bool,
	paymentLevel: PropTypes.string.isRequired,
	tournamentEdit: PropTypes.bool,
	upgradeUserAccount: PropTypes.func,
	changePassword: PropTypes.func,
}

const mapStateToProps = state => ({
	auth: state.auth,
	profile: state.profile,
	tournamentTypes: state.admin.tournamentTypes ? state.admin.tournamentTypes : null,
	payment: state.payment,
	savedTournaments: state.auth.user ? state.auth.user.savedTournaments : null,
	paymentComplete: state.payment.paymentComplete,
	tournamentAdded: state.tournament.tournamentAdded,
	paymentLevel: state.payment.paymentLevel,
	tournamentEdit: state.tournament.tournamentEdit,
	showTournamentOptions: state.tournament.showTournamentOptions,
	showDashboardLanding: state.dashboard.showDashboardLanding,
	showTournamentSearch: state.dashboard.showTournamentSearch,
	showListATournament: state.dashboard.showListATournament,
	showViewProfile: state.dashboard.showViewProfile,
	showViewMyTournaments: state.dashboard.showViewMyTournaments,
	showMyTournamentWatchlist: state.dashboard.showMyTournamentWatchlist,
})

export default connect(mapStateToProps, {
	getCurrentProfile,
	deleteAccount,
	getTournamentTypes,
	upgradeUserAccount,
	changePassword,
})(Dashboard)
