import React from 'react'
import StyleButton from './StyleButton'

const BlockStyleControls = ({ editorState, onToggle }) => {
	const blockTypes = [
		// { label: 'H1', style: 'header-one' },
		{ label: 'H2', style: 'header-two' },
		{ label: 'H3', style: 'header-three' },
		{ label: 'H4', style: 'header-four' },
		{ label: 'H5', style: 'header-five' },
		{ label: 'H6', style: 'header-six' },
		{ label: 'Blockquote', style: 'blockquote' },
		{ label: 'Bullet List', style: 'unordered-list-item' },
		{ label: 'Numbered List', style: 'ordered-list-item' },
		// { label: 'Code Block', style: 'code-block' },
	]

	const selection = editorState.getSelection()
	const blockType = editorState
		.getCurrentContent()
		.getBlockForKey(selection.getStartKey())
		.getType()

	return (
		<div className="RichEditor-controls">
			{blockTypes.map(type => (
				<StyleButton
					key={type.label}
					active={type.style === blockType}
					label={type.label}
					onToggle={onToggle}
					style={type.style}
				/>
			))}
		</div>
	)
}

export default BlockStyleControls
