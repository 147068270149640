import axios from 'axios'
import { setAlert } from './alert'
// import mongoose from 'mongoose'
// import zipcodes from 'zipcodes'
// import { loadUser } from './auth'
// import { getTournamentTypes } from './admin'

import { PAYMENT_SUBMITTED, PAYMENT_SUBMITTED_ERROR, TOURNAMENT_UPDATE_LEVEL } from './types'

// Get tournaments
export const submitPayment = (token, data) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}

	const paymentData = {
		data: data,
		token: token,
	}

	try {
		const res = await axios.post('/api/payment', paymentData, config)
		dispatch({
			type: PAYMENT_SUBMITTED,
			payload: res.data,
		})
		dispatch({
			type: TOURNAMENT_UPDATE_LEVEL,
			payload: res.data.tournLevel,
		})
		dispatch(setAlert('Payment Successful!', 'success'))
	} catch (err) {
		dispatch({
			type: PAYMENT_SUBMITTED_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		})
	}
}
