import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import InnerStructure from '../Layout/InnerStructure'
// import Spinner from '../effects/loading/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Button from '../Layout/Elements/Button'
import LoginForm from '../forms/registration/LoginForm'
import LogoTitle from '../Layout/Elements/LogoTitle'
import { TOURNAMENT_ALERT_SIGNUP_OPEN } from '../../actions/types'

const Login = ({ isAuthenticated, fromTournAlertSignUp }) => {
	const dispatch = useDispatch()
	// console.log('components/auth/Login.js -------------------------------------')

	if (isAuthenticated) {
		console.log('IS AUTHENTICATED 2')
		if (fromTournAlertSignUp) {
			console.log('fired')

			dispatch({ type: TOURNAMENT_ALERT_SIGNUP_OPEN })
		}
		// console.log('if isAuthenticated -------------------------------------')
		return <Redirect to="/dashboard" />
	}

	return (
		<>
			<InnerStructure>
				<LogoTitle tag="h1" title="Sign In" />
				<h6 className="info-title">
					<FontAwesomeIcon icon="user-circle" /> Sign Into Your Account
					{/* TODO: enhance this icon */}
				</h6>
				<LoginForm />
			</InnerStructure>
		</>
	)
}

Login.propTypes = {
	isAuthenticated: PropTypes.bool,
	fromTournAlertSignUp: PropTypes.bool,
}

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
	fromTournAlertSignUp: state.mail.fromTournAlertSignUp,
})

export default connect(mapStateToProps)(Login)
