import {
	ADMIN_ADDED_TOURNAMENT_TYPE,
	ADMIN_EDITED_TOURNAMENT_TYPE,
	ADMIN_ADDED_TOURNAMENT_TYPE_ERROR,
	ADMIN_GET_SAVED_TOURNAMENT_TYPES,
	ADMIN_GET_SAVED_TOURNAMENT_TYPES_ERROR,
	SHOW_ADMIN_VIEW_ALL_TOURNAMENTS,
	SHOW_ADMIN_VIEW_CURRENT_TOURNAMENTS,
	SHOW_ADMIN_VIEW_EXPIRED_TOURNAMENTS,
	SHOW_ADMIN_CREATE_A_TOURNAMENT,
	SHOW_ADMIN_EDIT_TOURNAMENT_TYPES,
	SHOW_ADMIN_ADD_TOURNAMENT_TYPE_FORM,
	HIDE_ADMIN_ADD_TOURNAMENT_TYPE_FORM,
	SHOW_ADMIN_EDIT_TOURNAMENT_TYPE_FORM,
	HIDE_ADMIN_EDIT_TOURNAMENT_TYPE_FORM,
	ADMIN_DELETED_TOURNAMENT_TYPE,
	ADMIN_DELETED_TOURNAMENT_TYPE_ERROR,
	// ADMIN_APPROVED_STATE_UPDATED,
	// ADMIN_APPROVED_TOURNAMENT,
	// ADMIN_DISAPPROVED_TOURNAMENT,
	LOGOUT,
} from '../actions/types'

const initialState = {
	tournamentTypes: [],
	tournamentTypeLoading: true,
	showAdminLanding: true,
	showViewAllTournaments: true,
	showCreateTournament: false,
	showEditTournamentTypes: false,
	showAddTournamentTypeForm: false,
	showEditTournamentTypeForm: false,
	showViewCurrentTournaments: false,
	showViewExpiredTournaments: false,
	error: {},
}

export default function(state = initialState, action) {
	const { type, payload } = action

	switch (type) {
		// ------------------------------------------------------------------------
		// VIEW STATES
		// ------------------------------------------------------------------------
		// View State
		case SHOW_ADMIN_VIEW_ALL_TOURNAMENTS:
			return {
				...state,
				showAdminLanding: false,
				showViewAllTournaments: true,
				showViewExpiredTournaments: false,
				showCreateTournament: false,
				showEditTournamentTypes: false,
				showViewCurrentTournaments: false,
			}

		case SHOW_ADMIN_VIEW_CURRENT_TOURNAMENTS:
			return {
				...state,
				showAdminLanding: false,
				showViewAllTournaments: false,
				showViewExpiredTournaments: false,
				showCreateTournament: false,
				showEditTournamentTypes: false,
				showViewCurrentTournaments: true,
			}
		case SHOW_ADMIN_VIEW_EXPIRED_TOURNAMENTS:
			return {
				...state,
				showAdminLanding: false,
				showViewAllTournaments: false,
				showViewExpiredTournaments: true,
				showCreateTournament: false,
				showEditTournamentTypes: false,
				showViewCurrentTournaments: false,
			}
		// View State
		case SHOW_ADMIN_CREATE_A_TOURNAMENT:
			return {
				...state,
				showAdminLanding: false,
				showViewAllTournaments: false,
				showCreateTournament: true,
				showEditTournamentTypes: false,
			}
		// View State
		case SHOW_ADMIN_EDIT_TOURNAMENT_TYPES:
			return {
				...state,
				showAdminLanding: false,
				showViewAllTournaments: false,
				showCreateTournament: false,
				showEditTournamentTypes: true,
			}
		// ------------------------------------------------------------------------
		// EDIT STATES
		// ------------------------------------------------------------------------
		// // Edit State
		// case ADMIN_APPROVED_STATE_UPDATED:
		// 	return {

		// 	}
		// // Edit State
		// case ADMIN_APPROVED_TOURNAMENT:
		// 	return {

		// 	}
		// // Edit State
		// case ADMIN_DISAPPROVED_TOURNAMENT:
		// 	return {

		// 	}
		// Edit State
		case ADMIN_ADDED_TOURNAMENT_TYPE:
			return {
				...state,
				tournamentTypes: [...state.tournamentTypes, payload],
				tournamentTypeLoading: false,
				showAddTournamentTypeForm: false,
			}
		// Edit State
		case ADMIN_GET_SAVED_TOURNAMENT_TYPES:
			return {
				...state,
				tournamentTypes: payload,
				tournamentTypeLoading: false,
			}
		// Edit State
		case ADMIN_ADDED_TOURNAMENT_TYPE_ERROR:
		case ADMIN_GET_SAVED_TOURNAMENT_TYPES_ERROR:
			return {
				...state,
				error: payload,
			}
		// case LOGOUT:
		// 	return {
		// 		tournamentTypes: [],
		// 		tournamentTypeLoading: true,
		// 	}
		// Edit State
		case SHOW_ADMIN_ADD_TOURNAMENT_TYPE_FORM:
			return {
				...state,
				showAddTournamentTypeForm: true,
			}
		// Edit State
		case HIDE_ADMIN_ADD_TOURNAMENT_TYPE_FORM:
			return {
				...state,
				showAddTournamentTypeForm: false,
			}
		// Edit State
		case SHOW_ADMIN_EDIT_TOURNAMENT_TYPE_FORM:
			return {
				...state,
				showEditTournamentTypeForm: true,
			}
		// Edit State
		case HIDE_ADMIN_EDIT_TOURNAMENT_TYPE_FORM:
			return {
				...state,
				showEditTournamentTypeForm: false,
			}
		// Edit State
		case ADMIN_EDITED_TOURNAMENT_TYPE:
			return {
				...state,
				tournamentTypeLoading: true,
			}
		case ADMIN_DELETED_TOURNAMENT_TYPE:
			return {
				...state,
				// showEditTournamentTypeForm: false,
			}
		default:
			return state
	}
}
