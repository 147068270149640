import axios from 'axios'
import { setAlert } from './alert'
import { sendMail_NewTournAlert } from './mail'
// import mongoose from 'mongoose'

import { getTournaments } from './tournament'

import {
	ADMIN_ADDED_TOURNAMENT_TYPE,
	ADMIN_ADDED_TOURNAMENT_TYPE_ERROR,
	ADMIN_EDITED_TOURNAMENT_TYPE,
	ADMIN_EDITED_TOURNAMENT_TYPE_ERROR,
	ADMIN_GET_SAVED_TOURNAMENT_TYPES,
	ADMIN_GET_SAVED_TOURNAMENT_TYPES_ERROR,
	// SHOW_ADMIN_ADD_TOURNAMENT_TYPE_FORM,
	// HIDE_ADMIN_ADD_TOURNAMENT_TYPE_FORM,
	// SHOW_ADMIN_EDIT_TOURNAMENT_TYPE_FORM,
	HIDE_ADMIN_EDIT_TOURNAMENT_TYPE_FORM,
	ADMIN_DELETED_TOURNAMENT_TYPE,
	ADMIN_DELETED_TOURNAMENT_TYPE_ERROR,
	ADMIN_APPROVED_STATE_UPDATED,
	ADMIN_APPROVED_TOURNAMENT,
	ADMIN_DISAPPROVED_TOURNAMENT,
} from './types'

export const setTournamentApproval = (id, isApproved) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}

	// console.log('id = ', id)
	// console.log('isApproved = ', isApproved)

	let data

	if (isApproved) {
		data = { approved: true }
	} else {
		data = { approved: false }
	}

	try {
		const res = await axios.post(`/api/admin/set-tournament-approval/${id}`, data, config)
		dispatch(getTournaments())
		if (isApproved) {
			dispatch(sendMail_NewTournAlert(res.data))
		}
	} catch (err) {
		console.log(err)
	}
}

export const getTournamentTypes = () => async dispatch => {
	// console.log('gotten')

	try {
		const res = await axios.get('/api/admin/tournament-types')

		dispatch({
			type: ADMIN_GET_SAVED_TOURNAMENT_TYPES,
			payload: res.data,
		})
		// dispatch(setAlert('Tournament Type Added', 'success'))
	} catch (err) {
		// console.log(err)

		dispatch({
			type: ADMIN_GET_SAVED_TOURNAMENT_TYPES_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		})
	}
}

export const addTournamentTypes = data => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}

	// console.log('tournament type data = ', data)

	const { edit } = data

	try {
		const res = await axios.post('/api/admin/tournament-types', data, config)

		if (!edit) {
			dispatch({
				type: ADMIN_ADDED_TOURNAMENT_TYPE,
				payload: res.data,
			})
		}
		if (edit) {
			dispatch({
				type: ADMIN_EDITED_TOURNAMENT_TYPE,
				payload: res.data,
			})
		}
		dispatch(getTournamentTypes())
		if (!edit) {
			dispatch(setAlert('Tournament Type Added', 'success'))
		}
		if (edit) {
			dispatch(setAlert('Tournament Type Updated', 'success'))
			dispatch({ type: HIDE_ADMIN_EDIT_TOURNAMENT_TYPE_FORM })
		}
	} catch (err) {
		if (!edit) {
			dispatch({
				type: ADMIN_ADDED_TOURNAMENT_TYPE_ERROR,
				payload: { msg: err.response.statusText, status: err.response.status },
			})
		}

		if (edit) {
			dispatch({
				type: ADMIN_EDITED_TOURNAMENT_TYPE_ERROR,
				payload: { msg: err.response.statusText, status: err.response.status },
			})
		}
	}
}

export const removeTournamentType = data => async dispatch => {
	// console.log('clicked data = ', data)
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}

	try {
		const res = await axios.post('/api/admin/remove-tournament-type', data, config)

		dispatch({
			type: ADMIN_DELETED_TOURNAMENT_TYPE,
		})

		dispatch(getTournamentTypes())
		dispatch(setAlert('Tournament Type Removed', 'success'))
	} catch (err) {
		dispatch({
			type: ADMIN_DELETED_TOURNAMENT_TYPE_ERROR,
			payload: { msg: err },
		})
	}
}
