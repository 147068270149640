import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'
import history from '../../routing/HistoryMap'

import { connect } from 'react-redux'
import Button from '../Elements/Button'
import {
	saveToUserList,
	removeSavedFromUserList,
	getSavedUserTournList,
	getTournList,
} from '../../../actions/tournament'
import { setTournamentApproval } from '../../../actions/admin'

// import { getTournamentTypes } from '../../../actions/admin'
import { TournDate, TournLocation, TournLogo, TournName, TournType } from './TournElems'
import AddToFavorites from '../../Layout/Elements/AddToFavorites'
// import { addLike, removeLike, deleteTournament } from '../../../actions/tournament'

// const options = require('../../forms/tournament/tournOptions.json')

const TournamentItem = ({
	// addLike,
	// removeLike,
	// deleteTournament,
	isAuthenticated,
	saveToUserList,
	removeSavedFromUserList,
	auth: { user },
	tournament,
	getTournList,
	isAdminArea,
	tournColor,
	setTournamentApproval,
	awaitingApproval,
}) => {
	let path = history.location.pathname
	const { _id, tourName, category, logo, eventDate, location, result_id, approved } = tournament
	// console.log('path - from TournamentItem.js = ', path)
	// console.log('!!!!!!approved = ', approved)

	const { startDate, endDate } = eventDate
	const [isAdmin, setIsAdmin] = useState(false)
	const [favExists, setFavExists] = useState(false)
	const [colorClass, setColorClass] = useState(tournColor)

	// console.log('isAdmin = ', isAdmin)
	// console.log('favExists = ', favExists)

	useEffect(() => {
		setColorClass(tournColor)
	})

	useEffect(() => {
		if (isAuthenticated) {
			// getSavedUserTournList(user._id)
			user.savedTournaments.forEach(function(elem) {
				if (elem._id === _id && !favExists) {
					// console.log(elem)
					setFavExists(true)
				}
			})
		}
	}, [isAuthenticated])

	useEffect(() => {
		if (user !== null) {
			if (user.role === 'admin') setIsAdmin(true)
		}
	}, [user])

	const setApproval = () => {
		// console.log('fired')

		setTournamentApproval(_id, approved ? false : true)
	}

	return (
		<>
			<div className={`tournament-item-container${awaitingApproval ? ' awaiting-approval' : ''}`}>
				<div className="tournament-display-number" style={{ backgroundColor: `${colorClass}` }}>
					{result_id}
				</div>

				<div className="tournament-item">
					<div className={`tournament-elems ${logo.path ? ' has-logo' : 'no-logo'}`}>
						{logo.path && (
							<div className="tournament-logo-container">
								<TournLogo logo={logo} tourName={tourName} />
							</div>
						)}
						<div className="tournament-details">
							<TournName tourName={tourName} />
							<div className="event-details">
								<TournLocation location={location} />
								<TournDate startDate={startDate} endDate={endDate} />
							</div>
						</div>
					</div>

					<div className="actions-wrapper">
						<TournType type={category} colorClass={colorClass} />
						<div className="actions-container">
							<Button
								isLink
								to={`/tournaments/${_id}`}
								variant="primary"
								block={'block'}
								size={'small'}
								label={'View Details'}
								icon={'info-circle'}
								iconType={'fas'}
							/>
							<AddToFavorites tournament={tournament} isFav={favExists} user={user} block={true} />
						</div>
					</div>
				</div>
				{isAdmin && isAdminArea && (
					<div className="admin-tourn-item-actions">
						<h6>Admin</h6>
						<div className="approved-container">
							<div className="approval-status-text">
								{approved && <>Approved</>}
								{!approved && <>Not Approved</>}
							</div>

							<Button
								variant={approved ? 'success' : 'danger'}
								size="square"
								label={approved ? 'Click to Disapprove' : 'Click to Approve'}
								icon={approved ? 'check' : 'times'}
								iconType={'fas'}
								onClick={() => setApproval()}
							/>
						</div>
					</div>
				)}
			</div>
		</>
	)
}

TournamentItem.defaultProps = {
	// showActions: true,
}

TournamentItem.propTypes = {
	tournament: PropTypes.object.isRequired,
	auth: PropTypes.object.isRequired,
	// addLike: PropTypes.func.isRequired,
	// removeLike: PropTypes.func.isRequired,
	// deleteTournament: PropTypes.func.isRequired,
	// showActions: PropTypes.bool,
	isAuthenticated: PropTypes.bool,
	getSavedUserTournList: PropTypes.func.isRequired,
	setTournamentApproval: PropTypes.func.isRequired,
	// getTournamentTypes: PropTypes.func.isRequired,
}

function savedTourn(state) {
	if (state.auth.isAuthenticated) return state.auth.user.savedTournaments
	else return null
}

const mapStateToProps = state => ({
	auth: state.auth,
	isAuthenticated: state.auth.isAuthenticated,
	savedTournaments: savedTourn(state),
})

export default connect(
	mapStateToProps,
	{ saveToUserList, removeSavedFromUserList, getSavedUserTournList, getTournList, setTournamentApproval }
	// ,{ addLike, removeLike, deleteTournament }
)(TournamentItem)
