import axios from 'axios'
import { setAlert } from './alert'
// import mongoose from 'mongoose'
import { convertToRaw, convertFromRaw } from 'draft-js'

// import zipcodes from 'zipcodes'
import { loadUser } from './auth'
import { getTournamentTypes } from './admin'
import { sendMail_NewTournAlert } from './mail'

import {
	GET_ALL_TOURNAMENTS,
	GET_CURRENT_TOURNAMENTS,
	GET_EXPIRED_TOURNAMENTS,
	TOURNAMENT_ERROR,
	UPDATE_LIKES,
	DELETE_TOURNAMENT,
	ADD_TOURNAMENT,
	PAYMENT_SUBMITTED,
	HIDE_CREATE_TOURNAMENT_FORM,
	GET_TOURNAMENT,
	ADD_COMMENT,
	REMOVE_COMMENT,
	MAP_CENTER,
	SEARCH_TOURNAMENTS,
	SEARCH_TOURNAMENTS_ERROR,
	USER_SAVED_TOURNAMENT,
	USER_SAVED_TOURNAMENT_ERROR,
	USER_GET_SAVED_TOURNAMENT_ID,
	USER_GET_SAVED_TOURNAMENT_ID_ERROR,
	USER_GET_SAVED_TOURNAMENT_ALL_DATA,
	USER_GET_SAVED_TOURNAMENT_ALL_DATA_ERROR,
	USER_REMOVED_TOURN_FROM_SAVED,
	USER_REMOVED_TOURN_FROM_SAVED_ERROR,
	SEARCH_MISPELLED_CITY,
	SEARCH_HAS_CITY_NO_STATE,
	SEARCH_HAS_STATE_NO_CITY,
	EXIT_EDIT_TOURNAMENT,
	SEARCH_NO_RESULTS,
} from './types'

// Search tournaments
export const searchTournaments = (data) => async (dispatch) => {
	// console.log('ACTIONS req.data = ', data)

	if (data.city && !data.state) {
		dispatch({
			type: SEARCH_HAS_CITY_NO_STATE,
			payload: new Error(),
			error: true,
		})
		dispatch(setAlert('Please Select a State'))
	} else if (data.state && !data.city) {
		dispatch({
			type: SEARCH_HAS_STATE_NO_CITY,
			payload: new Error(),
			error: true,
		})
		dispatch(setAlert('Please Select a City'))
	} else {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}
		try {
			const res = await axios.post('/api/tournaments/search', data, config)
			// If search is mispelled
			if (res.data.message === 'mispelled') {
				dispatch({
					type: SEARCH_MISPELLED_CITY,
					payload: res.data,
				})
				dispatch(
					setAlert('Can not locate your selected city, please check the spelling and try again.', 'danger')
				)
			}
			// If search is spelled correctly yet yields no tournament results
			else if (res.data.message === 'no-results') {
				dispatch({
					type: SEARCH_NO_RESULTS,
					payload: true,
				})
				dispatch({
					type: MAP_CENTER,
					payload: res.data.mapCenter,
				})
				dispatch(setAlert('No Results Found', 'danger'))
			}
			// If search is spelled correctly and has tournaments in result
			else {
				dispatch({
					type: SEARCH_TOURNAMENTS,
					payload: res.data.tournamentList,
				})
				dispatch({
					type: MAP_CENTER,
					payload: res.data.mapCenter,
				})
				dispatch(getTournamentTypes())
			}
		} catch (err) {
			dispatch({
				type: SEARCH_TOURNAMENTS_ERROR,
				payload: { msg: err },
			})
		}
	}
}

export const addTournament = (data, edit = false) => async (dispatch) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}

	// console.log('data = ', data)

	const jsonData = JSON.stringify(convertToRaw(data.description))
	data.description = jsonData

	try {
		const res = await axios.post('/api/tournaments', data, config)
		dispatch({
			type: ADD_TOURNAMENT,
			payload: res.data,
		})
		if (edit) {
			const convertedDescriptionData = convertFromRaw(JSON.parse(res.data.description))
			res.data.description = convertedDescriptionData
			dispatch({ type: EXIT_EDIT_TOURNAMENT })
			dispatch({
				type: GET_TOURNAMENT,
				payload: res.data,
			})
			dispatch(setAlert('Tournament Updated', 'success'))
		} else {
			// dispatch({ type: PAYMENT_SUBMITTED })
			dispatch({ type: HIDE_CREATE_TOURNAMENT_FORM })
			dispatch(setAlert('Tournament Created', 'success'))
			// dispatch(sendMail_NewTournAlert(res.data)) // Don't send at this point - It is prior to approval
		}
	} catch (err) {
		const errors = err.response.data.errors

		if (errors) {
			errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
		}
		dispatch({
			type: TOURNAMENT_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		})
	}
}

// Get tournaments
export const getTournaments = (filter) => async (dispatch) => {
	const q = {
		params: {
			filter: filter,
		},
	}

	try {
		const res = await axios.get('/api/tournaments', q)
		if (filter === 'current') {
			dispatch({
				type: GET_CURRENT_TOURNAMENTS,
				payload: res.data,
			})
		} else if (filter === 'expired') {
			dispatch({
				type: GET_EXPIRED_TOURNAMENTS,
				payload: res.data,
			})
		} else {
			dispatch({
				type: GET_ALL_TOURNAMENTS,
				payload: res.data,
			})
		}
	} catch (err) {
		dispatch({
			type: TOURNAMENT_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		})
	}
}

// Get tournament
export const getTournament = (id) => async (dispatch) => {
	// console.log(id)
	try {
		const res = await axios.get(`/api/tournaments/${id}`)
		const convertedDescriptionData = convertFromRaw(JSON.parse(res.data.description))
		res.data.description = convertedDescriptionData

		dispatch({
			type: GET_TOURNAMENT,
			payload: res.data,
		})
	} catch (err) {
		dispatch({
			type: TOURNAMENT_ERROR,
			payload: { msg: err, status: err },
		})
	}
}

// TODO: Update get user tournaments with current and expired filtering -> Reference getTournaments

// Get Users owned tournament(s)
export const getUserTournaments = (id, filter) => async (dispatch) => {
	const q = {
		params: {
			filter: filter,
		},
	}
	try {
		const res = await axios.get(`/api/tournaments/user/${id}`, q)

		if (filter === 'current') {
			dispatch({
				type: GET_CURRENT_TOURNAMENTS,
				payload: res.data,
			})
		} else if (filter === 'expired') {
			dispatch({
				type: GET_EXPIRED_TOURNAMENTS,
				payload: res.data,
			})
		} else {
			dispatch({
				type: GET_ALL_TOURNAMENTS,
				payload: res.data,
			})
		}
	} catch (err) {
		dispatch({
			type: TOURNAMENT_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		})
	}
}

//
//
//
//
//
//
// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
// TOURNAMENTS TO FAV
// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------

// Save Tournament to User List
export const saveToUserList = (userId, data) => async (dispatch) => {
	// console.log('userId = ', userId)
	// console.log('userId = ', data)

	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}

	try {
		const res = await axios.post(`/api/tournaments/save-tournament/${userId}`, data, config)

		dispatch({
			type: USER_SAVED_TOURNAMENT,
			payload: res.data,
		})

		dispatch(loadUser())
		dispatch(setAlert('Tournament Saved', 'success'))
	} catch (err) {
		dispatch({
			type: USER_SAVED_TOURNAMENT_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		})
	}
}

export const removeSavedFromUserList = (userId, data) => async (dispatch) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}

	const res = await axios.post(`/api/tournaments/unsave-tournament/${userId}`, data, config)

	try {
		dispatch({
			type: USER_REMOVED_TOURN_FROM_SAVED,
			payload: res.data,
		})

		dispatch(loadUser())
		dispatch(setAlert('Tournament Removed', 'success'))
	} catch (err) {
		dispatch({
			type: USER_REMOVED_TOURN_FROM_SAVED_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		})
	}
}
//
//
//
//
//
//
//
//
//
//
//
// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
// GETTING TOURNAMENT FAVs
// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------

export const getSavedUserTournList = (id) => async (dispatch) => {
	// console.log('getSavedUserTournList fired')

	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}

	try {
		const res = await axios.get(`/api/users/saved/${id}`, config)
		// console.log('------action res', res)

		dispatch({
			type: USER_GET_SAVED_TOURNAMENT_ID,
			payload: res.data,
		})
	} catch (err) {
		dispatch({
			type: USER_GET_SAVED_TOURNAMENT_ID_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		})
	}
}
//
//
// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
//
//
export const getTournList = (id) => async (dispatch) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}

	try {
		const res = await axios.get(`/api/tournaments/tournamentlist/${id}`, config)
		// console.log(res)

		dispatch({
			type: USER_GET_SAVED_TOURNAMENT_ALL_DATA,
			payload: res.data,
		})
	} catch (err) {
		dispatch({
			type: USER_GET_SAVED_TOURNAMENT_ALL_DATA_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		})
	}
}
//
//
//
//
//
//
//
//
//
//
//
// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
// TOURNAMENT Stuff
// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
// Add like
export const addLike = (id) => async (dispatch) => {
	try {
		const res = await axios.put(`/api/tournaments/like/${id}`)

		dispatch({
			type: UPDATE_LIKES,
			payload: { id, likes: res.data },
		})
	} catch (err) {
		dispatch({
			type: TOURNAMENT_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		})
	}
}

// Remove like
export const removeLike = (id) => async (dispatch) => {
	try {
		const res = await axios.put(`/api/tournaments/unlike/${id}`)

		dispatch({
			type: UPDATE_LIKES,
			payload: { id, likes: res.data },
		})
	} catch (err) {
		dispatch({
			type: TOURNAMENT_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		})
	}
}

// Delete Tournament
export const deleteTournament = (id) => async (dispatch) => {
	try {
		await axios.delete(`/api/tournaments/${id}`)

		dispatch({
			type: DELETE_TOURNAMENT,
			payload: id,
		})

		dispatch(setAlert('Tournament Removed', 'success'))
	} catch (err) {
		dispatch({
			type: TOURNAMENT_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		})
	}
}

// Add comment
export const addComment = (tournamentId, formData) => async (dispatch) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}

	try {
		const res = await axios.post(`/api/tournaments/comment/${tournamentId}`, formData, config)

		dispatch({
			type: ADD_COMMENT,
			payload: res.data,
		})

		dispatch(setAlert('Comment Added', 'success'))
	} catch (err) {
		dispatch({
			type: TOURNAMENT_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		})
	}
}

// Delete comment
export const deleteComment = (tournamentId, commentId) => async (dispatch) => {
	try {
		await axios.delete(`/api/tournaments/comment/${tournamentId}/${commentId}`)

		dispatch({
			type: REMOVE_COMMENT,
			payload: commentId,
		})

		dispatch(setAlert('Comment Removed', 'success'))
	} catch (err) {
		dispatch({
			type: TOURNAMENT_ERROR,
			payload: { msg: err.response.statusText, status: err.response.status },
		})
	}
}
