import React from 'react'
// import React, { Fragment } from 'react'
import Navbar from './Navbar'
import { connect, useDispatch } from 'react-redux'
import TournAlertSignUp from '../Layout/TournAlertSignUp'
import PropTypes from 'prop-types'
import InfoBox from '../Layout/Elements/InfoBox'
import Button from '../Layout/Elements/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { TOURNAMENT_ALERT_SIGNUP_OPEN } from '../../actions/types'

const Header = ({
	registeredForTournamentAlerts,
	showTournamentAlertSignUp,
	signupSuccess,
	tournamentAlertSignUpModalDismissed,
	isAuthenticated,
}) => {
	const dispatch = useDispatch()
	const tournAlertSignUpDisplay = () => {
		// setShowSignUp(true)
		dispatch({
			type: TOURNAMENT_ALERT_SIGNUP_OPEN,
		})
	}

	// When user registers for an account trigger tournament alert sign up to display
	if (
		signupSuccess &&
		!showTournamentAlertSignUp &&
		!registeredForTournamentAlerts &&
		!tournamentAlertSignUpModalDismissed
	) {
		tournAlertSignUpDisplay()
	}

	// when user clicks link trigger tournament alert sign up to display
	const handleButtonClick = () => {
		tournAlertSignUpDisplay()
	}

	// if (!showTournamentAlertSignUp) {
	// 	tournAlertSignUpDisplay()
	// }

	return (
		<>
			<header className="site-header">
				<div className="header-upper">
					<Navbar />
				</div>
				<div className="header-lower">
					{!registeredForTournamentAlerts && isAuthenticated && (
						<InfoBox variant="primary" noIcon noRadius noBorder noShadow size="small">
							{/* <span className="icon-container">
							<FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'trophy' }} />
						</span> */}
							<p>Be the first to know about new tournaments in your area!</p>
							<p className="link-container">
								<span className="icon-container">
									<FontAwesomeIcon icon={{ prefix: 'far', iconName: 'arrow-circle-right' }} />
								</span>
								<Button
									variant="link"
									label="Sign Up For Free Tournament Alerts"
									onClick={() => handleButtonClick()}
								/>
							</p>
						</InfoBox>
					)}
				</div>
				{showTournamentAlertSignUp && <TournAlertSignUp show={showTournamentAlertSignUp} />}
			</header>
		</>
	)
}

Header.propTypes = {
	showTournamentAlertSignUp: PropTypes.bool,
	signupSuccess: PropTypes.bool,
	registeredForTournamentAlerts: PropTypes.bool,
	tournamentAlertSignUpModalDismissed: PropTypes.bool,
	isAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => ({
	showTournamentAlertSignUp: state.mail.showTournamentAlertSignUp,
	signupSuccess: state.auth.signupSuccess,
	registeredForTournamentAlerts: state.auth.registeredForTournamentAlerts,
	tournamentAlertSignUpModalDismissed: state.mail.tournamentAlertSignUpModalDismissed,
	isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps)(Header)
