import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const AdminRoute = ({ component: Component, auth, auth: { isAuthenticated, role, authLoading }, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props =>
				!isAuthenticated && !authLoading ? (
					<Redirect to="/login" />
				) : !authLoading && role !== 'admin' ? (
					<Redirect to="/dashboard" />
				) : (
					<Component {...props} />
				)
			}
		/>
	)
}

AdminRoute.propTypes = {
	auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
	auth: state.auth,
})

export default connect(mapStateToProps)(AdminRoute)
