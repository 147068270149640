import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gsap } from 'gsap'

// TODO: Rework to destructure props

const Button = ({
	isInput,
	label,
	variant,
	outline,
	size,
	block,
	className,
	type,
	onClick,
	isLink,
	to,
	iconOnly,
	icon,
	iconType,
	iconSize,
	isAnchor,
	cursor,
	disabled,
	tooltip,
}) => {
	const tooltipRef = useRef()
	const [hovering, setHovering] = useState(false)

	const animationCompleteCallback = () => {
		setHovering(false)
	}

	const handleMouseEnter = e => {
		setHovering(true)
	}
	const handleMouseLeave = () => {
		gsap.to(tooltipRef.current, {
			duration: 0.25,
			ease: 'power3.in',
			opacity: 0,
			onComplete: () => setHovering(false),
		})
	}

	useEffect(() => {
		if (hovering && tooltipRef.current) {
			gsap.to(tooltipRef.current, { duration: 0.25, ease: 'power3.out', opacity: 1 })
		}
	}, [hovering])

	if (isInput) {
		return (
			<input
				defaultValue={label}
				// prettier-ignore
				className={`btn btn-${variant}${outline ? '-outline ' : ' ' }${size ? 'btn-' + size + ' ': ''}${block ? block  + ' ': ''}${className ? className + ' ' : ''}${disabled ? ' disabled ' : ''}`}
				type={type}
				disabled={disabled ? true : false}
				onClick={onClick}
			/>
		)
	} else if (isLink)
		return (
			<Link
				to={to}
				// prettier-ignore
				className={`btn btn-${variant}${outline ? '-outline ' : ' ' }${size ? 'btn-' + size + ' ': ''}${block ? block  + ' ': ''}${className ? className + ' ' : ''}${iconOnly ? ' icon-only' : ''}${disabled ? ' disabled ' : ''}${tooltip ? ' tooltip_parent ' : ''}`}
				type={type}
				disabled={disabled ? true : false}
				onClick={onClick}
				onMouseEnter={e => handleMouseEnter(e)}
				onMouseLeave={e => handleMouseLeave(e)}
			>
				{icon ? (
					<div className="btn-icon-layout">
						<FontAwesomeIcon
							icon={{ prefix: iconType ? iconType : 'fas', iconName: icon }}
							size={iconSize ? iconSize : null}
						/>
						{iconOnly ? null : <span className="btn-text">{label}</span>}
					</div>
				) : (
					<span className="btn-text">{label}</span>
				)}
				{tooltip && hovering && (
					<div ref={tooltipRef} className="tooltip tooltip_btn" style={{ opacity: 0 }}>
						<div className="icon-container">
							<FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'info-circle' }} />
						</div>
						<div className="tooltip-text">{tooltip}</div>
					</div>
				)}
			</Link>
		)
	else if (isAnchor)
		return (
			<a
				href={to}
				// prettier-ignore
				className={`btn btn-${variant}${outline ? '-outline ' : ' ' }${size ? 'btn-' + size + ' ': ''}${block ? block  + ' ': ''}${className ? className + ' ' : ''}${iconOnly ? ' icon-only' : ''}${disabled ? ' disabled ' : ''}`}
				type={type}
				disabled={disabled ? true : false}
				onClick={onClick}
			>
				{icon ? (
					<div className="btn-icon-layout">
						<FontAwesomeIcon
							icon={{ prefix: iconType ? iconType : 'fas', iconName: icon }}
							size={iconSize ? iconSize : null}
						/>
						{iconOnly ? null : <span className="btn-text">{label}</span>}
					</div>
				) : (
					<span className="btn-text">{label}</span>
				)}
				{tooltip && <div className="tooltip tooltip_btn">{tooltip}</div>}
			</a>
		)
	else {
		return (
			<button
				// prettier-ignore
				className={`btn btn-${variant}${outline ? '-outline' : ''} ${size ? 'btn-' + size + ' ': ''}${block ? block  + ' ': ''}${className ? className + ' ' : ''}${iconOnly ? ' icon-only' : ''}${disabled ? ' disabled ' : ''}`}
				disabled={disabled ? true : false}
				onClick={onClick}
				style={cursor ? { cursor: cursor } : null}
			>
				{icon ? (
					<div className="btn-icon-layout">
						<FontAwesomeIcon
							icon={{ prefix: iconType ? iconType : 'fas', iconName: icon }}
							size={iconSize ? iconSize : null}
						/>
						{iconOnly ? null : <span className="btn-text">{label}</span>}
					</div>
				) : (
					<span className="btn-text">{label}</span>
				)}
			</button>
		)
	}
}

export default Button
