import React, { useEffect, useState, Fragment } from 'react'
import { Link, withRouter, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createProfile, getCurrentProfile } from '../../../actions/profile'
import InnerStructure from '../../Layout/InnerStructure'
// import Spinner from '../effects/loading/Spinner'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Createprofile = ({ createProfile, getCurrentProfile, profile: { profile, profileLoading }, history }) => {
	const [formData, setFormData] = useState({
		zip: '',
		bio: '',
		twitter: '',
		facebook: '',
		youtube: '',
		instagram: '',
	})
	const [displaySocialInputs, toggleSocialInputs] = useState(false)
	const { zip, bio, twitter, facebook, youtube, instagram } = formData
	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })
	const onSubmit = e => {
		e.preventDefault()
		createProfile(formData, history)
	}
	useEffect(() => {
		getCurrentProfile()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getCurrentProfile])
	return profileLoading && profile === null ? (
		<Redirect to="/dashboard" />
	) : (
		<InnerStructure>
			<h1 className="view-title">Create Your Profile</h1>

			<form className="form" onSubmit={e => onSubmit(e)}>
				<div class="form-info form-header form-rules">
					<p className="lead">
						<i className="fas fa-user" />
						We want your experience with us to be enjoyable. <br />
						We have added features to this site to assist you with quickly and easily finding the
						tournaments you're looking for.
					</p>
					<small>* = required field</small>
				</div>
				<div className="form-group">
					<input type="text" placeholder="Zip" name="zip" value={zip} onChange={e => onChange(e)} />
					<div className="info-text">
						We base our tournament location searches off of zip codes. By providing your zip code we can:
						<ul>
							<li>Auto-fill tournament search forms for your convience.</li>
							<li>
								Send alerts for new tournaments in your area if you have signed up for tournament
								alerts.
							</li>
						</ul>
					</div>
				</div>
				<div className="form-group">
					<textarea
						placeholder="A short bio of yourself"
						name="bio"
						value={bio}
						onChange={e => onChange(e)}
					/>
					<small className="form-text">Tell us a little about yourself</small>
				</div>

				<div className="my-2">
					<button
						onClick={() => toggleSocialInputs(!displaySocialInputs)}
						type="button"
						className="btn btn-light"
					>
						Add Social Network Links
					</button>
					<span>Optional</span>
				</div>
				{displaySocialInputs && (
					<Fragment>
						<div className="form-group social-input">
							<i className="fab fa-twitter fa-2x" />
							<input
								type="text"
								placeholder="Twitter URL"
								name="twitter"
								value={twitter}
								onChange={e => onChange(e)}
							/>
						</div>

						<div className="form-group social-input">
							<i className="fab fa-facebook fa-2x" />
							<input
								type="text"
								placeholder="Facebook URL"
								name="facebook"
								value={facebook}
								onChange={e => onChange(e)}
							/>
						</div>

						<div className="form-group social-input">
							<i className="fab fa-youtube fa-2x" />
							<input
								type="text"
								placeholder="YouTube URL"
								name="youtube"
								value={youtube}
								onChange={e => onChange(e)}
							/>
						</div>

						<div className="form-group social-input">
							<i className="fab fa-instagram fa-2x" />
							<input
								type="text"
								placeholder="Instagram URL"
								name="instagram"
								value={instagram}
								onChange={e => onChange(e)}
							/>
						</div>
					</Fragment>
				)}

				<input type="submit" className="btn btn-primary my-1" />
				<Link className="btn btn-light my-1" to="/dashboard">
					Go Back
				</Link>
			</form>
		</InnerStructure>
	)
}

Createprofile.propTypes = {
	createProfile: PropTypes.func.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
	profile: state.profile,
})
export default connect(mapStateToProps, { createProfile, getCurrentProfile })(withRouter(Createprofile))
