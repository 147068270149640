import React, { useEffect } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import history from './components/routing/HistoryMap'
import ReactGA from 'react-ga'
import Landing from './components/Layout/Landing/Landing'
import Routes from './components/routing/Routes'
import Alert from './components/Alert'
import SiteWrapper from './components/Layout/SiteWrapper'

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faExclamationTriangle,
	faThumbsUp,
	faInfoCircle,
	faFish,
	faBold,
	faSignOutAlt,
	faTimesCircle,
	faTimesOctagon,
	faEnvelope,
} from '@fortawesome/pro-solid-svg-icons'
import {
	faCheckCircle,
	faPlus,
	faArrowCircleRight,
	faCaretCircleUp,
	faArrowAltDown,
	faArrowAltRight,
	faGripVertical,
} from '@fortawesome/pro-regular-svg-icons'

import { faUser, faTrophy } from '@fortawesome/pro-light-svg-icons'

import {
	faCheckSquare,
	faSquare,
	faTriangle,
	faBadgerHoney,
	faEyeSlash,
	faEye,
	faGlobe,
	faEnvelopeOpenText,
	faPhone,
	faSignInAlt,
	faEdit,
} from '@fortawesome/pro-duotone-svg-icons'

import { faTwitterSquare, faFacebookSquare, faYoutubeSquare, faInstagram } from '@fortawesome/free-brands-svg-icons'

// Redux
import { Provider } from 'react-redux'
import store from './store'
import { loadUser } from './actions/auth'
import setAuthToken from './utils/setAuthToken'

import './styles/less/App.css'

// library.add(fas, far, fal, fad, fab)
library.add(
	faExclamationTriangle,
	faThumbsUp,
	faFish,
	faBold,
	faSignOutAlt,
	faTimesCircle,
	faCheckSquare,
	faSquare,
	faTriangle,
	faArrowAltRight,
	faBadgerHoney,
	faEyeSlash,
	faEye,
	faGlobe,
	faEnvelopeOpenText,
	faPhone,
	faCheckCircle,
	faPlus,
	faArrowCircleRight,
	faCaretCircleUp,
	faArrowAltDown,
	faArrowAltRight,
	faGripVertical,
	faUser,
	faTrophy,
	faTwitterSquare,
	faFacebookSquare,
	faYoutubeSquare,
	faInstagram,
	faTimesOctagon,
	faEnvelope,
	faInfoCircle,
	faSignInAlt,
	faEdit
)

if (localStorage.token) {
	setAuthToken(localStorage.token)
}

ReactGA.initialize('UA-159678985-1')

history.listen((location) => {
	ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname)
})

const App = () => {
	useEffect(() => {
		store.dispatch(loadUser())
	}, [])

	return (
		<Provider store={store}>
			<Router history={history}>
				<SiteWrapper>
					<div className="message-container">
						<Alert />
					</div>

					<Switch>
						<Route exact path="/" component={Landing} />
						<Route component={Routes} />
					</Switch>
				</SiteWrapper>
			</Router>
		</Provider>
	)
}

export default App
