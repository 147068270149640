import React from 'react'

const Colors = () => {
	return (
		<>
			<div className="color-wrapper">
				<section className="hues-container">
					<h3>Hues</h3>
					<div className="hues">
						<section className="color color-red">
							<h4>Red</h4>
							<div className="swatch-block">
								<div className="swatch"></div>
								<div className="swatch"></div>
								<div className="swatch"></div>
								<div className="swatch"></div>
								<div className="swatch"></div>
							</div>
						</section>
						<section className="color color-gold">
							<h4>Gold</h4>
							<div className="swatch-block">
								<div className="swatch"></div>
								<div className="swatch"></div>
								<div className="swatch"></div>
								<div className="swatch"></div>
								<div className="swatch"></div>
							</div>
						</section>
						<section className="color color-blue">
							<h4>Blue</h4>
							<div className="swatch-block">
								<div className="swatch"></div>
								<div className="swatch"></div>
								<div className="swatch"></div>
								<div className="swatch"></div>
								<div className="swatch"></div>
							</div>
						</section>
						<section className="color color-green">
							<h4>Green</h4>
							<div className="swatch-block">
								<div className="swatch"></div>
								<div className="swatch"></div>
								<div className="swatch"></div>
								<div className="swatch"></div>
								<div className="swatch"></div>
							</div>
						</section>
						<section className="color color-purple">
							<h4>Purple</h4>
							<div className="swatch-block">
								<div className="swatch"></div>
								<div className="swatch"></div>
								<div className="swatch"></div>
								<div className="swatch"></div>
								<div className="swatch"></div>
							</div>
						</section>
					</div>
					<h3>Greyscale</h3>
					<div className="greyscale">
						<div className="swatch-block color-black">
							<div className="swatch"></div>
							<div className="swatch"></div>
							<div className="swatch"></div>
							<div className="swatch"></div>
							<div className="swatch"></div>
							<div className="swatch"></div>
							<div className="swatch"></div>
							<div className="swatch"></div>
							<div className="swatch"></div>
						</div>
						<div className="swatch-block color-white">
							<div className="swatch"></div>
							<div className="swatch"></div>
							<div className="swatch"></div>
							<div className="swatch"></div>
							<div className="swatch"></div>
							<div className="swatch"></div>
							<div className="swatch"></div>
							<div className="swatch"></div>
							<div className="swatch"></div>
						</div>
					</div>
				</section>
				<section className="gradients-container">
					<h3>Gradients</h3>
					<div className="gradients">
						<div className="swatch-block">
							<div className="swatch">background: @gr_1;</div>
							<div className="swatch">background: @gr_2;</div>
							<div className="swatch">background: @gr_3;</div>
							<div className="swatch">background: @gr_4;</div>
							<div className="swatch">background: @gr_5;</div>
							<div className="swatch">background: @gr_6;</div>
						</div>
						<div className="swatch-block">
							<div className="swatch">background: @grad_lb;</div>
							<div className="swatch">background: @gr_7;</div>
							<div className="swatch">background: @gr_8;</div>
							<div className="swatch">background: @gr_9;</div>
							<div className="swatch">background: @gr_10;</div>
							<div className="swatch">background: @gr_11;</div>
						</div>
						<div className="swatch-block">
							<div className="swatch">background: @gr_12;</div>
							<div className="swatch">background: @gr_13;</div>
							<div className="swatch">background: @gr_14;</div>
							<div className="swatch">background: @gr_15;</div>
							<div className="swatch">background: @gr_16;</div>
							<div className="swatch">background: @gr_17;</div>
						</div>
						<div className="swatch-block">
							<div className="swatch">background: @gr_18;</div>
							<div className="swatch">background: @gr_19;</div>
							<div className="swatch">background: @gr_20;</div>
							<div className="swatch">background: @gr_21;</div>
							<div className="swatch">background: @gr_22;</div>
							<div className="swatch">background: @gr_23;</div>
						</div>
					</div>
				</section>
			</div>
		</>
	)
}

export default Colors
