import React, { useEffect, useState } from 'react'
import Button from '../../Elements/Button'
import { Link } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { TOURNAMENT_ALERT_SIGNUP_OPEN } from '../../../../actions/types'

import TournAlertSignUp from '../../TournAlertSignUp'

const GetTournAlertsCallout = ({ showTournamentAlertSignUp, tournAlertRegistered }) => {
	const dispatch = useDispatch()
	// const [showSignUp, setShowSignUp] = useState(false)

	const tournAlertSignUpDisplay = () => {
		// setShowSignUp(true)
		dispatch({
			type: TOURNAMENT_ALERT_SIGNUP_OPEN,
		})
	}
	return (
		<>
			<div className="callout-container">
				<h3 className="callout-title">Get Tournament Alerts!</h3>
				{!tournAlertRegistered && (
					<p>
						Be the first to find out about <span className="emphasis">NEW</span> tournaments in your area
						with FindFishingTournaments.com's Tournament Alert Platform!
					</p>
				)}
				{tournAlertRegistered && (
					<p>
						Looks Like you have already signed up to recieve FindFishingTournaments.com's Tournament Alerts.
						You can update your settings in the section below.
					</p>
				)}
				<Button
					variant="primary"
					// size={'large'}
					outline="true"
					label={tournAlertRegistered ? 'Edit My Alert Settings' : 'Sign Up'}
					outline={true}
					onClick={() => tournAlertSignUpDisplay()}
				/>
			</div>
			{/* {showTournamentAlertSignUp && <TournAlertSignUp show={showTournamentAlertSignUp} />} */}
		</>
	)
}

GetTournAlertsCallout.propTypes = {
	showTournamentAlertSignUp: PropTypes.bool,
	tournAlertRegistered: PropTypes.bool,
}

const mapStateToProps = state => ({
	showTournamentAlertSignUp: state.mail.showTournamentAlertSignUp,
	tournAlertRegistered: state.mail.tournAlertRegistered,
})

export default connect(mapStateToProps)(GetTournAlertsCallout)
