import {
	GET_ALL_TOURNAMENTS,
	GET_CURRENT_TOURNAMENTS,
	GET_EXPIRED_TOURNAMENTS,
	SEARCH_TOURNAMENTS,
	SEARCH_TOURNAMENTS_ERROR,
	TOURNAMENT_ERROR,
	UPDATE_LIKES,
	DELETE_TOURNAMENT,
	SHOW_TOURNAMENT_LEVEL_OPTIONS,
	HIDE_TOURNAMENT_LEVEL_OPTIONS,
	SHOW_CREATE_TOURNAMENT_FORM,
	HIDE_CREATE_TOURNAMENT_FORM,
	ADD_TOURNAMENT,
	GET_TOURNAMENT,
	ADD_COMMENT,
	REMOVE_COMMENT,
	TOURNAMENT_SUBMISSION_COMPLETE,
	SEARCH_MISPELLED_CITY,
	SEARCH_HAS_CITY_NO_STATE,
	SEARCH_HAS_STATE_NO_CITY,
	CLEAR_TOURNAMENTS,
	CLEAR_TOURNAMENT,
	TOURNAMENT_UPDATE_LEVEL,
	EDIT_TOURNAMENT,
	EXIT_EDIT_TOURNAMENT,
	MAP_CENTER,
	SEARCH_NO_RESULTS,
	CLEAR_VALIDATION,
	LOGOUT,
} from '../actions/types'

const initialState = {
	tournaments: [],
	tournament: null,
	tournamentAdded: false,
	tournamentLoading: true,
	searchError: false,
	searchErrorMessage: '',
	tournamentLevel: 'free',
	tournamentEdit: false,
	emptySearch: false,
	searchMade: false,
	searchMapCenter: {},
	showTournamentOptions: false,
	showCreateTournamentForm: false,
	error: {},
}

export default function(state = initialState, action) {
	const { type, payload } = action

	// console.log('payload = ', payload)

	switch (type) {
		case CLEAR_VALIDATION:
			return {
				...state,
				searchErrorMessage: '',
				searchError: false,
			}
		case TOURNAMENT_SUBMISSION_COMPLETE:
			return {
				...state,
				tournamentAdded: false,
				showTournamentOptions: true,
			}
		case TOURNAMENT_UPDATE_LEVEL:
			return {
				...state,
				tournamentLevel: payload,
			}
		case EDIT_TOURNAMENT:
			return {
				...state,
				tournamentEdit: true,
			}
		case EXIT_EDIT_TOURNAMENT:
			return {
				...state,
				tournamentEdit: false,
			}
		case CLEAR_TOURNAMENTS:
			return {
				...state,
				tournaments: [],
			}
		case CLEAR_TOURNAMENT:
			return {
				...state,
				tournament: null,
				tournamentLevel: 'free',
			}
		case GET_ALL_TOURNAMENTS:
		case GET_CURRENT_TOURNAMENTS:
		case GET_EXPIRED_TOURNAMENTS:
			return {
				...state,
				tournaments: payload,
				searchError: false,
				searchErrorMessage: '',
				tournamentLoading: false,
			}
		case SEARCH_TOURNAMENTS:
			return {
				...state,
				tournaments: payload,
				searchError: false,
				searchErrorMessage: '',
				tournamentLoading: false,
				searchMade: true,
				emptySearch: false,
			}
		case MAP_CENTER:
			return {
				...state,
				searchMapCenter: payload,
			}
		case SEARCH_MISPELLED_CITY:
			return {
				...state,
				searchError: true,
				searchErrorMessage: 'spelling_city',
			}
		case SEARCH_HAS_CITY_NO_STATE:
			return {
				...state,
				searchError: true,
				searchErrorMessage: 'no_state',
			}
		case SEARCH_HAS_STATE_NO_CITY:
			return {
				...state,
				searchError: true,
				searchErrorMessage: 'no_city',
			}
		case SEARCH_NO_RESULTS:
			return {
				...state,
				emptySearch: payload,
				searchMade: true,
			}
		case GET_TOURNAMENT:
			return {
				...state,
				tournament: payload,
				tournamentLoading: false,
				tournamentLevel: payload.paymentLevel,
			}
		case SHOW_TOURNAMENT_LEVEL_OPTIONS:
			return {
				...state,
				showTournamentOptions: true,
			}
		case HIDE_TOURNAMENT_LEVEL_OPTIONS:
			return {
				...state,
				showTournamentOptions: false,
			}
		case SHOW_CREATE_TOURNAMENT_FORM:
			return {
				...state,
				showCreateTournamentForm: true,
			}
		case HIDE_CREATE_TOURNAMENT_FORM:
			return {
				...state,
				showCreateTournamentForm: false,
			}
		case ADD_TOURNAMENT:
			return {
				...state,
				tournaments: [...state.tournaments, payload],
				tournamentAdded: true,
				tournamentLoading: false,
			}
		case DELETE_TOURNAMENT:
			return {
				...state,
				tournaments: state.tournaments.filter(tournament => tournament._id !== payload),
				tournamentLoading: false,
			}
		case TOURNAMENT_ERROR:
		case SEARCH_TOURNAMENTS_ERROR:
			return {
				...state,
				error: payload,
				tournamentLoading: false,
			}
		case UPDATE_LIKES:
			return {
				...state,
				tournaments: state.tournaments.map(tournament =>
					tournament._id === payload.id ? { ...tournament, likes: payload.likes } : tournament
				),
				tournamentLoading: false,
			}
		case ADD_COMMENT:
			return {
				...state,
				tournament: { ...state.tournament, comments: payload },
				tournamentLoading: false,
			}
		case REMOVE_COMMENT:
			return {
				...state,
				tournament: {
					...state.tournament,
					comments: state.tournament.comments.filter(comment => comment._id !== payload),
				},
				tournamentLoading: false,
			}
		case LOGOUT:
			return {
				...state,
				tournaments: [],
				// tournament: null,
				tournamentAdded: false,
				// tournamentLoading: true,
				searchError: false,
				searchErrorMessage: '',
				tournamentLevel: 'free',
				tournamentEdit: false,
				emptySearch: false,
				searchMade: false,
			}

		default:
			return state
	}
}
