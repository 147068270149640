import React from 'react'
// import PropTypes from 'prop-types'
import { Elements } from 'react-stripe-elements'
import CheckoutForm from '../forms/checkout/CheckoutForm'

const Checkout = ({ orderData }) => {
	return (
		<>
			{/* <h1>Checkout</h1> */}
			<Elements>
				<CheckoutForm orderData={orderData} />
			</Elements>
		</>
	)
}

// Checkout.propTypes = {}

export default Checkout
