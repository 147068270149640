import { combineReducers } from 'redux'
import alert from './alert'
import auth from './auth'
import profile from './profile'
import tournament from './tournament'
import userItems from './userItems'
import admin from './admin'
import payment from './payment'
import mail from './mail'
import dashboard from './dashboard'
import userInterface from './userInterface'

export default combineReducers({
	alert,
	auth,
	profile,
	tournament,
	userItems,
	admin,
	payment,
	mail,
	dashboard,
	userInterface,
})
