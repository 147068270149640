import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FontItem = ({ font, data, tag, useTSize, usePSize, usePLineHeight, showT, showP }) => {
	const CustomTag = tag

	return (
		<div className="font-list-item">
			<div className="font-class-name">.{font}</div>
			<div className="font-container">
				<div className={font}>
					{showT && (
						<div className="title-container">
							<CustomTag className={font} style={{ fontSize: useTSize ? data.tSize : null }}>
								{data.tText}
							</CustomTag>
						</div>
					)}

					{showP && (
						<p
							style={{
								fontSize: usePSize ? data.pSize : null,
								lineHeight: usePLineHeight ? data.pLineHeight : null,
							}}
						>
							{data.pText}
						</p>
					)}
				</div>
			</div>
		</div>
	)
}

export default FontItem
