import React from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DashboardLanding = ({ auth: { user, authLoading, role } }) => {
	return (
		<>
			<h6 className="info-title">
				{/* TODO: Enhance this icon */}
				<FontAwesomeIcon icon="user-circle" /> Welcome {user && user.name}
			</h6>
			<p>Welcome to your Dashboard!</p>
			<p>
				Your dashboard is a one-stop shop for searching, saving, and viewing fishing tournaments in your area!
			</p>

			<dl>
				<dt>Tournament Search</dt>
				<dd>Search for tournaments right from your dashboard!</dd>

				<dt>View Profile</dt>
				<dd>View and update your findfishingtournaments.com user profile.</dd>

				<dt>Tournament Watchlist</dt>
				<dd>View your saved tournaments.</dd>
			</dl>
		</>
	)
}

DashboardLanding.propTypes = {}

const mapStateToProps = state => ({
	auth: state.auth,
})

export default connect(mapStateToProps)(DashboardLanding)
