import React, { useState, useEffect } from 'react'
// import redpin from '../../../img/map_markers/map-pin.svg'
// import { ReactComponent as MapPin } from '../../../img/map_markers/map-pin.svg'
import MapMarkerInfoPanel from './MapMarkerInfoPanel'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import { Redirect, withRouter } from 'react-router-dom'
// const options = require('../../forms/tournament/tournOptions.json')

const MapMarker = ({ tourn, color, match }) => {
	// console.log('tourn', tourn)
	const [redirect, setRedirect] = useState(false)
	const [redirectTo, setRedirectTo] = useState(null)
	const [colorClass, setColorClass] = useState()

	const { _id, category, tourName, result_id } = tourn

	useEffect(() => {
		setColorClass(color)
	})

	const navigateTo = (e, tournId) => {
		setRedirect(true)
		setRedirectTo(`/tournaments/${tournId}`)
	}

	return redirect && redirectTo !== match.url ? (
		<Redirect to={redirectTo} />
	) : (
		<div className={`map-marker-container`} data-tourn-id={_id} onClick={(e) => navigateTo(e, _id)}>
			<MapMarkerInfoPanel name={tourName} cat={category} />
			<div className="pin-container">
				<span className="result-id">{result_id}</span>
				{/* <MapPin /> */}

				<SvgLoader className="map-marker" path="../../../img/map_markers/map-pin.svg">
					<SvgProxy selector=".c" fill={colorClass} />
					<SvgProxy selector=".a" fill="url(#radial-gradient)" />
				</SvgLoader>

				{/* <img className="map-marker img-responsive" alt={`Map Marker for ${tourName}`} src={redpin} /> */}
			</div>
		</div>
	)
}

export default withRouter(MapMarker)
