import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import logo from '../../img/logo-color.png'
import FontList from '../../utils/FontList'
import dateformat from 'dateformat'
import { logout } from '../../actions/auth'

import { SHOW_TOURNAMENT_LEVEL_OPTIONS, SHOW_LIST_A_TOURNAMENT } from '../../actions/types'

const Footer = ({ isAuthenticated, logout }) => {
	const dispatch = useDispatch()
	const dateNow = new Date()
	const yearNow = dateformat(dateNow, 'yyyy')
	const [fontListDisplay, setFontListDisplay] = useState(false)

	const handleLogout = () => {
		logout()
	}

	return (
		<footer className="site-footer">
			{fontListDisplay && <FontList />}
			<div className="column-container footer-links-container">
				<ul className="nav-links guest-links">
					<li>
						{isAuthenticated ? (
							<a onClick={handleLogout} href="#!">
								<span className="hide-sm">Logout</span>
							</a>
						) : (
							<Link to="/login">Login</Link>
						)}
					</li>
					{!isAuthenticated && (
						<li>
							<Link to="/register">Register</Link>
						</li>
					)}
					<li>
						<Link to="/dashboard" onClick={() => dispatch({ type: SHOW_LIST_A_TOURNAMENT })}>
							List a Tournament
						</Link>
					</li>
					<li>
						<Link to="/advertise">Advertise</Link>
					</li>
				</ul>
			</div>
			<div className="site-data-copyright-container">
				<div className="brand">
					<Link className="brand-graphic-container" to="/">
						<img
							className="brand-graphic img-responsive"
							src={logo}
							alt="find fishing tournaments .com logo"
						/>
						<div className="brand-graphic-text-container">
							<div className="text-wrapper">
								<span className="brand-graphic-text top-text">FindFishing</span>
								<span className="brand-graphic-text bottom-text">Tournaments.com</span>
							</div>
						</div>
					</Link>
				</div>
				<div className="copyright-container">
					<div className="policy-container spacing-bottom-1">
						<Link to="/privacy-policies" className="policies">
							Privacy Policy
						</Link>
						<span className="link-divider"> | </span>
						<Link to="/terms-and-conditions">Terms and Conditions</Link>
					</div>
					<div className="copyright">© {yearNow} FindFishingTournaments.com. All rights reserved.</div>
				</div>
			</div>
			<div
				className={`font-box-btn${fontListDisplay ? ' font-box-active' : ''}`}
				onClick={() => setFontListDisplay(!fontListDisplay)}
			></div>
		</footer>
	)
}

Footer.propTypes = {
	isAuthenticated: PropTypes.bool,
	logout: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps, { logout })(Footer)
