import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import history from '../../routing/HistoryMap'
import MyMapComponent from '../../Layout/Elements/Maps'
import TournamentItem from '../../Layout/Tournament/TournamentItem'
import Button from '../Elements/Button'
// import Moment from 'react-moment'
// import Spinner from '../../../components/effects/loading/Spinner'
// import Stripes from '../../Layout/Elements/BG/Stripes'
import PortarodAd1 from '../../../adverts/portarod/portarod-five-rod-holder.jpg'
import PortarodAd2 from '../../../adverts/portarod/portarod-best-gear.jpg'
// import { getTournList } from '../../../actions/tournament'
// import { getTournamentTypes } from '../../../actions/admin'

const TournSearchResults = ({
	tournament: { tournaments, tournamentLoading },
	tournamentTypes,
	displayAds,
	emptySearch,
	searchMade,
	searchMapCenter,
}) => {
	const resultsVisibility = !tournamentLoading && tournaments.length > 0 ? true : false
	const reslutsVisibilitySetClass = resultsVisibility ? 'visible' : 'hidden'

	const [byDistanceOrder, setByDistanceOrder] = useState('asc')
	const [byDateOrder, setByDateOrder] = useState('asc')
	const [orderDisplay, setOrderDisplay] = useState('Nearest to Farthest')

	function setResultID(target, index) {
		target.result_id = index + 1
	}

	const sortByDistance = () => {
		if (byDistanceOrder === 'desc') {
			setByDistanceOrder('asc')
			tournaments.sort((a, b) => (a.result_distance > b.result_distance ? 1 : -1))
			tournaments.forEach(setResultID)
			setOrderDisplay('Nearest to Farthest')
		} else {
			setByDistanceOrder('desc')
			tournaments.sort((a, b) => (a.result_distance < b.result_distance ? 1 : -1))
			tournaments.forEach(setResultID)
			setOrderDisplay('Farthest to Nearest')
		}
	}

	const sortByDate = () => {
		if (byDateOrder === 'desc') {
			setByDateOrder('asc')
			tournaments.sort(function compare(a, b) {
				var dateA = new Date(a.eventDate.startDate)
				var dateB = new Date(b.eventDate.startDate)
				return dateA - dateB
			})

			tournaments.forEach(setResultID)
			setOrderDisplay('Soonest to Latest')
		} else {
			setByDateOrder('desc')
			tournaments.sort(function compare(a, b) {
				var dateA = new Date(a.eventDate.startDate)
				var dateB = new Date(b.eventDate.startDate)
				return dateB - dateA
			})

			tournaments.forEach(setResultID)
			setOrderDisplay('Latest to Soonest')
		}
	}

	return (
		<div className="map-and-results-container">
			<MyMapComponent tournaments={tournaments} types={tournamentTypes} />

			<div className={`tournament-search-results-container${' ' + reslutsVisibilitySetClass}`}>
				{/* <Stripes /> */}
				<div className="title-bar section-title">
					<h2>Tournament Search Results</h2>
					{searchMade && (
						<h4 className="result-message">
							{' '}
							{tournaments.length} Result
							{tournaments.length !== 1 ? 's' : null} Found
						</h4>
					)}

					<div className="display-order-selector">
						<h6>Display Order:</h6>
						<h4 className="display-order">{orderDisplay}</h4>
					</div>
				</div>
				{displayAds && (
					<>
						<div className="sidebar-left sidebar-graphic-container">
							<div className="adv-item portarod">
								<a href="https://www.portarod.com/" target="_blank" rel="noopener noreferrer">
									<img
										className="sidebar-img img-responsive"
										src={PortarodAd1}
										alt="Portarod 5 Rod Holder"
									/>
								</a>
							</div>
						</div>
						<div className="portarod sidebar-right sidebar-graphic-container">
							<div className="adv-item portarod">
								<a href="https://www.portarod.com/" target="_blank" rel="noopener noreferrer">
									<img
										className="sidebar-img img-responsive"
										src={PortarodAd2}
										alt="Portarod 5 Rod Holder"
									/>
								</a>
							</div>
						</div>
					</>
				)}
				<div className="tournament-search-results">
					<div className="sort-by-options">
						<Button variant="primary" label={'Sort By Distance'} onClick={sortByDistance} />
						<Button variant="primary" label={'Sort By Date'} onClick={sortByDate} />
					</div>
					{emptySearch && <div className="no-results">No Results</div>}
					{resultsVisibility && (
						<>
							{tournaments.map((tourn, index) => {
								// console.log('tourn', tourn.category)
								let tournTypeColor = ''
								tournamentTypes.map((type) => {
									if (type.tournTypeName === tourn.category) {
										// ('type', type.tournTypeName)
										// console.log('type.colorValue', type.colorValue)
										tournTypeColor = type.colorValue
									}
								})
								return <TournamentItem key={index} tournament={tourn} tournColor={tournTypeColor} />
							})}
						</>
					)}
				</div>
			</div>
		</div>
	)
}

TournSearchResults.propTypes = {
	tournament: PropTypes.object.isRequired,
	// getTournamentTypes: PropTypes.func.isRequired,
	emptySearch: PropTypes.bool,
	searchMade: PropTypes.bool,
}
const mapStateToProps = (state) => ({
	tournament: state.tournament,
	tournamentTypes: state.admin.tournamentTypes ? state.admin.tournamentTypes : null,
	emptySearch: state.tournament.emptySearch,
	searchMade: state.tournament.searchMade,
})

// export default connect(mapStateToProps, [getTournamentTypes])(TournSearchResults)
export default connect(mapStateToProps)(TournSearchResults)
