import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import TournamentTypesForm from '../forms/tournament/TournamentTypesForm'
import { getTournamentTypes } from '../../actions/admin'
import Spinner from '../../components/effects/loading/Spinner'
import TournType from './TournType'
import Button from '../Layout/Elements/Button'

import { SHOW_ADMIN_ADD_TOURNAMENT_TYPE_FORM } from '../../actions/types'

const TournTypeSettings = ({
	getTournamentTypes,
	admin: { tournamentTypeLoading, tournamentTypes },
	showAddTournamentTypeForm,
}) => {
	const dispatch = useDispatch()

	useEffect(() => {
		getTournamentTypes()
	}, [getTournamentTypes])

	return (
		<div className="tourn-types">
			<h2>Current Tournament Types</h2>
			{tournamentTypeLoading ? (
				<Spinner />
			) : (
				<>
					<div className="tournament-types-container no-border">
						{tournamentTypes.map((type, index) => {
							return <TournType key={index} type={type} />
						})}
					</div>
					{/* Add New Tournament Type to Array / Database */}
					<div className="add-tourn-btn">
						<Button
							variant="secondary"
							// size={'small'}
							label={'Add New Tournament Type'}
							onClick={() => dispatch({ type: SHOW_ADMIN_ADD_TOURNAMENT_TYPE_FORM })}
						/>
					</div>
				</>
			)}
			{showAddTournamentTypeForm && (
				<div className="add-tourn-type-form">
					<TournamentTypesForm />
				</div>
			)}
		</div>
	)
}

TournTypeSettings.propTypes = {
	getTournamentTypes: PropTypes.func.isRequired,
	admin: PropTypes.object.isRequired,
	showAddTournamentTypeForm: PropTypes.bool,
}

const mapStateToProps = state => ({
	admin: state.admin,
	showAddTournamentTypeForm: state.admin.showAddTournamentTypeForm,
})

export default connect(mapStateToProps, { getTournamentTypes })(TournTypeSettings)
