import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	USER_LOADED,
	AUTH_ERROR,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	ACCOUNT_DELETED,
	USER_REMOVED_TOURN_FROM_SAVED,
	REGISTRATION_TYPE_SELECTED,
	USER_PASSWORD_UPDATE_RESET_CODE_VERIFIED,
	USER_PASSWORD_UPDATE_RESET_CODE_ERROR,
	USER_PASSWORD_UPDATING,
	USER_PASSWORD_UPDATED,
	USER_PASSWORD_UPDATED_ERROR,
} from '../actions/types'

const initialState = {
	token: localStorage.getItem('token'),
	isAuthenticated: false,
	isAdmin: false,
	authLoading: true,
	user: null,
	role: null,
	savedTourn: null,
	registrationType: null,
	signupSuccess: false,
	passwordResetVarrifying: true,
	passwordResetCodeVerrified: false,
	userPasswordUpdating: false,
	userPasswordUpdated: false,
	passwordUpdateError: false,
	registeredForTournamentAlerts: false,
}

export default function(state = initialState, action) {
	const { type, payload } = action

	switch (type) {
		// Conditions for password reset request code verifiecation from user email
		case USER_PASSWORD_UPDATE_RESET_CODE_VERIFIED:
			return {
				...state,
				passwordResetVarrifying: false,
				passwordResetCodeVerrified: true,
			}
		case USER_PASSWORD_UPDATE_RESET_CODE_ERROR:
			return {
				...state,
				passwordResetVarrifying: false,
				passwordResetCodeVerrified: false,
			}
		case USER_PASSWORD_UPDATING:
			return {
				...state,
				userPasswordUpdating: true,
				userPasswordUpdated: false,
			}
		case USER_PASSWORD_UPDATED:
			return {
				...state,
				userPasswordUpdating: false,
				userPasswordUpdated: true,
			}
		case USER_PASSWORD_UPDATED_ERROR:
			return {
				...state,
				userPasswordUpdating: false,
				userPasswordUpdated: false,
				passwordUpdateError: true,
			}

		// User Login / Logout Conditions
		case USER_LOADED:
			return {
				...state,
				isAuthenticated: true,
				isAdmin: payload.role === 'admin' ? true : false,
				authLoading: false,
				user: payload,
				role: payload.role,
				registeredForTournamentAlerts: payload.tournAlerts.registered,
			}

		case REGISTRATION_TYPE_SELECTED:
			return {
				...state,
				registrationType: payload,
			}

		case REGISTER_SUCCESS:
			localStorage.setItem('token', payload.token)
			return {
				...state,
				...payload,
				isAuthenticated: true,
				authLoading: false,
				signupSuccess: true,
			}
		case LOGIN_SUCCESS:
			localStorage.setItem('token', payload.token)
			return {
				...state,
				...payload,
				isAuthenticated: true,
				authLoading: false,
			}
		case REGISTER_FAIL:
		case AUTH_ERROR:
		case LOGIN_FAIL:
		case LOGOUT:
		case ACCOUNT_DELETED:
			localStorage.removeItem('token')
			return {
				...state,
				token: null,
				isAuthenticated: false,
				authLoading: false,
				role: null,
				user: null,
				savedTourn: null,
				isAdmin: false,
				registrationType: null,
			}
		default:
			return state
	}
}
