import React from 'react'
// import Header from './Header'
// import PropTypes from 'prop-types'

const InnerStructure = ({ className, classNameInner, children }) => {
	return (
		<>
			<main className={`main-content ${className ? className : ''}`}>
				<div className={`page-inner-content ${classNameInner ? classNameInner : ''}`}>{children}</div>
			</main>
		</>
	)
}

// Structure.propTypes = {}

export default InnerStructure
