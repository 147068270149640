import React, { useState, useRef, useCallback, useEffect } from 'react'
import { Editor, RichUtils, getDefaultKeyBinding, ContentState } from 'draft-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BlockStyleControls from './BlockStyleControls'
import InlineStyleControls from './InlineStyleControls'
import 'draft-js/dist/Draft.css'

function TournamentDescription({ editorState, setEditorState, onChange }) {
	// Settings for Rich Text editor // draft.js // https://draftjs.org/docs/quickstart-api-basics

	const editor = useRef(null)

	const focus = () => {
		if (editor.current) editor.current.focus()
	}

	const handleKeyCommand = useCallback(
		(command, editorState) => {
			const newState = RichUtils.handleKeyCommand(editorState, command)
			if (newState) {
				setEditorState(newState)
				return 'handled'
			}
			return 'not-handled'
		},
		[editorState, setEditorState]
	)

	const mapKeyToEditorCommand = useCallback(
		e => {
			switch (e.keyCode) {
				case 9: // TAB
					const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */)
					if (newEditorState !== editorState) {
						setEditorState(newEditorState)
					}
					return null
			}
			return getDefaultKeyBinding(e)
		},
		[editorState, setEditorState]
	)

	// If the user changes block type before entering any text, we can
	// either style the placeholder or hide it. Let's just hide it now.
	let className = 'RichEditor-editor'
	var contentState = editorState.getCurrentContent()
	if (!contentState.hasText()) {
		if (
			contentState
				.getBlockMap()
				.first()
				.getType() !== 'unstyled'
		) {
			className += ' RichEditor-hidePlaceholder'
		}
	}

	const getBlockStyle = block => {
		switch (block.getType()) {
			case 'blockquote':
				return 'RichEditor-blockquote'
			default:
				return null
		}
	}

	// Custom overrides for "code" style.
	// const styleMap = {
	// 	CODE: {
	// 	backgroundColor: 'rgba(0, 0, 0, 0.05)',
	// 	fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
	// 	fontSize: 16,
	// 	padding: 2,
	// 	},
	// }

	return (
		<>
			<div className="RichEditor-root">
				<div className="editor-controls-container">
					<BlockStyleControls
						editorState={editorState}
						onToggle={blockType => {
							const newState = RichUtils.toggleBlockType(editorState, blockType)
							setEditorState(newState)
						}}
					/>
					<InlineStyleControls
						editorState={editorState}
						onToggle={inlineStyle => {
							const newState = RichUtils.toggleInlineStyle(editorState, inlineStyle)
							setEditorState(newState)
						}}
					/>
				</div>
				<div className={className} onClick={focus}>
					<Editor
						blockStyleFn={getBlockStyle}
						// customStyleMap={styleMap}
						editorState={editorState}
						handleKeyCommand={handleKeyCommand}
						keyBindingFn={mapKeyToEditorCommand}
						onChange={onChange}
						placeholder="Enter a Description"
						ref={editor}
						spellCheck={true}
					/>
				</div>
			</div>
			{/* <div className="rich-text-toolbar">
				<div className="toolbar-btn" onClick={onBoldClick}>
					<FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'bold' }} />
					<span className="toolbar-btn-text">Bold</span>
				</div>
				<div className="toolbar-btn" onClick={onBoldClick}>
					<FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'bold' }} />
					<span className="toolbar-btn-text">Italic</span>
				</div>
			</div>
			<Editor editorState={editorState} onChange={setEditorState} placeholder="Add a Description" /> */}
		</>
	)
}

export default TournamentDescription
