import React from 'react'
import PropTypes from 'prop-types'
import AdvertisingContactForm from '../../forms/contact/AdvertisingContactForm'

const AdvertisingContact = ({}) => {
	return (
		<div>
			<AdvertisingContactForm />
		</div>
	)
}

AdvertisingContact.propTypes = {}

export default AdvertisingContact
