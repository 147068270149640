import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { EditorState } from 'draft-js'
import Button from '../Layout/Elements/Button'
import StructureAdmin from '../Layout/StructureAdmin'
import CreateTournament from '../forms/tournament/CreateTournament'
import Tournaments from '../Layout/Tournament/Tournaments'
import TournTypeSettings from './TournTypeSettings'

import {
	SHOW_ADMIN_VIEW_ALL_TOURNAMENTS,
	SHOW_ADMIN_VIEW_CURRENT_TOURNAMENTS,
	SHOW_ADMIN_VIEW_EXPIRED_TOURNAMENTS,
	SHOW_ADMIN_CREATE_A_TOURNAMENT,
	SHOW_ADMIN_EDIT_TOURNAMENT_TYPES,
	SHOW_ADMIN_TOURNAMENT_TYPE_FORM,
} from '../../actions/types'

import { sendMail_TournTwoWeekDeadline } from '../../actions/mail'

const AdminPanel = ({
	auth: { isAuthenticated, role, authLoading },
	showAdminLanding,
	showViewAllTournaments,
	showCreateTournament,
	showEditTournamentTypes,
	showViewCurrentTournaments,
	showViewExpiredTournaments,
}) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty())
	const dispatch = useDispatch()
	return (
		<StructureAdmin>
			<h1>Admin</h1>
			<div className="admin-content-wrapper">
				<div className="display-controls">
					<Button
						size="small"
						variant="primary"
						label={'View Current Tournaments'}
						onClick={() => dispatch({ type: SHOW_ADMIN_VIEW_CURRENT_TOURNAMENTS })}
					/>
					<Button
						size="small"
						variant="primary"
						label={'View Expired Tournaments'}
						onClick={() => dispatch({ type: SHOW_ADMIN_VIEW_EXPIRED_TOURNAMENTS })}
					/>
					<Button
						size="small"
						variant="primary"
						label={'View all Tournaments'}
						onClick={() => dispatch({ type: SHOW_ADMIN_VIEW_ALL_TOURNAMENTS })}
					/>
					<div className="divider"></div>
					<Button
						size="small"
						variant="primary"
						label={'Create a Tournament'}
						onClick={() => dispatch({ type: SHOW_ADMIN_CREATE_A_TOURNAMENT })}
					/>
					<div className="divider"></div>
					<Button
						size="small"
						variant="primary"
						label={'Edit Tournament Types'}
						onClick={() => dispatch({ type: SHOW_ADMIN_EDIT_TOURNAMENT_TYPES })}
					/>
					{/* <Button
						size="small"
						variant="primary"
						label={'Test Registration Deadline Code'}
						onClick={() => sendMail_TournTwoWeekDeadline()}
					/> */}
				</div>
				<div className="admin-edit-area">
					{/* {console.log(viewAllTournaments)} */}
					{showViewCurrentTournaments && (
						<>
							<h3>All Current Tournaments</h3>
							<Tournaments isAdminArea={true} filter={'current'} />
						</>
					)}
					{showViewExpiredTournaments && (
						<>
							<h3>All Expired Tournaments</h3>
							<Tournaments isAdminArea={true} filter={'expired'} />
						</>
					)}
					{showViewAllTournaments && (
						<>
							<h3>All Tournaments</h3>
							<Tournaments isAdminArea={true} />
						</>
					)}
					{showCreateTournament && (
						<CreateTournament editorState={editorState} setEditorState={setEditorState} />
					)}
					{showEditTournamentTypes && <TournTypeSettings />}
				</div>
			</div>
		</StructureAdmin>
	)
}

AdminPanel.propTypes = {
	auth: PropTypes.object.isRequired,
	showAdminLanding: PropTypes.bool,
	showViewAllTournaments: PropTypes.bool,
	showCreateTournament: PropTypes.bool,
	showEditTournamentTypes: PropTypes.bool,
	showViewCurrentTournaments: PropTypes.bool,
	showViewExpiredTournaments: PropTypes.bool,
}

const mapStateToProps = state => ({
	auth: state.auth,
	showAdminLanding: state.admin.showAdminLanding,
	showViewAllTournaments: state.admin.showViewAllTournaments,
	showCreateTournament: state.admin.showCreateTournament,
	showEditTournamentTypes: state.admin.showEditTournamentTypes,
	showViewCurrentTournaments: state.admin.showViewCurrentTournaments,
	showViewExpiredTournaments: state.admin.showViewExpiredTournaments,
})

export default connect(mapStateToProps)(AdminPanel)
