const mapStyles = {
	styles: [
		{
			elementType: 'geometry',
			stylers: [
				{
					color: '#f5f5f5',
				},
			],
		},
		{
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'administrative.locality',
			elementType: 'labels.text',
			stylers: [
				{
					color: '#ffffff',
				},
				{
					weight: 5,
				},
			],
		},
		{
			featureType: 'administrative.locality',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#ba3217',
				},
				{
					weight: 6.5,
				},
			],
		},
		{
			featureType: 'administrative.province',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#ba3217',
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#43cdc7',
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#757575',
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry',
			stylers: [
				{
					color: '#f0b5ab',
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'on',
				},
				{
					weight: 2.5,
				},
			],
		},
		{
			featureType: 'road.local',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#9e9e9e',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'geometry',
			stylers: [
				{
					color: '#408eb5',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#9e9e9e',
				},
			],
		},
		{
			featureType: 'road.local',
			elementType: 'geometry',
			stylers: [
				{
					color: '#b2cfcd',
				},
			],
		},
	],
}

export { mapStyles }
