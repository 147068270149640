import React, { Fragment } from 'react'

const ListElem = () => {
	return (
		<Fragment>
			<h3>Ordered List</h3>
			<ol>
				<li>List Item 1</li>
				<li>List Item 2</li>
				<li>
					List Item 3
					<ol>
						<li>List Item 3.1</li>
						<li>
							List Item 3.2
							<ol>
								<li>List Item 3.2.1</li>
								<li>List Item 3.2.2</li>
							</ol>
						</li>
						<li>List Item 3.3</li>
					</ol>
				</li>
				<li>List Item 4</li>
			</ol>

			<h3>Unordered List</h3>
			<ul>
				<li>List Item 1</li>
				<li>List Item 2</li>
				<li>
					List Item 3
					<ul>
						<li>List Item 3.1</li>
						<li>
							List Item 3.2
							<ul>
								<li>List Item 3.2.1</li>
								<li>List Item 3.2.2</li>
							</ul>
						</li>
						<li>List Item 3.3</li>
					</ul>
				</li>
				<li>List Item 4</li>
			</ul>

			<h3>Definition List</h3>
			<dl>
				<dt>Definition Term 1</dt>
				<dd>Definition Description 1</dd>
				<dt>Definition Term 2</dt>
				<dd>Definition Description 2</dd>
			</dl>
		</Fragment>
	)
}

export default ListElem
