import React from 'react'
import TournamentSearch from '../../forms/tournament/TournamentSearch'

const Hero = () => {
	return (
		<>
			<div className="hero">
				<div className="billboard">
					{/* <div className="billboard-text-container">
					<span className="item-1">Find Fishing Tournaments</span>
					<span className="item-2">In Your Area</span>
				</div> */}
				</div>
				<TournamentSearch />
			</div>
		</>
	)
}

export default Hero
