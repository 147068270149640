import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '../Elements/Button'
import { saveToUserList, removeSavedFromUserList } from '../../../actions/tournament'
import { loadUser } from '../../../actions/auth'
import { connect } from 'react-redux'

const AddToFavorites = ({
	isAuthenticated,
	isFav,
	tournament,
	user,
	saveToUserList,
	removeSavedFromUserList,
	variant,
	className,
	block,
}) => {
	const [favExists, setFavExists] = useState(false)
	const { _id } = tournament

	useEffect(() => {
		if (isFav) setFavExists(true)
	}, [isFav])

	const addToFavorites = () => {
		const data = { tournament: _id }
		saveToUserList(user._id, data)
		setFavExists(true)
	}

	const removeFromFavorites = () => {
		const data = { tournament: _id }
		removeSavedFromUserList(user._id, data)
		setFavExists(false)
		// getTournList(user._id)
		// loadUser()
	}

	return (
		<>
			{isAuthenticated && !favExists ? (
				<Button
					className={`add-to-favorites fav-btn ${className ? className : null}`}
					// variant="primary"
					variant={variant ? variant : 'primary'}
					label={'Add to My Watchlist'}
					size={'small'}
					block={block ? 'block' : null}
					onClick={addToFavorites}
					icon={'map-marker-plus'}
					iconType={'fas'}
				/>
			) : null}
			{favExists ? (
				<Button
					className={`remove-from-favorites fav-btn ${className ? className : null}`}
					// variant="primary"
					variant={variant ? variant : 'primary'}
					label={'Remove From Watchlist'}
					size={'small'}
					block={block ? 'block' : null}
					onClick={removeFromFavorites}
					icon={'map-marker-minus'}
					iconType={'fas'}
				/>
			) : null}
		</>
	)
}

AddToFavorites.propTypes = {
	saveToUserList: PropTypes.func.isRequired,
	removeSavedFromUserList: PropTypes.func.isRequired,
	tournament: PropTypes.object.isRequired,
	// loadUser: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
	auth: state.auth,
	isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps, { saveToUserList, removeSavedFromUserList })(AddToFavorites)
