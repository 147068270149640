import axios from 'axios'
import { setAlert } from './alert'

export const handleS3Token = async (item, fileType) => {
	// console.log('item = ', item)
	// console.log('fileType = ', fileType)

	let file = item
	// Split the filename to get the name and type
	// let fileParts = file.name.split('.')
	// let fileName = fileParts[0]
	// let fileType = fileParts[1]

	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}

	try {
		const res = await axios.post(
			'/api/image-upload',
			{
				fileName: file.name,
				fileType: fileType,
			},
			config
		)
		// console.log('image token res = ', res)

		return res
	} catch (err) {
		console.log('image token ERROR = ', err)
	}
}

export const handleS3Upload = async (signedRequest, file, fileType) => {
	const config = {
		headers: {
			'Content-Type': fileType,
		},
	}

	try {
		const res = await axios.put(signedRequest, file, config)
		// console.log('image upload res = ', res)
		return res
	} catch (err) {
		console.log('image upload ERROR = ', err)
	}
}
