import {
	NEW_TOURNAMENT_ALERT_SIGNUP,
	NEW_TOURNAMENT_ALERT_SIGNUP_ERROR,
	NEW_TOURNAMENT_MAIL_ALERT,
	NEW_TOURNAMENT_MAIL_ALERT_ERROR,
	TOURNAMENT_ALERT_SIGNUP_OPEN,
	TOURNAMENT_ALERT_SIGNUP_CLOSE,
	FROM_TOURNAMENT_ALERT_SIGN_UP,
	TOURNAMENT_ALERT_SIGNUP_COMPLETE,
	USER_TOURN_ALERTS_IS_REGISTERED,
	USER_PASSWORD_UPDATE_EMAIL_SENT,
	USER_PASSWORD_UPDATE_NO_EMAIL,
	USER_PASSWORD_UPDATE_EMAIL_SENT_ERROR,
	RESET_USER_PASSWORD_UPDATE,
	LOGOUT,
} from '../actions/types'

const initialState = {
	tournAlertRegistered: false,
	showTournamentAlertSignUp: false,
	tournAlertSignUpSuccess: false,
	passwordResetEmailSent: false,
	passwordResetNoEmail: false,
	passwordResetEmailError: false,
	tournamentAlertSignUpModalDismissed: false,
	fromTournAlertSignUp: false,
	error: {},
}

export default function(state = initialState, action) {
	const { type, payload } = action

	switch (type) {
		case USER_PASSWORD_UPDATE_EMAIL_SENT:
			return {
				...state,
				passwordResetEmailSent: true,
				passwordResetEmailError: false,
			}
		case USER_PASSWORD_UPDATE_NO_EMAIL:
			return {
				...state,
				passwordResetNoEmail: true,
				passwordResetEmailSent: false,
				passwordResetEmailError: true,
			}
		case USER_PASSWORD_UPDATE_EMAIL_SENT_ERROR:
			return {
				...state,
				passwordResetEmailSent: false,
				passwordResetEmailError: true,
			}
		case RESET_USER_PASSWORD_UPDATE:
			return {
				...state,
				passwordResetEmailSent: false,
				passwordResetNoEmail: false,
				passwordResetEmailError: false,
			}
		case USER_TOURN_ALERTS_IS_REGISTERED:
			return {
				...state,
				tournAlertRegistered: true,
			}
		case TOURNAMENT_ALERT_SIGNUP_OPEN:
			return {
				...state,
				showTournamentAlertSignUp: true,
				fromTournAlertSignUp: false,
			}
		case FROM_TOURNAMENT_ALERT_SIGN_UP:
			return {
				...state,
				fromTournAlertSignUp: true,
			}

		case TOURNAMENT_ALERT_SIGNUP_CLOSE:
			return {
				...state,
				showTournamentAlertSignUp: false,
				tournAlertSignUpSuccess: false,
				tournamentAlertSignUpModalDismissed: true,
			}
		case NEW_TOURNAMENT_ALERT_SIGNUP:
			return {
				...state,
				tournAlertSignUpSuccess: true,
			}
		case NEW_TOURNAMENT_ALERT_SIGNUP_ERROR:
			return {
				...state,
				tournAlertSignUpSuccess: false,
			}
		case TOURNAMENT_ALERT_SIGNUP_COMPLETE:
			return {
				...state,
				tournAlertSignUpSuccess: false,
			}
		case LOGOUT:
			return {
				...state,
				tournAlertRegistered: false,
				showTournamentAlertSignUp: false,
				tournAlertSignUpSuccess: false,
			}
		default:
			return state
	}
}
