import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Register from '../auth/Register'
import ChooseAccountType from '../../components/auth/ChooseAccountType'
import Login from '../auth/Login'
import RequestPasswordChange from '../auth/RequestPasswordChange'
import ResetPassword from '../auth/ResetPassword'
// import Alert from '../Alert'

import Dashboard from '../dashboard/Dashboard'
import CreateProfile from '../forms/profile-forms/CreateProfile'
import EditProfile from '../forms/profile-forms//EditProfile'
import CreateTournament from '../forms/tournament/CreateTournament'
import EditTournament from '../forms/tournament/EditTournament'
// import AddExperience from '../profile-forms/AddExperience';
// import AddEducation from '../profile-forms/AddEducation';
// import Profiles from '../profiles/Profiles';
// import Profile from '../profile/Profile';
import Tournaments from '../Layout/Tournament/Tournaments.js'
import TournamentDetail from '../Layout/Tournament/TournamentDetail.js'
// import NotFound from '../layout/NotFound';
import Styleguide from '../Layout/styleguide/Styleguide'
import AdminPanel from '../admin/AdminPanel'
import PrivateRoute from './PrivateRoute'
import AdminRoute from './AdminRoute'
import OuterStructure from '../Layout/OuterStructure'
import Payments from '../payments/Payments'
import PrivacyPolicies from '../Layout/PrivacyPolicies'
import TermsAndConditions from '../Layout/TermsAndConditions'
import Advertise from '../Layout/Advertise/Advertise'

const Routes = () => {
	return (
		<OuterStructure>
			<div className="outer-page-container">
				{/* <Alert /> */}
				<Switch>
					<Route exact path="/register" component={Register} />
					<Route exact path="/register/account-type" component={ChooseAccountType} />
					<Route exact path="/login" component={Login} />
					<Route exact path="/forgot-password" component={RequestPasswordChange} />
					<Route exact path="/forgot-password/:id" component={ResetPassword} />
					<Route exact path="/privacy-policies" component={PrivacyPolicies} />
					<Route exact path="/terms-and-conditions" component={TermsAndConditions} />
					{/* <Route exact path="/profiles" component={Profiles} /> */}
					{/* <Route exact path="/profile/:id" component={Profile} /> */}
					<PrivateRoute exact path="/dashboard" component={Dashboard} />
					<PrivateRoute exact path="/create-profile" component={CreateProfile} />
					<PrivateRoute exact path="/edit-profile" component={EditProfile} />
					<PrivateRoute exact path="/edit-tournament/:id" component={EditTournament} />
					{/* <PrivateRoute
						exact
						path="/edit-tournament/:id"
						render={props => <EditTournament {...props} edit={true} />}
					/> */}
					<PrivateRoute exact path="/checkout" component={Payments} />
					{/* <PrivateRoute exact path="/add-experience" component={AddExperience} /> */}
					{/* <PrivateRoute exact path="/add-education" component={AddEducation} /> */}
					<Route exact path="/tournaments" component={Tournaments} />
					{/* <Route exact path="/tournaments/:id" component={TournamentDetail} /> */}
					<Route exact path="/tournaments/:id" render={props => <TournamentDetail {...props} />} />
					<AdminRoute exact path="/admin" component={AdminPanel} />
					<AdminRoute exact path="/admin/create-tournament" component={CreateTournament} />
					<AdminRoute exact path="/styleguide" component={Styleguide} />
					{/* <Route exact path="/styleguide" component={Styleguide} /> */}
					{/* <Route component={NotFound} /> */}
					<Route exact path="/advertise" component={Advertise} />
				</Switch>
			</div>
		</OuterStructure>
	)
}

export default Routes
