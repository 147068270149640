import {
	PAYMENT_SUBMITTED,
	PAYMENT_SUBMITTED_ERROR,
	TOURNAMENT_UPDATE_LEVEL,
	TOURNAMENT_SUBMISSION_COMPLETE,
	GET_TOURNAMENT,
	PAYMENT_LEVEL_SELECTED,
	SHOW_PAYMENT_MODAL,
	HIDE_PAYMENT_MODAL,
} from '../actions/types'

const initialState = {
	payment: null,
	paymentLoading: true,
	paymentComplete: false,
	paymentError: false,
	paymentLevel: 'free',
	paymentLevelSelected: false,
	showPaymentModal: false,
	error: {},
}

export default function(state = initialState, action) {
	const { type, payload } = action

	// console.log('payload = ', payload)

	switch (type) {
		case SHOW_PAYMENT_MODAL:
			return {
				...state,
				showPaymentModal: true,
			}
		case HIDE_PAYMENT_MODAL:
			return {
				...state,
				showPaymentModal: false,
			}
		case TOURNAMENT_UPDATE_LEVEL:
			return {
				...state,
				paymentLevel: payload,
			}
		case GET_TOURNAMENT:
			return {
				...state,
				paymentLevel: payload.paymentLevel,
			}
		case PAYMENT_LEVEL_SELECTED:
			return {
				...state,
				paymentLevelSelected: true,
			}
		case PAYMENT_SUBMITTED:
			return {
				...state,
				payment: payload,
				paymentLoading: false,
				paymentComplete: true,
				paymentLevel: payload.tournLevel,
			}
		case PAYMENT_SUBMITTED_ERROR:
			return {
				...state,
				payment: payload,
				paymentLoading: false,
				paymentError: true,
			}
		case TOURNAMENT_SUBMISSION_COMPLETE:
			return {
				...state,
				payment: null,
				paymentLoading: true,
				paymentComplete: false,
				paymentError: false,
				paymentLevel: 'free',
				paymentLevelSelected: false,
				showPaymentModal: false,
			}
		default:
			return state
	}
}
