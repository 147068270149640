import { TOURNAMENT_ALERT_SIGNUP_OPEN, TOURNAMENT_ALERT_SIGNUP_CLOSE } from '../actions/types'

const initialState = {
	modalOpen: false,
}

export default function(state = initialState, action) {
	const { type, payload } = action

	switch (type) {
		case TOURNAMENT_ALERT_SIGNUP_OPEN:
			return {
				...state,
				modalOpen: true,
			}
		case TOURNAMENT_ALERT_SIGNUP_CLOSE:
			return {
				...state,
				modalOpen: false,
			}

		default:
			return state
	}
}
