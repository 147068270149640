import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { login } from '../../../actions/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TOURNAMENT_ALERT_SIGNUP_CLOSE, FROM_TOURNAMENT_ALERT_SIGN_UP } from '../../../actions/types'
import Button from '../../Layout/Elements/Button'

const LoginForm = ({ login, isAuthenticated, auth, showTournamentAlertSignUp }) => {
	const dispatch = useDispatch()
	const [formData, setFormData] = useState({
		email: '',
		password: '',
	})

	const { email, password } = formData

	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

	const handleRegisterClick = e => {
		if (showTournamentAlertSignUp) {
			dispatch({ type: FROM_TOURNAMENT_ALERT_SIGN_UP })
		}
		dispatch({ type: TOURNAMENT_ALERT_SIGNUP_CLOSE })
	}

	const onSubmit = async e => {
		e.preventDefault()
		login(email, password)
	}

	return (
		<>
			<form className="form" onSubmit={e => onSubmit(e)}>
				<fieldset className="form-section">
					<div className="form-group">
						<label htmlFor="email">Email or Username:</label>
						<input type="email" name="email" value={email} onChange={e => onChange(e)} required />
					</div>
					<div className="form-group">
						<label htmlFor="email">Password:</label>
						<input
							type="password"
							name="password"
							value={password}
							onChange={e => onChange(e)}
							minLength="6"
						/>
					</div>
				</fieldset>
				<input type="submit" className="btn btn-primary spacing-bottom-1" value="Login" />
			</form>
			{/* <div className="login-footer-links">
				<div className="bat bat_inline bat_align-left bat_spread left-col">
					<p className="info-text">Don't have an account?</p>
					<Button
						isLink
						to="/register"
						variant="secondary"
						label="Create an Account"
						size="small"
						onClick={e => handleRegisterClick(e)}
					/>
				</div>
				<div className="bat bat_inline bat_align-left bat_spread right-col">
					<p className="info-text">Forgot Password?</p>
					<Button
						isLink
						to="/forgot-password"
						variant="secondary"
						label="Reset Password"
						size="small"
						onClick={e => handleRegisterClick(e)}
					/>
				</div>
			</div> */}
			<div className="login-footer-links">
				<h5>
					<FontAwesomeIcon icon={{ prefix: 'far', iconName: 'plus' }} />
					Additional Account Options:
				</h5>

				<Button
					isLink
					to="/register"
					variant="secondary"
					label="Create an Account"
					size="small"
					tooltip={
						<>
							<span>Don't have an account?</span>
							<span>Click here to create one.</span>
						</>
					}
					onClick={e => handleRegisterClick(e)}
				/>
				<Button
					isLink
					to="/forgot-password"
					variant="secondary"
					label="Reset Password"
					size="small"
					tooltip={
						<>
							<span>Forgot Password?</span>
							<span>Click here to change it.</span>
						</>
					}
					onClick={e => handleRegisterClick(e)}
				/>
			</div>
		</>
	)
}

LoginForm.propTypes = {
	login: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool,
	showTournamentAlertSignUp: PropTypes.bool,
}

const mapStateToProps = state => ({
	auth: state.auth,
	isAuthenticated: state.auth.isAuthenticated,
	showTournamentAlertSignUp: state.mail.showTournamentAlertSignUp,
})

export default connect(mapStateToProps, { login })(LoginForm)
