import React, { useState } from 'react'
import Checkbox from '../../../forms/Checkbox'
import Button from '../../Elements/Button'

const CheckboxeElems = () => {
	const [checkedItems, setCheckedItems] = useState({})
	const [viewCode, setViewCode] = useState(false)

	const checkBoxChange = e => {
		setCheckedItems({ ...checkedItems, [e.target.name]: e.target.checked })
	}

	const codeDisplay = () => {
		setViewCode(!viewCode)
	}

	const checkboxes = [
		{
			name: 'checkbox-1',
			label: 'Check Box 1',
			value: 'checkbox-1',
			disabled: false,
		},
		{
			name: 'checkbox-2',
			label: 'Check Box 2',
			value: 'checkbox-2',
			disabled: false,
		},
		{
			name: 'checkbox-3',
			label: 'Check Box 3',
			value: 'checkbox-3',
			disabled: false,
		},
		{
			name: 'checkbox-disabled',
			label: 'Check Box (Disabled)',
			value: 'checkbox-disabled',
			disabled: true,
		},
	]
	return (
		<>
			<h4>Checkboxes:</h4>
			<div className="form-group">
				{checkboxes.map((item, index) => {
					return (
						<Checkbox
							key={index}
							name={item.name}
							label={item.label}
							value={item.value}
							isDisabled={item.disabled}
							onChange={e => checkBoxChange(e)}
						/>
					)
				})}
			</div>

			<CheckboxCode />
		</>
	)
}

export default CheckboxeElems

const CheckboxCode = () => {
	return (
		<pre>
			<code>
				{`
	import Checkbox from '../../../forms/Checkbox'

	////////////////////////////////////////////////////////////////////////////////////////////////////
	// Single
	////////////////////////////////////////////////////////////////////////////////////////////////////
	
	<div className="form-group">
		<Checkbox name={name} label={label} value={value} isDisabled={false} onChange={e => onChangeFunc(e)} />
	</div>
	
	////////////////////////////////////////////////////////////////////////////////////////////////////
	// Multiple
	////////////////////////////////////////////////////////////////////////////////////////////////////
	
	const [checkedItems, setCheckedItems] = useState({})

	const checkBoxChange = e => {
		setCheckedItems({ ...checkedItems, [e.target.name]: e.target.checked })
	}

	const checkboxes = [
		{
			name: 'checkbox-1',
			label: 'Check Box 1',
			value: 'checkbox-1',
			disabled: false,
		},
		{
			name: 'checkbox-2',
			label: 'Check Box 2',
			value: 'checkbox-2',
			disabled: false,
		},
		{
			name: 'checkbox-3',
			label: 'Check Box 3',
			value: 'checkbox-3',
			disabled: false,
		},
		{
			name: 'checkbox-disabled',
			label: 'Check Box (Disabled)',
			value: 'checkbox-disabled',
			disabled: true,
		},
	]
	// JSX
	<div className="form-group">
		{checkboxes.map((item, index) => {
			return (
				<Checkbox
					key={index}
					name={item.name}
					label={item.label}
					value={item.value}
					isDisabled={item.disabled}
					onChange={e => checkBoxChange(e)}
				/>
			)
		})}
	</div>

	
	`}
			</code>
		</pre>
	)
}
