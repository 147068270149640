import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import InnerStructure from '../../Layout/InnerStructure'
import PropTypes from 'prop-types'
import AdvertisingContact from '../Advertise/AdvertisingContact'

const Advertise = props => {
	return (
		<InnerStructure className="width-narrow">
			<header className="section-header">
				<h1>Advertise</h1>
			</header>
			<p className="p-large spacing-bottom-2">
				Advertising with FindFishingTournaments.com can give visibility to your business on a local, regional,
				or national level.
			</p>
			<p className="p-large spacing-bottom-1">
				To learn more about pricing and availability send us a message by filling out the form below.
			</p>
			<AdvertisingContact />
		</InnerStructure>
	)
}

Advertise.propTypes = {}

const mapStateToProps = state => ({})

export default connect(mapStateToProps)(Advertise)
